import { TableColumnPopupType, TableTotalColumnList } from "../../../Constants/Constants";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility from "../../../Utilities/Utility";
import { getFirstTableColumnType, getIsSelectTotalColumnByType, getSortedHeaderType } from "../TableUtility";
export class AgedReceivableTableTotalColumn {
    lineItems: any;
    currency: string;
    totalAmount: string;
    totalBalance: string;
    totalSlab1: string;
    totalSlab2: string;
    totalSlab3: string;
    totalSlab4: string;

    constructor(lineItems: any, data: any, currency: string) {
        this.lineItems = lineItems
        this.currency = currency
        this.totalAmount = ''
        this.totalBalance = ''
        this.totalSlab1 = ''
        this.totalSlab2 = ''
        this.totalSlab3 = ''
        this.totalSlab4 = ''
        this.getTotalValue(lineItems)
    }

    getTotalValue(lineItems: any) {
        if (lineItems === undefined || lineItems === null) {
            return
        }

        if (lineItems.length === 0) {
            return
        }

        var amount = 0
        var balance = 0
        var slab1 = 0
        var slab2 = 0
        var slab3 = 0
        var slab4 = 0

        lineItems.forEach((element: any) => {
            if (!isNaN(element.amount)) {
                amount = amount + Number(element.amount)
            }
            if (!isNaN(element.balance)) {
                balance = balance + Number(element.balance)
            }
            if (!isNaN(element.slab1)) {
                slab1 = slab1 + Number(element.slab1)
            }
            if (!isNaN(element.slab2)) {
                slab2 = slab2 + Number(element.slab2)
            }
            if (!isNaN(element.slab3)) {
                slab3 = slab3 + Number(element.slab3)
            }
            if (!isNaN(element.slab4)) {
                slab4 = slab4 + Number(element.slab4)
            }
        });

        this.totalAmount = getIsSelectTotalColumnByType(TableColumnPopupType.amount) ? Utility.toCurrencyFormat(amount, this.currency) : ''
        this.totalBalance = getIsSelectTotalColumnByType(TableColumnPopupType.balance) ? Utility.toCurrencyFormat(balance, this.currency) : ''
        this.totalSlab1 = getIsSelectTotalColumnByType(TableColumnPopupType.slab1) ? Utility.toCurrencyFormat(slab1, this.currency) : ''
        this.totalSlab2 = getIsSelectTotalColumnByType(TableColumnPopupType.slab2) ? Utility.toCurrencyFormat(slab2, this.currency) : ''
        this.totalSlab3 = getIsSelectTotalColumnByType(TableColumnPopupType.slab3) ? Utility.toCurrencyFormat(slab3, this.currency) : ''
        this.totalSlab4 = getIsSelectTotalColumnByType(TableColumnPopupType.slab4) ? Utility.toCurrencyFormat(slab4, this.currency) : ''
        return
    }

    toString() {
        var sortedHeader = getSortedHeaderType()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            var firstColumnType = getFirstTableColumnType([], undefined)
            sortedHeader.forEach(type => {
                sortedValue.push(this.getDefaultValue(type, firstColumnType))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getDefaultValue(type: string, firstColumn: string | undefined) {
        if (firstColumn !== undefined) {
            if (firstColumn === type) {
                if (!TableTotalColumnList.includes(type)) {
                    return '<b>' + getLocalisedText('total') + '</b>'
                }
            }
        }
        return this.getValueByType(type)
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.amount:
                return this.totalAmount
            case TableColumnPopupType.balance:
                return this.totalBalance
            case TableColumnPopupType.slab1:
                return this.totalSlab1
            case TableColumnPopupType.slab2:
                return this.totalSlab2
            case TableColumnPopupType.slab3:
                return this.totalSlab3
            case TableColumnPopupType.slab4:
                return this.totalSlab4
            default:
                return ''
        }
    }



    getDefaultList() {
        return [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
    }
}