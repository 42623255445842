import React, { Component } from 'react';
import '../../App.css';
import DKCalendar from '../../DKUILibrary/dkdatepicker/DKCalendar';
import AccountColumnEditor from './AccountColumnEditor';
import ProductColumnEditor from './ProductColumnEditor';
import PaymentColumnEditor from './PaymentColumnEditor';
import FulfillmentColumnEditor from './FulfillmentColumnEditor';
import GoodsReceiptColumnEditor from './GoodsReceiptColumnEditor';
import JournalEntryColumnEditor from './JournalEntryColumnEditor';
import PickingColumnEditor from './PickingColumnEditor';
import PackingColumnEditor from './PackingColumnEditor';
import ShippingColumnEditor from './ShippingColumnEditor';
import ChequeColumnEditor from './ChequeColumnEditor';

import {
    COLOR_BLUE, 
    EDITOR_WIDTH, 
    FONT_FAMILY, 
    IMG_MAX_UPLOAD_SIZE,
    Utility,  
    getDateFrom, 
    getDateString, 
    getIsDebitOrCreditNote, 
    getIsAccountModule, 
    getIsFulfillment, 
    getIsGoodsReceipt, 
    getPaperFormat, 
    getIsMakePayment, 
    getIsReceivePayment, 
    getIsJournalEntry, 
    getIsPackList, 
    getIsPickList, 
    getIsShipList, 
    getIsPayslip, 
    getIsCheque, 
    getIsBill, 
    getIsInvoice, 
    getIsOrder, 
    getIsQuotation, 
    getIsPayrollCheck, 
    getIsBillOrInvoice, 
    getIsSalesOrder, 
    getIsEwayBill, 
    getIsEwayBillDetails, 
    getIsWorkOrder, 
    getIsStockTransfer, 
    getIsStockAdjustment,
    ScreenState, 
    getLocalisedText,
    AppManager,
    ComponentManager,
    DocumentManager,
    TemplateSettingsManager,
    PayslipUtility,
    showToast, TOAST_TYPE,
    CustomFieldManager,
    IndiaTaxParser,
    AddressType,
    OwnerType,
    Asset,
    DKContentEditableDiv,
    DKContentEditableDivWithRef,
    eInvoiceSectionPosition,
    AddComponentPopup,
    ViewComponentListPopup,
    TableColumnPopupType,
    TableDimensionType,
    CurrencyFormatType,
    EditorInfoType,
    DateFormat,
    getIsQCDocument,
    getIsExpenseOrDeposit
} from 'deskera-doc-builder-lib'
import { AddressParser, ApprovalSectionUtility, CONSTANTS, getIsAddressCustomFieldType, getIsBOMModule, getIsChequeAddressContact, getIsJobWorkOutOrder, getIsSalesReturn, getIsStockIssue, getIsStockRequest, getIsServiceRequisition, getIsPurchaseRequisition, getIsSecurityGateEntry, getIsServiceOrderTemplate, getIsPurchaseRequisitionTemplate, getIsServiceRequisitionTemplate, getIsQCInspection, getIsInspectionReport, getIsSOACustomer, getIsSOAVendor, getIsRequestForQuotation, getIsPurchaseInwardQuotation, getIsAgedReceivable } from '../../deskera-doc-builder-lib/src';
import ChequeParser from 'deskera-doc-builder-lib/src/Helper/DocumentModuleParser/ChequeParser';
export default class DocumentEditor extends Component {
    selectedColumnImageIndex = null;

    constructor(props) {
        super(props);
        this.state = {
            isFromERP: this.props.isFromERP,
            data: this.props.data,
            showDateCalendar: false,
            showDueDateCalendar: false,
        };
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
            isFromERP: nextProp.isFromERP,
        });

        CustomFieldManager.setDateFormat(nextProp.data.dateFormat)

        if (nextProp.selectedImage) {
            this.imagePopupImageSelected(nextProp.selectedImage);
        }
    }

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    handleSelectedFile = (event, value) => {
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            if (file.size > (IMG_MAX_UPLOAD_SIZE)) {
                showToast(getLocalisedText("MAX_IMG_UPLOAD_ALERT"), TOAST_TYPE.WARNING);
                return
            }
            const reader = new FileReader()
            const data = this.state.data;
            let self = this;
            reader.onload = function (e) {
                TemplateSettingsManager.updateImage((value === 'logo'), reader.result)
                data[value] = reader.result;
                self.dataChanged(data);
            }
            reader.onerror = (e) => {
                console.error(e)
            }
            reader.readAsDataURL(file);
        }
    };

    titleDataChanged = (event, value) => {
        let data = this.state.data;
        data[value] = event.target.value;
        TemplateSettingsManager.updateTypeTitle(value, event.target.value)
        this.dataChanged(data);
    };

    textDataChanged = (event, value) => {
        var data = this.state.data;

        if (value === EditorInfoType.termsAndCondition || value === EditorInfoType.notes) {
            TemplateSettingsManager.updateAdditionalDetailsText((value === EditorInfoType.termsAndCondition), event.target.value)
        }

        if (value === EditorInfoType.clientBillToAddress || value === EditorInfoType.clientShipToAddress || value === EditorInfoType.clientShipFromAddress || value === EditorInfoType.from) {
            data[value] = event.target.innerHTML;
        }
        else if (value === EditorInfoType.currency || value === EditorInfoType.checkTableCurrency) {
            data.currencyCustomValue = event.target.value;
            this.onUpdateCurrencyFormat('custom')
        } else {
            const customNoteTemplateId = [51, 52, 53, 54]
            if (value === EditorInfoType.notes) {
                if (!customNoteTemplateId.includes(this.state.data.templateId)) {
                    data[value] = event.target.value;
                }
            }
            else {
                data[value] = event.target.value;
            }
        }

        if (value === EditorInfoType.documentType) {
            TemplateSettingsManager.updateDocumentTypeLabelText(event.target.value)
        }

        if(value === EditorInfoType.documentTitle) {
            let workOrderConfig = TemplateSettingsManager.getWorkOrderSettings()
            workOrderConfig.documentTitle = event.target.value;
            TemplateSettingsManager.updateWorkOrderSettings(workOrderConfig)
        }

        if (value === EditorInfoType.generatedMessage) {
            var generatedMessage = TemplateSettingsManager.getGeneratedMessage()
            generatedMessage.customText = event.target.value
            TemplateSettingsManager.updateGeneratedMessage(generatedMessage)
        }

        this.dataChanged(data);
    };

    documentTypeChanged = (event) => {
        var data = this.state.data;
        data["type"] = event.target.value;
        data["documentType"] = event.target.value;
        AppManager.setDocumentType(event.target.value)
        var newDocumentDetailsList = TemplateSettingsManager.getDocumentDetailsList(data)
        TemplateSettingsManager.updateDocumentDetailsList(newDocumentDetailsList)
        this.dataChanged(data);
    };

    themeColorChanged = (event) => {
        var data = this.state.data;
        data["themeColor"] = event.target.value;
        TemplateSettingsManager.updateTemplateThemeColor(event.target.value)
        this.dataChanged(data);
    };

    showHideDataChanged = (value) => {
        var data = this.state.data;
        data[value] = !data[value]
        if (value === 'showPaymentFooter') {
            if (data[value]) {
                data.payments = data.paymentsFooter
            }
            else {
                data.payments = undefined
            }
        }
        TemplateSettingsManager.updateTemplateVisibility(value, data[value])
        //add stock transfer approval list
        if ((getIsStockTransfer(data.type) ||
            getIsStockIssue(data.type) ||
            getIsStockRequest(data.type) ||
            getIsSecurityGateEntry(data.type) ||
            getIsServiceRequisition(data.type) ||
            getIsOrder(data.type) ||
            getIsGoodsReceipt(data.type))
            && value === 'showApprovalSection') {
            ApprovalSectionUtility.updateApprovalElement(data.type, data.templateId)
        }
        this.dataChanged(data);
    };

    resetValue(valueStr) {
        if (valueStr === EditorInfoType.generatedMessage) {
            var generatedMessage = TemplateSettingsManager.getGeneratedMessage()
            generatedMessage.customText = 'print_only_message'
            TemplateSettingsManager.updateGeneratedMessage(generatedMessage)
            var data = this.state.data;
            this.dataChanged(data);
        }
    }

    dateFormatChanged = (event) => {
        var data = this.state.data;
        var newDataFormat = event.target.value

        if (!TemplateSettingsManager.getDateFormatIsSystemDefault()) {
            TemplateSettingsManager.updateDateFormat(newDataFormat)
            CustomFieldManager.setDateFormat(newDataFormat)
            data = this.dateChanged(data, newDataFormat);
            data["dateFormat"] = event.target.value;
            data["documentDateFormat"] = event.target.value;
        }
        this.dataChanged(data);
    };

    dateChanged(data, dataFormat) {
        if (data.date !== undefined && data.date !== null) {
            data["date"] = getDateString(getDateFrom(this.state.data.date, this.state.data.dateFormat), dataFormat);
        }
        if (data.dueDate !== undefined && data.dueDate !== null) {
            data["dueDate"] = getDateString(getDateFrom(this.state.data.dueDate, this.state.data.dateFormat), dataFormat);
        }
        if (data.additionalDate !== undefined && data.additionalDate !== null) {
            data["additionalDate"] = getDateString(getDateFrom(this.state.data.additionalDate, this.state.data.dateFormat), dataFormat);
        }

        if (data.ewayGenerated && data.ewayBillDate !== undefined && data.ewayBillDate !== null) {
            data["ewayBillDate"] = getDateString(getDateFrom(this.state.data.ewayBillDate, this.state.data.dateFormat), dataFormat);
        }

        if (data.deliveryDate && data.deliveryDate !== undefined && data.deliveryDate !== null) {
            data["deliveryDate"] = getDateString(getDateFrom(this.state.data.deliveryDate, this.state.data.dateFormat), dataFormat);
        }

        if (data.linkDocumentDate !== undefined && data.linkDocumentDate !== null) {
            data["linkDocumentDate"] = getDateString(getDateFrom(this.state.data.linkDocumentDate, this.state.data.dateFormat), dataFormat);
        }
        if (data.fromDate !== undefined && data.fromDate !== null) {
            data["fromDate"] = getDateString(getDateFrom(this.state.data.fromDate, this.state.data.dateFormat), dataFormat);
        }
        if (data.asOfDate !== undefined && data.asOfDate !== null) {
            data["asOfDate"] = getDateString(getDateFrom(this.state.data.asOfDate, this.state.data.dateFormat), dataFormat);
        }
        if (data.toDate !== undefined && data.toDate !== null) {
            data["toDate"] = getDateString(getDateFrom(this.state.data.toDate, this.state.data.dateFormat), dataFormat);
        }

        if (getIsPayslip(this.state.data.type)) {
            data['payslip'] = PayslipUtility.setDateFormat(this.state.data['payslip'], dataFormat);
        }
        else if (getIsCheque(this.state.data.type)) {
            //update data line items date
            var lineItems = data.lineItems;
            if (lineItems !== undefined && lineItems !== null) {
                if (lineItems.length > 0) {
                    lineItems.forEach(element => {
                        if (element.date !== undefined && element.date !== null && element.date !== '') {
                            element.date = getDateString(getDateFrom(element.date, this.state.data.dateFormat), dataFormat);
                            element.dateFormat = dataFormat
                        }
                        if(element.lineItems && Array.isArray(element.lineItems)) {
                            element.lineItems.forEach(subelement => {
                                if (subelement.documentDate !== undefined && subelement.documentDate !== null && subelement.documentDate !== '') {
                                    subelement.documentDate = getDateString(getDateFrom(subelement.documentDate, this.state.data.dateFormat), dataFormat);
                                    subelement['dateFormat'] = dataFormat
                                }
                            })
                        }
                    });
                }
            }
            data.lineItems = lineItems;
        }
        else if (getIsPurchaseRequisition(this.state.data.type) || getIsServiceRequisition(this.state.data.type)) {
            data["authorisedDate"] = getDateString(getDateFrom(this.state.data.authorisedDate, this.state.data.dateFormat), dataFormat);
        }
        else if (getIsGoodsReceipt(this.state.data.type)) {
            //update data line items date
            const lineItems = data.lineItems;
            if (lineItems !== undefined && lineItems !== null) {
                if (lineItems.length > 0) {
                    lineItems.forEach(element => {
                        if (!Utility.isEmpty(element.parentDocumentDate)) {
                            element.parentDocumentDate = getDateString(getDateFrom(element.parentDocumentDate, this.state.data.dateFormat), dataFormat);
                        }
                    });
                }
            }
            data.lineItems = lineItems;
        }
        else if (getIsSOACustomer(this.state.data.type) || getIsSOAVendor(this.state.data.type)) {
            //update data line items date
            const lineItems = data.lineItems;
            if (lineItems !== undefined && lineItems !== null) {
                if (lineItems.length > 0) {
                    lineItems.forEach(element => {
                        if(element.documentDate && element.documentDate !== null) {
                            element.documentDate = getDateString(getDateFrom(element.documentDate, this.state.data.dateFormat), dataFormat);
                        }
                    });
                }
            }
            data.lineItems = lineItems;

            const invoiceSummaryData = data.invoiceDueSummaryData
            if (invoiceSummaryData !== undefined && lineItems !== null) {
                if (invoiceSummaryData.length > 0) {
                    invoiceSummaryData.forEach(element => {
                        if (element.dueDate && element.dueDate !== null) {
                            element.dueDate = getDateString(getDateFrom(element.dueDate, this.state.data.dateFormat), dataFormat);
                        }
                    });
                }
            }
            data.invoiceSummaryData = invoiceSummaryData;
        }

        else if (getIsAgedReceivable(this.state.data.type)) {
            //update data line items date
            const lineItems = data.lineItems;
            if (lineItems !== undefined && lineItems !== null) {
                if (lineItems.length > 0) {
                    lineItems.forEach(element => {
                        if (element.documentDate && element.documentDate !== null) {
                            element.documentDate = getDateString(getDateFrom(element.documentDate, this.state.data.dateFormat), dataFormat);
                        }
                        if (element.documentDueDate && element.documentDueDate !== null) {
                            element.documentDueDate = getDateString(getDateFrom(element.documentDueDate, this.state.data.dateFormat), dataFormat);
                        }
                    });
                }
            }
            data.lineItems = lineItems;
        }

        if (getIsAddressCustomFieldType(this.state.data.type)) {
            AddressParser.updateAdressCF(data, this.state.data.dateFormat, dataFormat);
        }

        return data
    }

    dateFormatIsSystemDefaultChanged() {
        var isCheck = TemplateSettingsManager.getDateFormatIsSystemDefault()
        var data = this.state.data
        var newDataFormat = this.state.dataFormat
        if (isCheck) {
            newDataFormat = data.documentDateFormat
        }
        else {
            newDataFormat = data.tenantDateFormat
        }
        data = this.dateChanged(data, newDataFormat);
        data["dateFormat"] = newDataFormat
        TemplateSettingsManager.updateDateFormatIsSystemDefault(!isCheck)
        this.dataChanged(data)
    }

    multiProductTableChanged() {
        var isCheck = TemplateSettingsManager.getIsMultiProductTable()
        var data = this.state.data
        TemplateSettingsManager.setIsMultiProductTable(!isCheck)
        TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(false)
        this.dataChanged(data)
    }

    showOptionalProductGroupTableChanged() {
        var isCheck = TemplateSettingsManager.getIsShowOptionalProductGroupTable()
        var data = this.state.data
        TemplateSettingsManager.setIsShowOptionalProductGroupTable(!isCheck)
        this.dataChanged(data)
    }

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////

    dataChanged(data) {
        this.setState(
            {
                data: data,
            },
            () => {
                this.props.onDataChanged(this.state.data);
            }
        );
    }

    //////////////////////////////////////////////////

    getListPickerArrow() {
        return (
            <img
                className="ListPickerArrowStyle"
                // style={{ top: 58, right: 25 }}
                src={Asset.icon.ic_listPickerArrow2}
                alt=""
            />
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    render() {
        if (this.props.isAddComponentPopupRequired || this.props.isViewComponentPopupRequired) {
            return this.renderComponentPanel()
        }
        else {
            return this.renderTemplateConfiguration()
        }
    }

    renderTemplateConfiguration() {
        return (
            <div
                style={{
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    // padding: 6,
                }}
            >
                {!getIsPayrollCheck(this.state.data.type) && this.renderComponentSection()}
                {(this.state.isFromERP === true || AppManager.getDesignInfo() !== undefined)  ? this.getTextfield(
                    'document_type',
                    getIsPayslip(this.state.data.type) ? this.state.data.type : this.state.data.documentType,
                    EditorInfoType.documentType,
                    this.textDataChanged,
                    false
                ) : this.getDocumentTypeListPicker()}

                {
                    getIsWorkOrder(this.state.data.type) && 
                    this.getTextfield(
                        'document_title',
                        TemplateSettingsManager.getWorkOrderDocumentTitle() ?? '',
                        EditorInfoType.documentTitle,
                        this.textDataChanged,
                        false
                    )

                }

                {getIsPayslip(this.state.data.type) && this.getTextfield(
                    'header_title',
                    this.state.data.documentType,
                    EditorInfoType.documentType,
                    this.textDataChanged,
                    true
                )}

                {this.getPaperFormatListPicker()}

                {this.getPaperOrientationListPicker()}

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !TemplateSettingsManager.getRepeatableHeaderFooterIsVisible() &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    !Utility.getIsReceiptPaperSize() &&
                    !Utility.getIsRepeatableHeaderFooterTemplate(this.state.data.templateId) &&
                    this.getTextView(
                        'repeatable_header',
                        null,
                        EditorInfoType.repeatableHeader,
                        null
                    )
                }

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    !Utility.getIsProductGroupingWithMultiTable() &&
                    !Utility.getIsReceiptPaperSize() &&
                    !Utility.getIsRepeatableHeaderFooterTemplate(this.state.data.templateId) &&
                    this.getTextView(
                        'repeatable_header_footer',
                        null,
                        EditorInfoType.repeatableHeaderFooter,
                        null
                    )
                }

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    TemplateSettingsManager.getRepeatableHeaderFooterIsVisible() &&
                    !Utility.getIsReceiptPaperSize() &&
                    this.getTextView(
                        'repeatable_footer',
                        null,
                        EditorInfoType.repeatableFooter,
                        null
                    )
                }

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsPickList(this.state.data.type) &&
                    !getIsPackList(this.state.data.type) &&
                    !getIsShipList(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !Utility.getIsReceiptPaperSize() &&
                    this.getTextView(
                        'subTable_configuration',
                        null,
                        EditorInfoType.additionalLeftFooterTable,
                        null
                    )
                }

                {(getIsInvoice(this.state.data.type) ||
                    getIsSalesOrder(this.state.data.type) ||
                    getIsQuotation(this.state.data.type) ||
                    getIsRequestForQuotation(this.state.data.type) ||
                    getIsPurchaseInwardQuotation(this.state.data.type) ||
                    getIsBill(this.state.data.type) ||
                    getIsOrder(this.state.data.type)) &&
                    !Utility.getIsReceiptPaperSize() &&
                    this.getTextView(
                        'watermark',
                        null,
                        EditorInfoType.watermark,
                        null
                    )
                }
                {this.getEditView(
                    'header_footer_section',
                    EditorInfoType.headerFooter,
                )}

                {!getIsCheque(this.state.data.type) && !getIsPayrollCheck(this.state.data.type) && this.getThemeColorPicker()}
                

                {!getIsPayslip(this.state.data.type)
                    && !getIsCheque(this.state.data.type)
                    && !getIsPayrollCheck(this.state.data.type)
                    && !getIsEwayBill(this.state.data.type)
                    && !getIsBOMModule(this.state.data.type)
                    && this.getThemeFontStyle(OwnerType.tenant)}

                {this.state.isFromERP
                    && !getIsPayslip(this.state.data.type)
                    && !getIsCheque(this.state.data.type)
                    && !getIsPayrollCheck(this.state.data.type)
                    && !getIsEwayBill(this.state.data.type)
                    && !getIsWorkOrder(this.state.data.type)
                    && !getIsQCDocument(this.state.data.type)
                    && !getIsQCInspection(this.state.data.type)
                    && !getIsInspectionReport(this.state.data.type)
                    && !getIsStockTransfer(this.state.data.type)
                    && !getIsStockAdjustment(this.state.data.type)
                    && !getIsPurchaseRequisition(this.state.data.type)
                    && !getIsServiceRequisition(this.state.data.type)
                    && !getIsServiceOrderTemplate(this.state.data.templateId)
                    && this.getThemeFontStyle(OwnerType.contact)}

                {getIsDebitOrCreditNote(this.state.data.type) &&
                    this.getTextView(
                        'product_table',
                        null,
                        EditorInfoType.accountProductTable,
                        null
                    )
                }

                {!getIsPayslip(this.state.data.type)
                    && !getIsCheque(this.state.data.type)
                    && !getIsPayrollCheck(this.state.data.type)
                    && !getIsShipList(this.state.data.type)
                    && !getIsEwayBill(this.state.data.type)
                    && !getIsInspectionReport(this.state.data.type)
                    && this.getTableStyle()}

                {this.getIsContainsCustomField() && this.getCustomFields()}

                {(getIsBill(this.state.data.type)
                    || getIsOrder(this.state.data.type))
                    && !getIsServiceOrderTemplate(this.state.data.templateId)
                    && this.getIsSwapAddressSection()
                }

                {!getIsCheque(this.state.data.type)
                    && !getIsPayrollCheck(this.state.data.type)
                    && !getIsEwayBillDetails(this.state.data.type)
                    && !getIsQCDocument(this.state.data.type)
                    && !getIsQCInspection(this.state.data.type)
                    && !getIsBOMModule(this.state.data.type)
                    && this.getImageSelection("company_logo",
                        "logo",
                        EditorInfoType.companyLogo,
                        Asset.icon.ic_thumbnail_default2,
                        { borderRadius: this.state.data["logo"] === null ? '50%' : '5%', height: 100 })
                }

                {!getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    this.getTextfield(
                        this.getYourCompanyNameLabel(),
                        this.getCompanyName(),
                        EditorInfoType.companyName,
                        this.textDataChanged
                    )}

                {!getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsShipList(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    this.getIsNotAllowEditCompanyAddressTemplate() &&
                    this.getTextView(
                        'company_address',
                        this.getCompanyAddress(),
                        EditorInfoType.from,
                        this.textDataChanged,
                    )}

                {/*{ComponentManager.addVerticalSpace(30)}*/}

                {this.isInvoiceModule() && Utility.getIsIndiaCountry(this.state.data) &&
                    this.getEInvoiceConfiguration()
                }

                {(this.getIsPurchaseOrderAndBillTemplates() ||
                    getIsServiceOrderTemplate(this.state.data.templateId)) &&
                    this.getTextView(
                        this.state.data.shipFromTitle,
                        this.state.data.clientShipFromAddress,
                        EditorInfoType.clientShipFromAddress,
                        this.textDataChanged,
                        !getIsPayslip(this.state.data.type),
                        this.titleDataChanged
                    )
                }

                {!getIsJournalEntry(this.state.data.type) &&
                    !getIsPickList(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    !getIsStockRequest(this.state.data.type) &&
                    !getIsStockIssue(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsServiceOrderTemplate(this.state.data.templateId) &&
                    (
                        !getIsCheque(this.state.data.type) ||
                        (getIsCheque(this.state.data.type) && getIsChequeAddressContact(this.state.data.templateId))
                    ) &&
                    this.getTextView(
                        getIsCheque(this.state.data.type) ? 'contact_address' : this.getEmployeeAddressLabel(),
                        this.getEmployeeAddress(),
                        EditorInfoType.clientBillToAddress,
                        getIsCheque(this.state.data.type)? null : this.textDataChanged,
                        getIsCheque(this.state.data.type) ? false : !getIsPayslip(this.state.data.type),
                        getIsCheque(this.state.data.type) ? null : this.titleDataChanged
                    )}

                {!this.getIsExcludedCreditAndDebitNoteShipTo() &&
                    !getIsJournalEntry(this.state.data.type) &&
                    !getIsPickList(this.state.data.type) &&
                    !getIsPackList(this.state.data.type) &&
                    !getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    !getIsStockRequest(this.state.data.type) &&
                    !getIsStockIssue(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsSOACustomer(this.state.data.type) &&
                    !getIsSOAVendor(this.state.data.type) &&
                    !getIsAgedReceivable(this.state.data.type) &&
                    this.getTextView(
                        this.state.data.shipToTitle,
                        this.getIsArabicAddress(this.state.data, EditorInfoType.clientShipToAddress) ? this.state.data.clientShipToAddressInArabic : this.state.data.clientShipToAddress,
                        EditorInfoType.clientShipToAddress,
                        this.textDataChanged,
                        true,
                        this.titleDataChanged
                    )
                }

                {(getIsQuotation(this.state.data.type) ||
                    getIsSalesOrder(this.state.data.type) ||
                    getIsInvoice(this.state.data.type) ||
                    getIsServiceOrderTemplate(this.state.data.templateId)) &&
                    Utility.getIsIndiaCountry(this.state.data) &&
                    this.getTextView(
                        'place_of_supply',
                        null,
                        EditorInfoType.placeOfSupply,
                        null
                    )
                }

                {(getIsBill(this.state.data.type) ||
                    getIsOrder(this.state.data.type)) &&
                    this.state.data.templateId === 63 &&
                    this.getTransporterDetailsPicker()
                }

                {(getIsBill(this.state.data.type) ||
                    getIsOrder(this.state.data.type)) &&
                    !getIsServiceOrderTemplate(this.state.data.templateId) &&
                    Utility.getIsIndiaCountry(this.state.data) &&
                    this.getTextView(
                        'source_of_supply',
                        null,
                        EditorInfoType.sourceOfSupply,
                        null
                    )
                }

                {(getIsBill(this.state.data.type) ||
                    getIsOrder(this.state.data.type)) &&
                    !getIsServiceOrderTemplate(this.state.data.templateId) &&
                    Utility.getIsIndiaCountry(this.state.data) &&
                    this.getTextView(
                        'destination_of_supply',
                        null,
                        EditorInfoType.destinationOfSupply,
                        null
                    )
                }

                {getIsPayslip(this.state.data.type) &&
                    this.getPayslipConfigurationView(
                        'field_configuration',
                        EditorInfoType.payslipField,
                        (editorType) => this.props.onPayslipConfigurationClick(editorType)
                    )
                }

                {getIsPayslip(this.state.data.type) &&
                    this.getPayslipConfigurationView(
                        'table_configuration',
                        EditorInfoType.payslipTable,
                        (editorType) => this.props.onPayslipConfigurationClick(editorType)
                    )
                }

                {getIsPayslip(this.state.data.type) &&
                    Utility.getIsIndiaCountry(this.state.data) &&
                    this.getPayslipConfigurationView(
                        'earnings_configuration',
                        EditorInfoType.payslipEarningsFields,
                        (editorType) => this.props.onEarningsConfigurationClick(editorType)
                    )
                }

                {getIsPayslip(this.state.data.type) &&
                    this.getTextView(
                        'payslip_overtime',
                        null,
                        EditorInfoType.payslipOvertime,
                        null
                    )
                }

                {getIsPayslip(this.state.data.type) &&
                    PayslipUtility.getCountryCode(this.state.data.payslip) !== "SG" &&
                    this.getTextView(
                        'payslip_LOP',
                        null,
                        EditorInfoType.payslipLOP,
                        null
                    )
                }

                {getIsPayslip(this.state.data.type) &&
                    PayslipUtility.getCountryCode(this.state.data.payslip) === "MY" &&
                    this.getTextView(
                        'basic_salary_wo_lop',
                        null,
                        EditorInfoType.payslipBasic,
                        null
                    )
                }

                {/*{ComponentManager.addVerticalSpace(30)}*/}
                {!getIsFulfillment(this.state.data.type) &&
                    !getIsPickList(this.state.data.type) &&
                    !getIsPackList(this.state.data.type) &&
                    !getIsShipList(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    !getIsStockRequest(this.state.data.type) &&
                    !getIsStockIssue(this.state.data.type) &&
                    !getIsRequestForQuotation(this.state.data.type) &&
                    this.getTextfield(
                        'currency',
                        this.state.data.currencyCustomValue,
                        EditorInfoType.currency,
                        this.textDataChanged,
                        true,
                    )
                }

                {getIsCheque(this.state.data.type) &&
                    this.getTextfield(
                        'currency',
                        this.state.data.currencyCustomValue,
                        EditorInfoType.checkTableCurrency,
                        this.textDataChanged,
                        true,
                    )
                }

                {!getIsShipList(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    this.getDocumentDetails()}

                {!getIsShipList(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    this.getTextView(
                        'top_section_above_table',
                        null,
                        EditorInfoType.topSectionAboveTable,
                        null
                    )}

                {getIsInspectionReport(this.state.data.type) &&
                    this.getProductListingListPicker()}

                {!getIsPickList(this.state.data.type) &&
                    !getIsPackList(this.state.data.type) &&
                    !getIsShipList(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    this.getDecimalScaleListPicker()}

                {this.getQuantityLabel() !== undefined &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsPayslip(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    this.getQuantityDecimalScaleListPicker()}

                {/* {!getIsShipList(this.state.data.type) &&
                    !getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                        this.getTextfield(
                            this.state.data.type.toLowerCase(),
                            this.state.data.refNumber,
                            EditorInfoType.refNumber,
                            this.textDataChanged
                )} */}

                {!getIsShipList(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    !getIsRequestForQuotation(this.state.data.type) &&
                    this.getDateFormatListPicker()
                }

                {getIsWorkOrder(this.state.data.type) && this.getWorkOrderProductPicker()}

                {getIsWorkOrder(this.state.data.type) && this.getWorkOrderSubstitutePicker()}

                {/* {!getIsShipList(this.state.data.type) &&
                    !getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    this.getDateButton(this.state.data.date)}

                {this.isContainReferenceDateOrDueDate() && !getIsPayrollCheck(this.state.data.type) && this.getDueDateButton(this.state.data.dueDate)} */}

                {/* {getIsProductDocumentForBuySell(this.state.data) && this.getAdditionalDateButton(this.state.data.additionalDate)} */}

                {/* {this.isContainLinkDocument() &&
                    this.getTextfield(
                        AppManager.getTypeNoString(this.state.data.linkDocumentType),
                        this.state.data.linkDocumentNumber,
                        EditorInfoType.linkDocumentNumber,
                    this.textDataChanged
                )} */}

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsJobWorkOutOrder(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    !getIsStockRequest(this.state.data.type) &&
                    !getIsStockIssue(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    !getIsRequestForQuotation(this.state.data.type) &&
                    (this.getQuantityLabel() !== undefined ||
                        getIsFulfillment(this.state.data.type) ||
                        getIsGoodsReceipt(this.state.data.type)) &&
                    this.getGlobalDiscountTypePicker()
                }

                {getIsBillOrInvoice(this.state.data.type) &&
                    this.getTextView(
                        'payment_footer',
                        null,
                        EditorInfoType.paymentFooter,
                        null
                    )
                }

                {(getIsInvoice(this.state.data.type) ||
                    getIsSalesOrder(this.state.data.type) ||
                    getIsQuotation(this.state.data.type) ||
                    getIsWorkOrder(this.state.data.type)) &&
                    !Utility.getIsReceiptPaperSize() &&
                    this.getTextView(
                        'document_label',
                        null,
                        EditorInfoType.documentLabel,
                        null
                    )
                }
                {(getIsInvoice(this.state.data.type) ||
                    getIsSalesOrder(this.state.data.type) ||
                    getIsQuotation(this.state.data.type) ||
                    getIsRequestForQuotation(this.state.data.type) ||
                    getIsWorkOrder(this.state.data.type)) &&
                    !Utility.getIsReceiptPaperSize() &&
                    this.getBarcodeConfiguration()
                }

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsStockTransfer(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    !getIsStockAdjustment(this.state.data.type) &&
                    !getIsSalesReturn(this.state.data.type) &&
                    !getIsJobWorkOutOrder(this.state.data.type) &&
                    !getIsBOMModule(this.state.data.type) &&
                    !getIsStockRequest(this.state.data.type) &&
                    !getIsStockIssue(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    (this.getQuantityLabel() !== undefined ||
                        getIsFulfillment(this.state.data.type) ||
                        getIsGoodsReceipt(this.state.data.type)) &&
                    this.getProductGroupingPicker(this.state.data.type)
                }

                {/* {!getIsPayslip(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !this.state.isFromERP &&
                    this.getColumnSection(this.state.data.lineItems)
                } */}

                {(getIsQuotation(this.state.data.type) ||
                    getIsSalesOrder(this.state.data.type) ||
                    getIsInvoice(this.state.data.type) ||
                    getIsOrder(this.state.data.type) ||
                    getIsBill(this.state.data.type)) &&
                    !getIsServiceOrderTemplate(this.state.data.templateId) &&
                    this.getTextView(
                        this.state.data.exchangeRateTitle,
                        null,
                        EditorInfoType.exchangeRate,
                        null,
                        true,
                        this.titleDataChanged
                    )
                }

                {Utility.getIsContainBomProduct(this.state.data.type) &&
                    this.getTextView(
                        'bom_product_table',
                        null,
                        EditorInfoType.isIncludesBomProductTable,
                        null
                    )
                }

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsServiceOrderTemplate(this.state.data.templateId) &&
                    this.getImageSelection("signature",
                        "signature",
                        EditorInfoType.signature,
                        Asset.icon.signature,
                        { width: EDITOR_WIDTH / 2.5, height: 60 })
                }

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsServiceOrderTemplate(this.state.data.templateId) &&
                    this.getTextView(
                        Utility.getTermsAndConditionTitle('terms_&_condition'),
                        this.state.data.termsAndCondition,
                        EditorInfoType.termsAndCondition,
                        this.textDataChanged,
                        true,
                        this.titleDataChanged
                    )}

                {!getIsPayslip(this.state.data.type) &&
                    !getIsCheque(this.state.data.type) &&
                    !getIsPayrollCheck(this.state.data.type) &&
                    !getIsEwayBill(this.state.data.type) &&
                    !getIsWorkOrder(this.state.data.type) &&
                    !getIsQCDocument(this.state.data.type) &&
                    !getIsQCInspection(this.state.data.type) &&
                    !getIsInspectionReport(this.state.data.type) &&
                    !getIsPurchaseRequisition(this.state.data.type) &&
                    !getIsServiceRequisition(this.state.data.type) &&
                    !getIsSecurityGateEntry(this.state.data.type) &&
                    this.getTextView(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? 'memo' : 'notes'),
                        this.getNotesValue(),
                        EditorInfoType.notes,
                        this.textDataChanged,
                        true,
                        this.titleDataChanged
                    )
                }

                {IndiaTaxParser.getIsIndiaDefaultTaxTemplates(this.state.data.templateId) &&
                    this.getTextView(
                        'hsn_sac_tax_table',
                        null,
                        EditorInfoType.hsnSacTaxTable,
                        null
                    )
                }

                {
                    (getIsQuotation(this.state.data.type) ||
                        getIsInvoice(this.state.data.type) ||
                        getIsRequestForQuotation(this.state.data.type) ||
                        getIsPurchaseInwardQuotation(this.state.data.type) ||
                        getIsBill(this.state.data.type) ||
                        getIsOrder(this.state.data.type) ||
                        getIsSalesOrder(this.state.data.type) ||
                        getIsStockTransfer(this.state.data.type) ||
                        getIsStockIssue(this.state.data.type) ||
                        getIsServiceRequisition(this.state.data.type) ||
                        getIsStockRequest(this.state.data.type) ||
                        getIsSecurityGateEntry(this.state.data.type) ||
                        getIsPurchaseRequisition(this.state.data.type) ||
                        getIsGoodsReceipt(this.state.data.type))
                        && this.state.data.templateId !== 51
                        && this.getTextView(
                            'approval_section',
                            null,
                            EditorInfoType.approvalSection,
                            null
                        )
                }

                {
                    (getIsQuotation(this.state.data.type) ||
                        getIsInvoice(this.state.data.type) ||
                        getIsRequestForQuotation(this.state.data.type) ||
                        getIsPurchaseInwardQuotation(this.state.data.type) ||
                        getIsBill(this.state.data.type) ||
                        getIsOrder(this.state.data.type) ||
                        getIsSalesOrder(this.state.data.type))
                        && this.state.data.templateId === 51
                        && this.getTextView(
                            'approval_section',
                            null,
                            EditorInfoType.customApprovalSection,
                            null
                        )
                }

                {/* {
                    (getIsQuotation(this.state.data.type) ||
                        getIsInvoice(this.state.data.type) ||
                        getIsBill(this.state.data.type) ||
                        getIsOrder(this.state.data.type) ||
                        getIsSalesOrder(this.state.data.type))
                    && this.getApprovalSection()
                } */}
                
                { 
                    (getIsJobWorkOutOrder(this.state.data.type) ||
                    this.state.data.templateId === 64 || //AG template
                    IndiaTaxParser.getIsIndiaDefaultTaxTemplates(this.state.data.templateId)) &&
                    this.getTextView(
                        getLocalisedText('generated_message'),
                        this.getGeneratedMessage(),
                        EditorInfoType.generatedMessage,
                        this.textDataChanged,
                        true,
                        undefined,
                    )
                }

                {Utility.getIsUSCountry(this.state.data) &&
                    getIsCheque(this.state.data.type) &&
                    this.state.data.templateId === 7 &&
                    this.getCheckFields()
                }
                {getIsCheque(this.state.data.type) &&
                this.state.data.templateId === 7 &&
                    this.getCheckTableView(
                        'Check Details',
                        EditorInfoType.checkTable,
                        false,
                        null
                    )}
                {Utility.getIsUSCountry(this.state.data) &&
                    getIsCheque(this.state.data.type) &&
                    this.state.data.templateId === 7 &&
                    this.getCheckGridStyleSection()
                }

                {/*{this.getDiscountSelectionSection()}*/}
                {/*{this.getTaxSelectionSection()}*/}
                <div style={{ height: 100 }} />
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    renderComponentPanel() {
        return (
            <div
                style={{
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    padding: 6,
                }}
            >
                {this.renderComponentSection()}
                <div style={{ height: 100 }} />
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getSectionHeader(str, isRefNumber = false, fontSize = 12, marginTop = 15) {
        return (
            <text
                style={{
                    fontSize: fontSize,
                    color: "black",
                    fontWeight: "600",
                    // width: '90%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                }}
            >
                {isRefNumber ? getLocalisedText(str) + getLocalisedText('num') : getLocalisedText(str)}
            </text>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getDocumentTypeListPicker() {
        let documentTypeList = [
            { type: 'estimate', value: 'estimate', name: 'estimate' },
            { type: 'quotation', value: 'quotation', name: 'quotation' },
            { type: 'sales_order', value: 'sales_order', name: 'sales_order' },
            { type: 'invoice', value: 'invoice', name: 'invoice' },
            { type: 'bom', value: 'bom', name: 'Bom' },
            { type: 'purchase order', value: 'purchase order', name: 'purchase_order' },
            { type: 'bill', value: 'bill', name: 'bills' },
            { type: 'expense', value: 'expense', name: 'expense' },
            { type: 'deposit', value: 'deposit', name: 'deposit' },
            { type: 'credit note', value: 'credit_note', name: 'credit_note' },
            { type: 'debit note', value: 'debit_note', name: 'debit_note' },
            { type: 'fulfillment', value: 'fulfillment', name: 'fulfillment' },
            { type: 'goods_receipt', value: 'goods_receipt', name: 'goods_receipt' },
            { type: 'make_payment', value: 'make_payment', name: 'make_payment' },
            { type: 'receive_payment', value: 'receive_payment', name: 'receive_payment' },
            { type: 'journal_entry', value: 'journal_entry', name: 'journal_entry' },
            { type: 'pick_list', value: 'pick_list', name: 'pick_list' },
            { type: 'pack_list', value: 'pack_list', name: 'pack_list' },
            { type: 'ship_list', value: 'ship_list', name: 'ship_list' },
            { type: 'payslip', value: 'payslip', name: 'payslip' },
            { type: 'cheque', value: 'cheque', name: 'cheque' },
            { type: 'payroll_check', value: 'payroll_check', name: 'payroll_check' },
            { type: 'e_way_bill_summary', value: 'e_way_bill_summary', name: 'e_way_bill_summary' },
            { type: 'e_way_bill_details', value: 'e_way_bill_detail', name: 'e_way_bill_detail' },
            { type: 'work_order', value: 'work_order', name: 'work_order' },
            { type: 'job_work_out_order', value: 'job_work_out_order', name: 'job_work_out_order' },
            { type: 'machine', value: 'machine', name: 'machine' },
            // { type: 'job_card', value: 'job_card', name: 'job_card' },
            // { type: 'purchase_requisition', value: 'purchase_requisition', name: 'purchase_requisition' },
            // { type: 'qc_template', value: 'qc_template', name: 'qc_template' },
            { type: 'stock_transfer', value: 'stock_transfer', name: 'stock_transfer' },
            { type: 'stock_adjustment', value: 'stock_adjustment', name: 'stock_adjustment' },
            { type: 'sales_return', value: 'sales_return', name: 'sales_return' },
            { type: 'stock_request', value: 'stock_request', name: 'stock_request' },
            { type: 'stock_issue', value: 'stock_issue', name: 'stock_issue' },
            { type: 'purchase_requisition', value: 'purchase_requisition', name: 'purchase_requisition' },
            { type: 'service_requisition', value: 'service_requisition', name: 'service_requisition' },
            { type: 'gate_entry', value: 'gate_entry', name: 'gate_entry' },
            { type: 'qc_inspection', value: 'qc_inspection', name: 'qc_inspection' },
            { type: 'inspection_report', value: 'inspection_report', name: 'inspection_report' },
            { type: 'soa_vendor', value: 'soa_vendor', name: 'soa_vendor' },
            { type: 'soa_customer', value: 'soa_customer', name: 'soa_customer' },
            { type: 'purchase_request_for_quotes', value: 'purchase_request_for_quotes', name: 'purchase_request_for_quotes' },
            { type: 'purchase_inward_quotation', value: 'purchase_inward_quotation', name: 'purchase_inward_quotation' },
            { type: 'aged_receivable', value: 'aged_receivable', name: 'aged_receivable' },
        ]


        const optionList = documentTypeList.sort((a, b) => a.name.localeCompare(b.name)).map(element => {
            return <option value={element.value} selected={this.state.data.type.toLowerCase() === element.type}>
                {getLocalisedText(element.name)}
            </option>
        });
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow">
                    {this.getSectionHeader("document_type")}
                </div>
                <select
                    onChange={(e) => {
                        this.resetEInvoiceStatus()
                        this.documentTypeChanged(e)
                        TemplateSettingsManager.updateRemoteTemplateUID(null)
                    }}
                    className="TextField ListPicker"
                    style={this.getPickerStyle()}
                >
                    {optionList}
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    resetEInvoiceStatus(event) {
        if (this.isInvoiceModule() && Utility.getIsIndiaCountry(this.state.data)) {
            TemplateSettingsManager.updateEInvoiceSectionVisibility(true)
        }
        else {
            TemplateSettingsManager.updateEInvoiceSectionVisibility(false)
        }
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getHideShowButton(valueStr) {
        return <div onClick={() => {
            this.showHideDataChanged(this.getSelectedValueFor(valueStr))
        }} className="HeaderButton">
            <text>{this.getShowHideValueFor(valueStr) ? getLocalisedText("hide") : getLocalisedText("show")}</text>
        </div>
    }
    getEditButton(valueStr) {
        return <div onClick={() => {
            document.getElementById(valueStr).focus()
        }} className="HeaderButton" style={{ marginRight: 5 }}>
            <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
        </div>
    }

    getResetButton(valueStr) {
        return <div onClick={() => {
            this.resetValue(valueStr)
        }} className="HeaderButton" style={{
            marginRight: 5,
            display: 'flex'
        }}>
            <img src={Asset.icon.ic_refresh_black} alt="reset" style={{ width: 10 }} />
        </div>
    }

    getCurrencyButton(valueStr) {
        var isCurrencyEnable = true
        var isCurrencyNameEnable = true
        var currencyFormat = this.props.data.currencyFormat

        if (currencyFormat !== undefined && currencyFormat !== null) {
            switch (currencyFormat) {
                case CurrencyFormatType.SYMBOL:
                    isCurrencyNameEnable = false
                    break;
                case CurrencyFormatType.NAME:
                    isCurrencyEnable = false
                    break;
                case CurrencyFormatType.CUSTOM:
                    isCurrencyNameEnable = false
                    isCurrencyEnable = false
                    break;
                default:
                    break;
            }
        }

        return <div className='RowDiv' style={{
            justifyContent: Utility.getIsArabicLang() ? 'normal' : 'flex-end',
            paddingLeft: 4,
            paddingRight: 4,
        }}>

            {this.getTextWithCheckmark(isCurrencyNameEnable, 'name', this.state.data.currencyName, valueStr)}
            {ComponentManager.addHorizontalSpace(4)}
            {this.state.data.currency !== undefined && this.getTextWithCheckmark(isCurrencyEnable, 'symbol', this.state.data.currency, valueStr)}
        </div>
    }

    getTextWithCheckmark(isSelected, type, text, valueStr) {
        return <div className="HeaderButton"
            style={{
                backgroundColor: 'rgb(230, 230, 230)',
                color: 'rgb(50, 50, 50)'
            }}
            onClick={() => {
                if (valueStr === EditorInfoType.currency || valueStr === EditorInfoType.checkTableCurrency) {
                    this.onUpdateCurrencyFormat(type)
                }
                else if (valueStr === EditorInfoType.eInvoiceSection) {
                    this.onUpdateEInvoicePosition(type)
                }
            }}>
            <div className='RowDiv'>
                <text>{text}</text>
                {
                    isSelected &&
                    <img
                        src={Asset.icon.ic_checkmarkCircle}
                        alt=""
                        style={{
                            paddingLeft: 4,
                            width: 14
                        }}
                    />
                }
            </div>
        </div>
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getTextView(title, value, valueStr, valueChangeCallback, isTitleEditable = false, titleChangeCallback = null) {
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {isTitleEditable ? this.renderTitleArea(title, valueStr, titleChangeCallback) : this.getSectionHeader(title)}
                    {Utility.getIsUSCountry(this.state.data) &&
                    getIsCheque(this.state.data.type) &&
                    this.state.data.templateId === 7 && this.getFontAddressButton()}
                    {isTitleEditable && !this.getIsShowResetButton(valueStr) && this.getShowHideValueFor(valueStr) && this.getEditButton(valueStr)}
                    {this.getIsShowResetButton(valueStr) && this.getShowHideValueFor(valueStr) && this.getResetButton(valueStr)}
                    {this.getIsShowCallbackEditButton(valueStr) && this.getEditButtonCallback(valueStr)}
                    {this.getIsShowHideButton(valueStr) && this.getHideShowButton(valueStr)}
                </div>
                {this.getIsShowTextArea(valueStr) && this.getShowHideValueFor(valueStr) && this.renderValueArea(title, value, valueStr, valueChangeCallback)}
                {this.renderFontSetting(valueStr)}
                {this.renderPageCountSettings(valueStr)}
                {valueStr === EditorInfoType.watermark && this.state.data.isDraft && this.renderDraftWatermark()}
            </div>
        );
    }

    getCheckTableView(title, valueStr, isTitleEditable = false, titleChangeCallback = null) {
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {isTitleEditable ? this.renderTitleArea(title, valueStr, titleChangeCallback) : this.getSectionHeader(title)}
                    {this.getShowHideValueFor(valueStr) && this.getIsRenderCheckTableConfigureSection(valueStr) && this.getCheckTableEditButton(valueStr)}
                    {this.getIsShowHideButton(valueStr) && this.getHideShowButton(valueStr)}
                </div>
                {/* {this.getIsShowTextArea(valueStr) && this.getShowHideValueFor(valueStr) && this.renderValueArea(title, value, valueStr, valueChangeCallback)} */}
            </div>
        );
    }

    getPayslipConfigurationView(title, editorType, callback) {
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader(title)}
                    <div
                        onClick={() => callback(editorType)}
                        className="HeaderButton"
                        style={{ marginRight: 5 }}
                    >
                        <img
                            src={Asset.icon.ic_edit}
                            alt="edit" style={{ width: 10 }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getEditView(title, valueStr) {
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader(title)}
                    {
                        this.getShowHideValueFor(valueStr) &&
                        <div
                            onClick={this.props.onHeaderFooterClick}
                            className="HeaderButton"
                            style={{ marginRight: 5 }}>
                            <img src={Asset.icon.ic_edit}
                                alt="edit"
                                style={{ width: 10 }}
                            />
                        </div>
                    }
                    {this.getHideShowButton(valueStr)}
                </div>
            </div>
        );
    }

    getIsShowTextArea(valueStr) {
        switch (valueStr) {
            case EditorInfoType.repeatableHeader:
                return false
            case EditorInfoType.repeatableHeaderFooter:
                return false
            case EditorInfoType.repeatableFooter:
                return false
            case EditorInfoType.additionalLeftFooterTable:
                return false
            case EditorInfoType.watermark:
                return false
            case EditorInfoType.placeOfSupply:
                return false
            case EditorInfoType.sourceOfSupply:
                return false
            case EditorInfoType.destinationOfSupply:
                return false
            case EditorInfoType.paymentFooter:
                return false
            case EditorInfoType.topSectionAboveTable:
                return false
            case EditorInfoType.payslipOvertime:
                return false
            case EditorInfoType.payslipLOP:
                return false
            case EditorInfoType.payslipBasic:
                return false
            case EditorInfoType.accountProductTable:
                return false
            case EditorInfoType.documentLabel:
                return false
            case EditorInfoType.exchangeRate:
                return false
            case EditorInfoType.isIncludesBomProductTable:
                return false
            case EditorInfoType.hsnSacTaxTable:
                return false
            case EditorInfoType.approvalSection:
                return false
            case EditorInfoType.customApprovalSection:
                return false
            default:
                return true
        }
    }

    renderTitleArea(title, valueStr, titleChangeCallback) {
        return (
            <input
                id={valueStr}
                className="EditableLabel"
                placeholder=""
                type="text"
                dir={Utility.getInputDirection()}
                value={getLocalisedText(title)}
                style={{ marginRight: 5 }}
                disabled={(titleChangeCallback === null || titleChangeCallback === undefined)}
                onChange={(e) => {
                    if (titleChangeCallback !== undefined && titleChangeCallback !== null) {
                        titleChangeCallback(e, this.getTitleType(valueStr))
                    }
                }}
            />
        )
    }

    getFontAddressButton() {
        const ownerType = "";
        return (
          <div
            style={{
              justifyContent: "flex-end",
              display: 'flex'
            }}
          >
            <div
              className="HeaderButton clicked-effect"
              style={{ marginLeft: 10,userSelect: 'none' }}
              onClick={(event) => this.updateAddressCheckFontSize("down", ownerType)}
            >
              A-
            </div>
            <div
              className="HeaderButton clicked-effect"
              style={{ marginLeft: 10,userSelect: 'none' }}
              onClick={(event) => this.updateAddressCheckFontSize("up", ownerType)}
            >
              A+
            </div>
          </div>
        );
      }

    getTitleType(type) {
        switch (type) {
            case EditorInfoType.clientBillToAddress:
                return EditorInfoType.billToTitle
            case EditorInfoType.clientShipToAddress:
                return EditorInfoType.shipToTitle
            case EditorInfoType.clientShipFromAddress:
                return EditorInfoType.shipFromTitle
            case EditorInfoType.termsAndCondition:
                return EditorInfoType.termsAndConditionTitle
            case EditorInfoType.notes:
                return EditorInfoType.notesTitle
            case EditorInfoType.exchangeRate:
                return EditorInfoType.exchangeRateTitle
            default:
                console.log('getTitleType - undefined')
                return ''
        }
    }

    renderValueArea(title, value, valueStr, valueChangeCallback) {
        return (
            this.isTnCOrNotes(valueStr) ? <textarea
                className="TextField"
                style={{
                    minHeight: 50,
                    fontFamily: "helvetica",
                    fontSize: 12,
                    resize: 'none'
                }}
                dir={Utility.getInputDirection()}
                placeholder=""
                type="text"
                value={value ? getLocalisedText(value) : ''}
                onChange={(e) => valueChangeCallback(e, valueStr)}
                disabled={this.getIsDisableTextField(valueStr)}
            /> :
                (
                    this.isCheckTable(valueStr) ?
                    this.renderCheckTableConfigureSection(valueChangeCallback, value, valueStr, title) :
                    this.renderAddressConfigureSection(valueChangeCallback, value, valueStr, title)
                )                
        )
    }

    renderAddressConfigureSection(valueChangeCallback, value, valueStr, title) {
        let isShowAddressConfig = true

        if(getIsRequestForQuotation(this.state.data.type)) {
            if(valueStr === EditorInfoType.clientBillToAddress || valueStr === EditorInfoType.clientShipToAddress) {
                isShowAddressConfig = false
            }
        }

        if (!isShowAddressConfig) {
            return <> </>
        }
        return <>
            {this.getDKContentEditableDiv(valueChangeCallback, value, valueStr, title)}
            {
                this.getIsRenderAddressConfigureSection(valueStr) &&
                <div
                    className='RowDiv HeaderRow'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('address_format')}
                    {/*  */}
                    {this.getAddressEditButton(valueStr)}
                </div>
            }
        </>
    }

    renderCheckTableConfigureSection(valueChangeCallback, value, valueStr, title) {
        return <>
            {/* {this.getDKContentEditableDiv(valueChangeCallback, value, valueStr, title)} */}
            {
                this.getIsRenderCheckTableConfigureSection(valueStr) &&
                <div
                    className='RowDiv HeaderRow'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('Check Details')}
                    {/*  */}
                    {this.getCheckTableEditButton(valueStr)}
                </div>
            }
        </>
    }

    getIsRenderCheckTableConfigureSection(valueStr) {
        if (!getIsCheque(this.props.data.type)) {
            return false
        }
        return true
    }

    getIsRenderAddressConfigureSection(valueStr) {
        if (valueStr === 'clientShipToAddress') {
            if (getIsExpenseOrDeposit(this.props.data.type)) {
                return false
            }
            else if (getIsMakePayment(this.props.data.type)) {
                return false
            }
            else if (getIsReceivePayment(this.props.data.type)) {
                return false
            }
        }
        if (valueStr === 'from') {
            if (getIsShipList(this.props.data.type)) {
                return false
            }
        }
        return true
    }

    getAddressEditButton(valueStr) {
        return <div
            onClick={() => {
                var val = AddressType.from
                switch (valueStr) {
                    case 'from':
                        val = AddressType.from
                        if (this.getIsPurchaseOrderAndBillTemplates()) {
                            val = AddressType.tenantAddress
                        }
                        break;
                    case 'clientBillToAddress':
                        val = AddressType.billTo
                        break;
                    case 'clientShipToAddress':
                        val = AddressType.shipTo
                        break;
                    case 'clientShipFromAddress':
                        val = AddressType.shipFrom
                        break;
                    default:
                        break;
                }
                this.props.onAddressFormatClick(val)
            }}
            className="HeaderButton"
            style={{ marginRight: 5 }}>
            <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
        </div>
    }

    getCheckTableEditButton(valueStr) {
        return <div
            onClick={() => {
                var val = 'checkTable'
                this.props.onCheckTableFormatClick(val)
            }}
            className="HeaderButton"
            style={{ marginRight: 5, marginLeft:'auto' }}>
            <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
        </div>
    }


    getEditButtonCallback(valueStr) {
        return <div
            onClick={() => {
                this.props.onEditButtonCallbackClicked(valueStr)
            }}
            className="HeaderButton"
            style={{ marginRight: 5 }}>
            <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
        </div>
    }


    getDKContentEditableDiv(valueChangeCallback, value, valueStr, title) {
        if (this.props.isFromERP || getIsPayslip(this.state.data.type)) {
            return <DKContentEditableDiv
                valueChangeCallback={valueChangeCallback}
                value={value}
                valueStr={valueStr}
                className={'TextField'}
                style={{
                    zIndex: 2,
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    fontSize: 12,
                    padding: 10,
                    width: '100%',
                    outlineWidth: 0,
                }}
                disabled={this.getIsDisableTextField(valueStr)}
            />
        }
        return <DKContentEditableDivWithRef
            valueChangeCallback={valueChangeCallback}
            value={value}
            valueStr={valueStr}
            className={'TextField'}
            style={{
                zIndex: 2,
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                fontSize: 12,
                padding: 10,
                width: '100%',
                outlineWidth: 0,
            }}
            disabled={this.getIsDisableTextField(valueStr)}
        />
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getTextfield(title, value, valueStr, callback, canBeHidden = true) {
        return (
            <div className="ListPickerHolderStyle">
                <div className='RowDiv HeaderRow'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {(valueStr === EditorInfoType.refNumber) ? this.getSectionHeader(title, true) : this.getSectionHeader(title)}
                    {title === 'currency' && this.getCurrencyButton(valueStr)}
                    {canBeHidden && this.getHideShowButton(valueStr)}
                </div>
                {this.getShowHideValueFor(valueStr) && this.getIsShowTextField(valueStr) && <input
                    className="TextField"
                    placeholder=""
                    type="text"
                    dir={Utility.getInputDirection()}
                    value={getLocalisedText(value)}
                    onChange={(e) => callback(e, valueStr)}
                    disabled={this.getIsDisableTextField(valueStr, title)}
                />}
            </div>
        );
    }

    getIsShowResetButton(valStr) {
        if (valStr === EditorInfoType.generatedMessage) {
            return true
        }
        return false
    }

    getIsShowTextField(type) {
        if (type === EditorInfoType.eInvoiceSection) {
            return false
        }

        return true
    }

    getIsShowHideButton(valStr) {
        if (getIsPayslip(this.state.data.type)) {
            if (valStr === EditorInfoType.clientBillToAddress) {
                return false
            }
            else if (valStr === EditorInfoType.payslipField) {
                return false
            }
            else if (valStr === EditorInfoType.payslipTable) {
                return false
            }
        } else if (valStr === EditorInfoType.clientBillToAddress && getIsCheque(this.state.data.type) && this.state.data.templateId === 7) {
            return false
        }
        return true
    }

    getIsShowCallbackEditButton(valStr) {
        if (valStr === EditorInfoType.additionalLeftFooterTable && TemplateSettingsManager.getAdditionalTableIsVisible()) {
            return true
        }
        else if (valStr === EditorInfoType.watermark && TemplateSettingsManager.getWatermarkVisible()) {
            return true
        }
        else if (valStr === EditorInfoType.documentLabel && TemplateSettingsManager.getDocumentLabelVisible()) {
            return true
        }
        else if (valStr === EditorInfoType.topSectionAboveTable && TemplateSettingsManager.getTopSectionAboveTableIsVisible()) {
            return true
        }
        else if (valStr === EditorInfoType.approvalSection && TemplateSettingsManager.getApprovalSectionSettingsVisible()) {
            return true
        }
        else if (valStr === EditorInfoType.customApprovalSection && TemplateSettingsManager.getCustomApprovalSectionSettingsVisible()) {
            return true
        }
        else if (valStr === EditorInfoType.isIncludesBomProductTable && Utility.getIsIncludesBomProductTable()) {
            return true
        }

        return false
    }

    getIsDisableTextField(type, title) {
        if (getIsPayslip(this.state.data.type)) {
            if (type === EditorInfoType.companyName) {
                return true
            }
            else if (type === EditorInfoType.from) {
                return true
            }
            else if (type === EditorInfoType.clientBillToAddress) {
                return true
            }
            else if (type === EditorInfoType.documentType) {
                if (title === 'header_title') {
                    return false
                }
                return true
            }
        }
        if (type === EditorInfoType.documentType) {
            return false
        }
        else if (type === EditorInfoType.currency) {
            return false
        }
        else if(type === EditorInfoType.checkTableCurrency) {
            return false
        }
        else if (type === EditorInfoType.termsAndCondition) {
            return false
        }
        else if (type === EditorInfoType.notes) {
            return false
        }
        else if (type === EditorInfoType.generatedMessage) {
            return false
        }
        else if (type === EditorInfoType.documentTitle) {
            return false
        }
        else if (type === EditorInfoType.hsnSacTaxTable) {
            return false
        }
        return this.state.isFromERP
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getImageSelection(title, value, valueStr, placeholder, style) {
        return (
            <div className="ListPickerHolderStyle">
                <div className='RowDiv HeaderRow'>
                    <div className='RowDiv'
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                        {this.getSectionHeader(title)}
                    </div>
                    {!this.getShowHideValueFor(valueStr) && this.getHideShowButton(valueStr)}
                    {this.getShowHideValueFor(valueStr) &&
                        <div className='RowDiv' style={{ justifyContent: 'space-between', width: '75%' }}>
                            <div className='HeaderButton' onClick={(event) => this.updateImageSize('up', value)}>
                                +
                            </div>

                            <div className='HeaderButton' onClick={(event) => this.updateImageSize('down', value)}>
                                -
                            </div>
                            {this.getHideShowButton(valueStr)}
                        </div>
                    }
                </div>
                {this.getShowHideValueFor(valueStr) && <div className="RowDiv TextField" style={{ alignItems: 'center' }}>
                    <div className="ColumnDiv" style={{}}>
                        <img src={this.state.data && this.state.data[value] ? this.state.data[value] : placeholder}
                            alt=""
                            style={{
                                ...{
                                    width: 100,
                                    borderRadius: '5%',
                                    objectFit: "contain",
                                    border: "solid",
                                    borderWidth: 1,
                                    borderColor: "rgb(230, 230, 230)",
                                }, ...style
                            }}
                        />
                    </div>
                    <div className="ColumnDiv" style={{ marginLeft: (valueStr === EditorInfoType.companyLogo) === true ? 10 : 5 }}>
                        <input
                            type="file"
                            accept=".png,.jpg,.jpeg,.bitmap"
                            onChange={(e) => {
                                this.handleSelectedFile(e, value)
                            }}
                            style={{
                                opacity: 1,
                                fontSize: 12,
                                color: "rgb(255, 255, 255)",
                                border: "none",
                                marginLeft: 4,
                                marginTop: 10,
                            }}
                        />
                    </div>
                </div>}
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getThemeColorPicker() {
        return (
            <div
                className="ListPickerHolderStyle RowDiv HeaderRow"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                {this.getSectionHeader('select_theme_color', false, 12.5, 4)}
                <input
                    type="color"
                    className="ColorPicker"
                    style={{
                        marginRight: 5,
                        outline: "none",
                        appearance: "none",
                    }}
                    value={this.state.data ? this.state.data.themeColor : "#ffffff"}
                    onChange={this.themeColorChanged}
                />
            </div>
        );
    }

    getThemeFontStyle(ownerType) {
        var title = ownerType === OwnerType.contact ? 'contact' : 'text'
        return (
            <div className='ListPickerHolderStyle'>
                <div
                    className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {ownerType === OwnerType.contact ?
                        <div className='RowDiv'
                            style={{ marginRight: 4, width: 'auto' }} >
                            <input type="checkbox"
                                checked={this.getIsEnableContact(ownerType)}
                                onClick={() => this.updateGlobalIsEnableContactText(ownerType)}
                                style={{ margin: 0 }}
                            />
                        </div>
                        : undefined
                    }
                    <div className='RowDiv'>
                        {this.getSectionHeader(title, false, 12.5, 4)}
                    </div>
                    <div className='RowDiv'
                        style={{
                            flexDirection: 'row-reverse',
                            marginLeft: OwnerType.contact ? 4 : 0
                        }}>
                        <div className='HeaderButton'
                            style={{
                                marginLeft: 10,
                                fontWeight: 'bold',
                                backgroundColor: Utility.getGlobalFontStyle('weight', ownerType) === 'bold' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                                color: Utility.getGlobalFontStyle('weight', ownerType) === 'bold' ? 'white' : undefined,
                            }}
                            onClick={(event) => this.updateGlobalFontWeight('bold', ownerType)}>
                            B
                        </div>
                        <div className='HeaderButton'
                            style={{
                                marginLeft: 10,
                                fontStyle: 'italic',
                                backgroundColor: Utility.getGlobalFontStyle('style', ownerType) === 'italic' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                                color: Utility.getGlobalFontStyle('style', ownerType) === 'italic' ? 'white' : undefined,
                            }}
                            onClick={(event) => this.updateGlobalFontStyle('italic', ownerType)}>
                            I
                        </div>
                        <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateGlobalFontSize('down', ownerType)}>
                            A-
                        </div>
                        <div className='HeaderButton' onClick={(event) => this.updateGlobalFontSize('up', ownerType)}>
                            A+
                        </div>
                    </div>
                </div>
                {this.getTextColorPicker(ownerType)}
                {this.getFontFamilyListPicker(ownerType)}
            </div>
        );
    }

    getFontFamilyListPicker(ownerType) {
        return (
            <div className="RowDiv"
                style={{
                    marginTop: 5,
                }}>
                <div className="RowDiv">
                    <select
                        style={this.getPickerStyle()}
                        onChange={(event) => this.updateGlobalFontFamily(event, ownerType)}
                        className="TextField ListPicker">
                        {FONT_FAMILY.map((key) => {
                            return <>
                                <option
                                    style={{
                                        fontFamily: key.value
                                    }}
                                    value={key.value}
                                    selected={Utility.getGlobalFontStyle('family', ownerType) === key.value}>
                                    {key.label}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    getTextColorPicker(ownerType) {
        var title = ownerType === OwnerType.contact ? 'override_global_color' : 'override_theme_color'
        return (
            <div
                className="RowDiv HeaderRow"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    fontSize: 12,
                    padding: 0,
                }}>
                <div className='RowDiv'>
                    <input type="checkbox"
                        checked={this.getIsOverrideThemeColor(ownerType)}
                        onClick={() => this.updateGlobalFontIsOverrideTextColor(ownerType)}
                        style={{ marginRight: 4, marginLeft: 8 }} />
                    <div style={{ textAlign: 'left' }}>
                        {getLocalisedText(title)}
                    </div>
                </div>
                <input
                    type="color"
                    className="ColorPicker"
                    style={{
                        marginRight: 5,
                        outline: "none",
                        appearance: "none",
                    }}
                    disabled={!this.getIsOverrideThemeColor(ownerType)}
                    value={Utility.getGlobalFontStyle('color', ownerType) ? Utility.getGlobalFontStyle('color', ownerType) : 'black'}
                    onChange={(event) => this.textColorChanged(event, ownerType)}
                />
            </div>
        );
    }

    getTableStyle() {
        var title = 'table'
        return (
            <div className='ListPickerHolderStyle'>
                <div className='RowDiv HeaderRow'>
                    {this.getSectionHeader(title, false, 12.5, 4)}
                </div>
                <div
                    className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={this.getIsEnableTableStyle()}
                            onClick={() => this.updateGlobalTableIsEnable()}
                            style={{ margin: 0 }}
                        />
                    </div>
                    <div className='RowDiv'>
                        {this.getSectionHeader('font', false, 12.5, 4)}
                    </div>
                    <div className='RowDiv'
                        style={{
                            flexDirection: 'row-reverse',
                            marginLeft: 0
                        }}>
                        <div className='HeaderButton'
                            style={{
                                marginLeft: 10,
                                fontWeight: 'bold',
                                backgroundColor: Utility.getGlobalTableFontStyleWithProperty('weight') === 'bold' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                                color: Utility.getGlobalTableFontStyleWithProperty('weight') === 'bold' ? 'white' : undefined,
                            }}
                            onClick={(event) => this.updateGlobalTableFontStyle('weight', 'bold')}>
                            B
                        </div>
                        <div className='HeaderButton'
                            style={{
                                marginLeft: 10,
                                fontStyle: 'italic',
                                backgroundColor: Utility.getGlobalTableFontStyleWithProperty('style') === 'italic' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                                color: Utility.getGlobalTableFontStyleWithProperty('style') === 'italic' ? 'white' : undefined,
                            }}
                            onClick={(event) => this.updateGlobalTableFontStyle('style', 'italic')}>
                            I
                        </div>
                        <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateGlobalTableFontSize('down')}>
                            A-
                        </div>
                        <div className='HeaderButton' onClick={(event) => this.updateGlobalTableFontSize('up')}>
                            A+
                        </div>
                    </div>
                </div>
                {this.getTableDimension(TableDimensionType.ROW_HEIGHT)}
                {this.getTableDimension(TableDimensionType.PADDING_HORIZONTAL)}
                {this.getTableDimension(TableDimensionType.PADDING_VERTICAL)}
                {this.getTableColumnAlignment(EditorInfoType.tableColumnAlignment)}
                {this.getTableColumnAlignment(EditorInfoType.tableColumnVerticalAlignment)}
            </div>
        );
    }

    getTableDimension(tableDimensionType) {
        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={this.getTableDimensionIsSelected(tableDimensionType)}
                            onClick={() => this.updateGlobalTableDimensionByProperty(tableDimensionType)}
                            style={{ margin: 0 }}
                        />
                    </div>
                    <div className='RowDiv'
                        style={{ marginRight: 0, width: 'auto', whiteSpace: 'nowrap' }} >
                        {this.getSectionHeader(this.getTableDimensionTitle(tableDimensionType))}
                    </div>
                    <div className='RowReverseDiv'>
                        <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateGlobalTableDimensionSizeByProperty(tableDimensionType, 'down')}>
                            -
                        </div>
                        <div className='HeaderButton' onClick={(event) => this.updateGlobalTableDimensionSizeByProperty(tableDimensionType, 'up')}>
                            +
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getTableColumnAlignment(editorInfoType) {
        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 0, width: 'auto', whiteSpace: 'nowrap' }} >
                        {editorInfoType === EditorInfoType.tableColumnAlignment ? this.getSectionHeader('table_column_alignment') : this.getSectionHeader('table_column_vertical_alignment')}
                    </div>
                    <div className='RowReverseDiv'>
                        <div
                            className='HeaderButton'
                            style={{ marginLeft: 10 }}
                            onClick={(event) => {
                                this.props.onEditButtonCallbackClicked(editorInfoType)
                            }}>
                            <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getTableDimensionTitle(type) {
        var label = ''
        switch (type) {
            case TableDimensionType.ROW_HEIGHT:
                label = 'table_row_height'
                break;
            case TableDimensionType.PADDING_HORIZONTAL:
                label = 'padding_horizontal'
                break;
            case TableDimensionType.PADDING_VERTICAL:
                label = 'padding_vertical'
                break;
            default:
                break;
        }

        return getLocalisedText(label)
    }

    getTableDimensionIsSelectedFieldName(type) {
        var property = ''
        switch (type) {
            case TableDimensionType.ROW_HEIGHT:
                property = 'isFixRowHeightEnable'
                break;
            case TableDimensionType.PADDING_HORIZONTAL:
                property = 'isPaddingHorizontalEnable'
                break;
            case TableDimensionType.PADDING_VERTICAL:
                property = 'isPaddingVerticalEnable'
                break;
            default:
                break;
        }
        return property
    }

    getTableDimensionFieldName(type) {
        var property = ''
        switch (type) {
            case TableDimensionType.ROW_HEIGHT:
                property = 'rowHeight'
                break;
            case TableDimensionType.PADDING_HORIZONTAL:
                property = 'paddingHorizontal'
                break;
            case TableDimensionType.PADDING_VERTICAL:
                property = 'paddingVertical'
                break;
            default:
                break;
        }
        return property
    }

    getTableDimensionIsSelected(type) {
        var property = this.getTableDimensionIsSelectedFieldName(type)
        if (property !== '') {
            return this.getTableIsSelectedByProperty(property)
        }
        return false
    }


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    updateGlobalFontFamily(event, ownerType) {
        var family = event.target.value
        Utility.updateGlobalFontFamily(family, ownerType)
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalFontSize(action, ownerType) {
        Utility.updateGlobalFontSize(action, ownerType)
        this.props.onDataChanged(this.state.data);
    }

    updateAddressCheckFontSize(action, ownerType) {
        Utility.updateCheckAddressFontSize(action, ownerType)
        this.props.onDataChanged(this.state.data);
    }

    updateCheckFontStyle(action, ownerType, field) {
        Utility.updateCheckFontSize(action, ownerType, field);
        this.props.onDataChanged(this.state.data);
    }

    updateCheckStyle(action, field, isReset) {
        Utility.updateCheckSize(action, field, isReset);
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalFontStyle(style, ownerType) {
        Utility.updateGlobalFontStyle(style, ownerType)
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalFontWeight(weight, ownerType) {
        Utility.updateGlobalFontWeight(weight, ownerType)
        this.props.onDataChanged(this.state.data);
    }

    getIsOverrideThemeColor(ownerType) {
        if (Utility.getGlobalFontStyle('isOverrideThemeColor', ownerType) !== undefined && Utility.getGlobalFontStyle('isOverrideThemeColor', ownerType) !== null) {
            return Utility.getGlobalFontStyle('isOverrideThemeColor', ownerType)
        }
        return false
    }

    getIsEnableContact(ownerType) {
        if (Utility.getGlobalFontStyle('isEnable', ownerType) !== undefined && Utility.getGlobalFontStyle('isEnable', ownerType) !== null) {
            return Utility.getGlobalFontStyle('isEnable', ownerType)
        }
        return false
    }

    updateGlobalFontIsOverrideTextColor(ownerType) {
        Utility.updateGlobalFontIsOverrideTextColor(ownerType)
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalIsEnableContactText(ownerType) {
        Utility.updateGlobalIsEnableContactText(ownerType)
        this.props.onDataChanged(this.state.data);
    }

    textColorChanged = (event, ownerType) => {
        Utility.updateGlobalFontTextColor(event.target.value, ownerType)
        this.props.onDataChanged(this.state.data);
    };

    updateImageSize(action, type) {
        Utility.updateImageSize(action, type)
        this.props.onDataChanged(this.state.data);
    }

    getIsEnableTableStyle() {
        if (Utility.getGlobalTableFontStyleWithProperty('isEnable') !== undefined && Utility.getGlobalTableFontStyleWithProperty('isEnable') !== null) {
            return Utility.getGlobalTableFontStyleWithProperty('isEnable')
        }
        return false
    }

    getTableIsSelectedByProperty(property) {
        if (Utility.getGlobalTableFontStyleWithProperty(property) !== undefined && Utility.getGlobalTableFontStyleWithProperty(property) !== null) {
            return Utility.getGlobalTableFontStyleWithProperty(property)
        }
        return false
    }

    updateGlobalTableIsEnable() {
        Utility.updateGlobalTableIsEnable()
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalTableFontStyle(property, value) {
        Utility.updateGlobalTableProperty(property, value)
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalTableFontSize(action) {
        Utility.updateGlobalTableFontSize(action)
        this.props.onDataChanged(this.state.data);
    }

    updateGlobalTableDimensionByProperty(tableDimensionType) {
        var property = this.getTableDimensionIsSelectedFieldName(tableDimensionType)
        if (property !== '') {
            Utility.updateGlobalTableDimensionByProperty(property)
            this.props.onDataChanged(this.state.data)
        }
    }

    updateGlobalTableDimensionSizeByProperty(tableDimensionType, action) {
        var property = this.getTableDimensionFieldName(tableDimensionType)
        var isEnable = this.getTableDimensionIsSelected(tableDimensionType)
        if (property !== '' && isEnable) {
            if (tableDimensionType === TableDimensionType.ROW_HEIGHT) {
                Utility.updateGlobalTableRowHeightSize(action)
            }
            else {

                Utility.updateGlobalTablePadding(property, action)
            }
            this.props.onDataChanged(this.state.data)
        }
    }
    //////////////////////////////////////////////////
    ///////////////// COLUMN SECTION /////////////////
    //////////////////////////////////////////////////

    getColumnSection(columnsData) {
        return (
            <div
                style={{
                    marginTop: 35,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                }}
            >
                <div className="RowDiv" style={{ justifyContent: 'space-between' }}>
                    {this.getSectionHeader('line_items', false, 13)}

                    {!getIsCheque(this.state.data.type) && ComponentManager.getButton("edit_table", COLOR_BLUE, 'white', () => { this.props.onEditTableTapped() })}
                </div>
                <div
                    className="ColumnDiv"
                    style={{
                        width: "100%",
                        marginTop: 8,
                        padding: 15,
                        backgroundColor: "rgb(240, 240, 240)",
                        borderRadius: 6,
                        boxSizing: 'border-box'
                    }}
                >
                    {this.renderLineItemRow()}
                    {this.getColumnButton()}
                </div>
            </div>
        );
    }

    renderLineItemRow() {
        if (this.isAccountModule()) {
            return <>
                <AccountColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsMakePayment(this.state.data.type) || getIsReceivePayment(this.state.data.type)) {
            return <>
                <PaymentColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsFulfillment(this.state.data.type)) {
            return <>
                <FulfillmentColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsGoodsReceipt(this.state.data.type)) {
            return <>
                <GoodsReceiptColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsJournalEntry(this.state.data.type)) {
            return <>
                <JournalEntryColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsPickList(this.state.data.type)) {
            return <>
                <PickingColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsPackList(this.state.data.type)) {
            return <>
                <PackingColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsShipList(this.state.data.type)) {
            return <>
                <ShippingColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else if (getIsCheque(this.state.data.type)) {
            return <>
                <ChequeColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
        else {
            return <>
                <ProductColumnEditor
                    data={this.state.data}
                    columnData={this.state.data.lineItems}
                    dataChanged={(data) => this.dataChanged(data)}
                />
            </>
        }
    }

    ////////////////////
    ////////////////////

    getColumnButton() {
        return (
            <div
                style={{
                    borderRadius: 6,
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "rgb(200, 200, 200)",
                    width: "90%",
                    backgroundColor: "white",
                    cursor: "pointer",
                    padding: 8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 15,
                }}
                onClick={() => this.getNewColumn()}
            >
                <text style={{ fontSize: 13, textAlign: "center", color: "black" }}>
                    {"+ " + getLocalisedText("add_new_item")}
                </text>
            </div>
        );
    }

    getNewColumn() {
        var data = this.state.data;
        var index = 0
        if (data.lineItems !== undefined && data.lineItems !== null) {
            index = data.lineItems.length + 1
        }
        data["lineItems"] = [...data["lineItems"], this.getNewColumnData(data.type, index)];
        this.dataChanged(data);
    }

    getNewColumnId() {
        let id = 0;
        let columns = this.state.data.lineItems;
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].id > id) {
                id = columns[i].id;
            }
        }
        return id + 1;
    }

    getNewColumnData(type, index) {
        return DocumentManager.getLineItemData(type, index, this.state.data.dateFormat);
    }

    deleteColumn(index) {
        var data = this.state.data;
        data["lineItems"].splice(index, 1);
        this.dataChanged(data);
    }

    getDocumentDetails() {
        return (
            <div className='ListPickerHolderStyle RowDiv HeaderRow'
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                {this.getSectionHeader('document_details')}
                <div onClick={() => {
                    this.props.onDocumentDetailsClick()
                }}
                    className="HeaderButton"
                    style={{ marginRight: 5 }}>
                    <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
                </div>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    getDateFormatListPicker() {
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('date_format')}
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(e) => this.dateFormatChanged(e)}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                        disabled={TemplateSettingsManager.getDateFormatIsSystemDefault()}
                    >
                        <option
                            value='dd/mm/yyyy'
                            selected={this.state.data.dateFormat === DateFormat.DD_MM_YYYY_SLASH}>
                            DD/MM/YYYY
                        </option>
                        <option
                            value="mm/dd/yyyy"
                            selected={this.state.data.dateFormat === DateFormat.MM_DD_YYYY_SLASH}>
                            MM/DD/YYYY
                        </option>
                        <option
                            value='dd-mm-yyyy'
                            selected={this.state.data.dateFormat === DateFormat.DD_MM_YYYY_DASH}>
                            DD-MM-YYYY
                        </option>
                        <option
                            value="mm-dd-yyyy"
                            selected={this.state.data.dateFormat === DateFormat.MM_DD_YYYY_DASH}>
                            MM-DD-YYYY
                        </option>
                        <option
                            value="yyyy-mm-dd"
                            selected={this.state.data.dateFormat === DateFormat.YYYY_MM_DD_DASH}>
                            YYYY-MM-DD
                        </option>
                        <option
                            value="ddmmyyyy"
                            selected={this.state.data.dateFormat === DateFormat.DDMMYYYY}>
                            DDMMYYYY
                        </option>
                        <option
                            value="mmddyyyy"
                            selected={this.state.data.dateFormat === DateFormat.MMDDYYYY}>
                            MMDDYYYY
                        </option>
                        <option
                            value="ddmmmmyyyy"
                            selected={this.state.data.dateFormat === DateFormat.DDMMMMYYYY}>
                            DD MMMM YYYY
                        </option>
                        <option
                            value="ddmmmyyyy"
                            selected={this.state.data.dateFormat === DateFormat.DDMMMYYYY}>
                            DD MMM YYYY
                        </option>
                        <option
                            value="mmmddyyyy"
                            selected={this.state.data.dateFormat === DateFormat.MMMDDYYYY}>
                            MMM DD YYYY
                        </option>
                        <option
                            value="d mmm yyyy"
                            selected={this.state.data.dateFormat === DateFormat.D_MMM_YYYY_SPACE}>
                            D MMM YYYY
                        </option>
                    </select>
                    <div style={{
                        position: 'relative',
                        alignSelf: 'center',
                        paddingTop: 32,
                    }}>
                        {this.getListPickerArrow()}
                    </div>
                </div>
                <div className='RowDiv'>
                    <input type="checkbox"
                        checked={TemplateSettingsManager.getDateFormatIsSystemDefault()}
                        onClick={() => this.dateFormatIsSystemDefaultChanged()}
                        style={{ marginRight: 4, marginLeft: 8 }} />
                    <div style={{
                        textAlign: 'left',
                        paddingTop: 6,
                        paddingBottom: 6,
                        fontSize: 12,
                        color: "black",
                        fontWeight: "600"
                    }}>
                        {getLocalisedText('system_default')}
                    </div>
                </div>
            </div>

        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getDateButton(dateString) {
        return (
            <div className="ListPickerHolderStyle">
                <div className='ColumnDiv' style={{ width: '100%' }}>
                    <div className="RowDiv HeaderRow"
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                        {this.getSectionHeader(this.getDateTitleLabel(DateType.START))}
                        {this.getHideShowButton(EditorInfoType.startDate)}
                    </div>
                    {this.getShowHideValueFor(EditorInfoType.startDate) && <div className="RowDiv TextField" style={{
                        fontSize: 13, flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row", color: "black",
                        cursor: "pointer",
                    }} onClick={() => this.showCalender(DateType.START)}>
                        <text>
                            {dateString}
                        </text>
                    </div>}
                </div>
                {this.state.showDateCalendar && <div className='ColumnDiv' style={{ width: '100%', alignItems: 'center', paddingBottom: '30px' }}>
                    <div style={{ height: 10 }} />
                    <DKCalendar
                        onSelectDate={(date) => {
                            this.setSelectedDate(DateType.START, date)
                        }}
                        selectedDate={this.getSelectedDate(DateType.START)}
                        isDateRange={false}
                        onSelectDateRange={(startDate, endDate) => {

                        }}
                        selectedStartDate={undefined}
                        selectedEndDate={undefined}
                    />
                </div>}

            </div>
        );
    }


    getDueDateButton(dateString) {
        return (
            <div className="ListPickerHolderStyle">
                <div className='ColumnDiv' style={{ width: '100%' }}>
                    <div className="RowDiv HeaderRow"
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                        {this.getSectionHeader(this.getDateTitleLabel(DateType.END))}
                        {this.getHideShowButton(EditorInfoType.dueDate)}
                    </div>
                    {this.getShowHideValueFor(EditorInfoType.dueDate) &&
                        <div
                            className="RowDiv TextField"
                            style={{
                                cursor: "pointer",
                                flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row",
                            }}
                            onClick={() => this.showCalender(DateType.END)}>
                            <text style={{}}>
                                {dateString}
                            </text>
                        </div>}
                </div>

                {this.state.showDueDateCalendar && <div className='ColumnDiv' style={{ width: '100%', backgroundColor: 'transparent', alignItems: 'center', paddingBottom: '30px' }}>
                    <div style={{ height: 10 }} />
                    <DKCalendar
                        onSelectDate={(date) => {
                            this.setSelectedDate(DateType.END, date)
                        }}
                        selectedDate={this.getSelectedDate(DateType.END)}
                        isDateRange={false}
                        onSelectDateRange={(startDate, endDate) => {

                        }}
                        selectedStartDate={undefined}
                        selectedEndDate={undefined}
                    />
                </div>}
            </div>
        );
    }

    getAdditionalDateButton(dateString) {
        return (
            <div className="ListPickerHolderStyle">
                <div className='ColumnDiv' style={{ width: '100%' }}>
                    <div className="RowDiv HeaderRow"
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                        {this.getSectionHeader(this.getDateTitleLabel(DateType.ADDITIONAL))}
                        {this.getHideShowButton(EditorInfoType.additionalDate)}
                    </div>
                    {this.getShowHideValueFor(EditorInfoType.additionalDate) &&
                        <div
                            className="RowDiv TextField"
                            style={{
                                cursor: "pointer",
                                flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row",
                            }}
                            onClick={() => this.showCalender(DateType.ADDITIONAL)}>
                            <text style={{}}>
                                {dateString}
                            </text>
                        </div>}
                </div>
            </div>
        );
    }

    getDateTitleLabel(dateType) {
        if (dateType === DateType.START) {
            return ComponentManager.getStartDateLabel(this.state.data.type)
        }
        else if (dateType === DateType.END) {
            return ComponentManager.getEndDateLabel(this.state.data.type)
        }
        else if (dateType === DateType.ADDITIONAL) {
            return ComponentManager.getAdditionalDateLabel(this.state.data.type)
        }
    }

    showCalender(dateType) {
        if (this.state.isFromERP) {
            return
        }
        if (dateType === DateType.START) {
            let { showDateCalendar } = this.state
            this.setState({ showDateCalendar: !showDateCalendar, showDueDateCalendar: false })
        } else if (dateType === DateType.END) {
            let { showDueDateCalendar } = this.state
            this.setState({ showDueDateCalendar: !showDueDateCalendar, showDateCalendar: false })
        }
    }

    getSelectedDate(dateType) {
        if (dateType === DateType.START) {
            return getDateFrom(this.state.data.date, this.state.data.dateFormat)
        } else if (dateType === DateType.END) {
            return getDateFrom(this.state.data.dueDate, this.state.data.dateFormat)
        }
    }

    setSelectedDate(dateType, date) {
        const data = this.state.data;
        if (dateType === DateType.START) {
            data["date"] = getDateString(date, this.state.data.dateFormat)
            this.setState({ showDateCalendar: false })
        } else if (dateType === DateType.END) {
            data["dueDate"] = getDateString(date, this.state.data.dateFormat)
            this.setState({ showDueDateCalendar: false })
        }
        this.dataChanged(data)
    }


    getPaperFormatListPicker() {
        var paperFormat = ['A4', 'Letter', 'Legal', 'A3', 'A5']
        if (getIsQuotation(this.state.data.type) ||
            getIsSalesOrder(this.state.data.type) ||
            getIsInvoice(this.state.data.type) ||
            getIsRequestForQuotation(this.state.data.type) ||
            getIsPurchaseInwardQuotation(this.state.data.type) ||
            getIsBill(this.state.data.type) ||
            getIsOrder(this.state.data.type)) {
            paperFormat = ['A4', 'Letter', 'Legal', 'A3', 'A5', '3.5', '4.6']
        } else if (getIsPayrollCheck(this.state.data.type)) {
            paperFormat = ['A4']
        }

        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('paper_format')}
                </div>
                <div className="RowDiv">
                    <select
                        style={this.getPickerStyle()}
                        onChange={(event) => this.paperFormatChanged(event)}
                        className="TextField ListPicker">
                        {paperFormat.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={this.getPaperFormat() === key.toLowerCase()}>
                                    {key}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    getPaperFormat() {
        if (TemplateSettingsManager.getPageFormat() === undefined ||
            TemplateSettingsManager.getPageFormat() === null) {
            var localValue = getPaperFormat()
            if (localValue !== null) {
                // localStorage.removeItem('paper_format')
                TemplateSettingsManager.updatePageFormat(localValue)
                return localValue.toLowerCase()
            }
            TemplateSettingsManager.updatePageFormat('a4')
            return 'a4'
        }

        if (TemplateSettingsManager.getPageFormat().toLowerCase() === 'four_six') {
            return '4.6'
        }
        else if (TemplateSettingsManager.getPageFormat().toLowerCase() === 'three_five') {
            return '3.5'
        }
        return TemplateSettingsManager.getPageFormat().toLowerCase()
    }

    paperFormatChanged(event) {
        let oldPaperSize = TemplateSettingsManager.getPageFormat()
        var newFormat = event.target.value
        //'letter' | 'legal' | 'tabloid' | 'ledger' | 'a0' | 'a1' | 'a2' | 'a3' | 'a4' | 'a5' | 'a6';
        // setPaperFormat(newFormat.toLocaleLowerCase())
        var data = this.state.data
        if (newFormat === '3.5' || newFormat === '4.6') {
            newFormat = newFormat === '3.5' ? 'three_five' : 'four_six'
            TemplateSettingsManager.updateTemplateID(1)
            data.templateId = 1
        }
        TemplateSettingsManager.updatePageFormat(newFormat.toLowerCase())

        if (getIsQuotation(data.type) ||
            getIsSalesOrder(data.type) ||
            getIsInvoice(data.type) ||
            getIsRequestForQuotation(this.state.data.type) ||
            getIsPurchaseInwardQuotation(this.state.data.type) ||
            getIsBill(data.type) ||
            getIsOrder(data.type)) {
            if (Utility.getIsTemplateChangeRequired(oldPaperSize, newFormat)) {
                data.themeColor = DocumentManager.getThemeColor(data.type, data.templateId)
                data = TemplateSettingsManager.resetDefaultDataForReceipt(data, undefined)
                data.event = 'onPaperSizeChanged'
            }
        }
        this.dataChanged(data)
    }

    getDecimalScaleListPicker() {
        var decimalScales = []
        for (let index = 0; index < 6; index++) {
            decimalScales.push(index)
        }
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('decimal_point')}
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.decimalScaleChanged(event)}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                        disabled={TemplateSettingsManager.getDecimalScaleIsSystemDefault()}
                    >
                        {decimalScales.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={Number(this.getDecimalScale()) === Number(key)}>
                                    {key}
                                </option>
                            </>
                        })}
                    </select>
                    <div style={{
                        position: 'relative',
                        alignSelf: 'center',
                        paddingTop: 32,
                    }}>
                        {this.getListPickerArrow()}
                    </div>
                </div>
                <div className='RowDiv'>
                    <input type="checkbox"
                        checked={TemplateSettingsManager.getDecimalScaleIsSystemDefault()}
                        onClick={() => this.decimalScaleIsSystemDefaultChanged()}
                        style={{ marginRight: 4, marginLeft: 8 }} />
                    <div style={{
                        textAlign: 'left',
                        paddingTop: 6,
                        paddingBottom: 6,
                        fontSize: 12,
                        color: "black",
                        fontWeight: "600"
                    }}>
                        {getLocalisedText('system_default')}
                    </div>
                </div>
            </div>
        );
    }

    getDecimalScale() {
        if (TemplateSettingsManager.getDecimalScaleIsSystemDefault()) {
            return AppManager.getDecimalScale()
        }
        else if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale === undefined) {
            return AppManager.getDecimalScale()
        }
        else {
            return TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.value
        }
    }

    decimalScaleChanged(event) {
        var newValue = event.target.value
        TemplateSettingsManager.updateDecimalScale(newValue)
        AppManager.setDecimalScale(newValue)
        this.dataChanged(this.state.data)
    }

    decimalScaleIsSystemDefaultChanged() {
        var isCheck = TemplateSettingsManager.getDecimalScaleIsSystemDefault()
        TemplateSettingsManager.updateDecimalScaleIsSystemDefault(!isCheck)
        this.dataChanged(this.state.data)
    }

    getQuantityLabel() {
        var name = undefined
        var tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo
        if (tableInfo !== undefined && tableInfo !== null) {
            if (tableInfo.length > 0) {
                var quantityItem = tableInfo.filter(x => x.type === TableColumnPopupType.quantity)
                if (quantityItem !== undefined && quantityItem !== null) {
                    if (quantityItem.length > 0) {
                        name = quantityItem[0].name
                    }
                }

                if(name === undefined) {
                    var qtyItem = tableInfo.find(x => x.type === TableColumnPopupType.returnedQty)
                    if (qtyItem) {
                        name = getLocalisedText('qty') 
                    }
                }
                
            }
        }
        return name
    }

    getQuantityDecimalScaleListPicker() {
        var decimalScales = []
        for (let index = 0; index < 5; index++) {
            decimalScales.push(index)
        }
        var title = getLocalisedText(this.getQuantityLabel()) + ' - ' + getLocalisedText('decimal_point')
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader(title)}
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.quantityDecimalScaleChanged(event)}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                    >
                        {decimalScales.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={Number(TemplateSettingsManager.getQuantityDecimalScale()) === Number(key)}>
                                    {key}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    quantityDecimalScaleChanged(event) {
        var newValue = event.target.value
        TemplateSettingsManager.updateQuantityDecimalScale(newValue)
        this.dataChanged(this.state.data)
    }

    getProductGroupingPicker(type) {
        var productGroupingType = ['none', 'custom_field'];

        if (getIsQuotation(type)) {
            productGroupingType = ['none', 'custom_field', CONSTANTS.PRODUCT_GROUP];
        }

        if (getIsWorkOrder(type)) {
            productGroupingType = ['none', 'custom_field', CONSTANTS.PRODUCT_GROUP];
        }

        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('product_grouping')}
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.productGroupingTypeChanged(event)}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                    >
                        {productGroupingType.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={this.getProductGroupingType(key)}>
                                    {getLocalisedText(key)}
                                </option>
                            </>
                        })}
                    </select>
                    <div style={{
                        position: 'relative',
                        alignSelf: 'center',
                        paddingTop: 32,
                    }}>
                        {this.getListPickerArrow()}
                    </div>
                </div>
                {this.getIsProductGroup() &&
                    !getIsWorkOrder(type) &&
                    <div className='RowDiv'>
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getIsMultiProductTable()}
                            onClick={() => this.multiProductTableChanged()}
                            style={{ marginRight: 4, marginLeft: 8 }} />
                        <div style={{
                            textAlign: 'left',
                            paddingTop: 6,
                            paddingBottom: 6,
                            fontSize: 12,
                            color: "black",
                            fontWeight: "600"
                        }}>
                            {getLocalisedText('is_multi_product_table')}
                        </div>
                    </div>
                }
                {this.getIsProductGroup() &&
                    !getIsWorkOrder(type) &&
                    <div className='RowDiv'>
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getIsShowOptionalProductGroupTable()}
                            onClick={() => this.showOptionalProductGroupTableChanged()}
                            style={{ marginRight: 4, marginLeft: 8 }} />
                        <div style={{
                            textAlign: 'left',
                            paddingTop: 6,
                            paddingBottom: 6,
                            fontSize: 12,
                            color: "black",
                            fontWeight: "600"
                        }}>
                            {getLocalisedText('is_show_optional_group')}
                        </div>
                    </div>
                }
                {TemplateSettingsManager.getIsMultiProductTable() &&
                    TemplateSettingsManager.getIsShowOptionalProductGroupTable()
                    && this.getCRMTableSettings()}
            </div>
        );
    }

    getProductGroupingType(key) {
        var grouping = 'none'
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }
        return key.toLowerCase() === grouping.toLowerCase()
    }

    getIsProductGroup() {
        let isProductGroup = true
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                isProductGroup = newProductGrouping.selection === CONSTANTS.PRODUCT_GROUP
            }
        }

        return isProductGroup
    }

    productGroupingTypeChanged(event) {
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var newValue = event.target.value
        newProductGrouping.selection = newValue.toLowerCase()
        TemplateSettingsManager.updateProductGrouping(newProductGrouping)
        this.dataChanged(this.state.data)
    }

    getWorkOrderProductPicker() {
        var productOptions = [
            { label: 'show_product', value: 'SHOW_PRODUCT' },
            { label: 'hide_product_with_substitute', value: 'HIDE_PRODUCT_WITH_SUBSTITUTE' },
        ]
        return this.getWorkOrderPicker('product', 'product', productOptions)
    }

    getWorkOrderSubstitutePicker() {
        var substituteOptions = [
            { label: 'show_all_substitute', value: 'SHOW_ALL_SUBSTITUTE' },
            { label: 'show_non_zero_substitute', value: 'SHOW_NON_ZERO_SUBSTITUTE' },
            { label: 'hide_substitute', value: 'HIDE_SUBSTITUTE' },
        ]

        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings['product'] !== undefined && workOrderSettings['product'] !== null) {
                if (workOrderSettings['product'] === 'HIDE_PRODUCT_WITH_SUBSTITUTE') {
                    substituteOptions = substituteOptions.filter(x => x.value !== 'HIDE_SUBSTITUTE')
                }
            }
        }
        return this.getWorkOrderPicker('substitute', 'substitute', substituteOptions)
    }

    getWorkOrderSettingsIsSelected(type, value) {
        var isSelected = false
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                isSelected = workOrderSettings[type] === value
            }
            else {
                workOrderSettings = TemplateSettingsManager.getDefaultWorkOrderSettings()
                if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                    isSelected = workOrderSettings[type] === value
                }
            }
        }
        return isSelected
    }

    workOrderSettingsChanged(event, type) {
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            workOrderSettings[type] = event.target.value

            if (type === 'product' && event.target.value === 'HIDE_PRODUCT_WITH_SUBSTITUTE') {
                if (workOrderSettings['substitute'] !== undefined && workOrderSettings['substitute'] !== null) {
                    if (workOrderSettings['substitute'] === 'HIDE_SUBSTITUTE') {
                        workOrderSettings['substitute'] = 'SHOW_ALL_SUBSTITUTE'
                    }
                }
            }

            if (type === 'taggedBinWarehouseTitle') {
                workOrderSettings['taggedBinWarehouseTitle'] = event.target.value
            }
            TemplateSettingsManager.updateWorkOrderSettings(workOrderSettings)
            this.dataChanged(this.state.data)
        }
    }


    workOrderSettingsShowLabelChanged(type, defaultValue) {
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                workOrderSettings[type] = !workOrderSettings[type]
            }
            else {
                workOrderSettings[type] = !defaultValue
            }
            TemplateSettingsManager.updateWorkOrderSettings(workOrderSettings)
            this.dataChanged(this.state.data)
        }
    }

    getWorkOrderCheckboxValue(type) {
        var isChecked = false
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                isChecked = workOrderSettings[type]
            }
            else {
                workOrderSettings = TemplateSettingsManager.getDefaultWorkOrderSettings()
                if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                    isChecked = workOrderSettings[type]
                }
            }
        }

        return isChecked
    }

    getWorkOrderInputValue(type) {
        var value = ''
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                value = workOrderSettings[type]
            }
            else {
                workOrderSettings = TemplateSettingsManager.getDefaultWorkOrderSettings()
                if (workOrderSettings[type] !== undefined && workOrderSettings[type] !== null) {
                    value = workOrderSettings[type]
                }
            }
        }

        return value
    }

    getWorkOrderPicker(type, title, list) {
        var checkbox = type === 'product' ? 'showProductLabel' : 'showSubstituteLabel'
        const textInput = type === 'product' ? 'productLabel' : 'substituteLabel'
        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader(title)}
                </div>
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <input type="checkbox"
                        checked={this.getWorkOrderCheckboxValue(checkbox)}
                        onClick={() => this.workOrderSettingsShowLabelChanged(checkbox, this.getWorkOrderCheckboxValue(checkbox) )}
                        style={{ marginRight: 5 }}
                    />
                    
                    <input
                        id={textInput}
                        className="TextField"
                        placeholder={getLocalisedText(title)}
                        type="text"
                        dir={Utility.getInputDirection()}
                        value={this.getWorkOrderInputValue(textInput)}
                        style={{ marginRight: 5 }}
                        disabled={!this.getWorkOrderCheckboxValue(checkbox)}
                        onChange={(event) => this.workOrderSettingsChanged(event, textInput)}
                    />
                </div>
                <div className='RowDiv'
                    style={{ marginRight: 4, width: 'auto' }} >
                    
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.workOrderSettingsChanged(event, type)}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                    >
                        {list.map((key) => {
                            return <>
                                <option
                                    value={key.value}
                                    selected={this.getWorkOrderSettingsIsSelected(type, key.value)}>
                                    {getLocalisedText(key.label)}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    getProductListingListPicker() {
        var productListingType = ['product_name', 'product_description', 'both']

        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('product_listing')}
                </div>
                <div className="RowDiv">
                    <select
                        style={this.getPickerStyle()}
                        onChange={(event) => this.productListingChanged(event)}
                        className="TextField ListPicker">
                        {productListingType.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={TemplateSettingsManager.getProductListing() === key.toLowerCase()}>
                                    {getLocalisedText(key)}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    productListingChanged(event) {
        var newProductListing = event.target.value
        TemplateSettingsManager.updateProductListing(newProductListing)
        this.dataChanged(this.state.data)
    }


    getGlobalDiscountTypePicker() {
        var globalDiscountList = [
            { label: 'show_all', value: 'SHOW_ALL' },
            { label: 'percentage', value: 'PERCENTAGE' },
            { label: 'amount', value: 'AMOUNT' }
        ]

        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('global_discount')}
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.globalDiscountTypeChanged(event, 'globalDiscountType')}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                    >
                        {globalDiscountList.map((element) => {
                            return <>
                                <option
                                    value={element.value}
                                    selected={this.getGlobalDiscountType('globalDiscountType', element.value)}>
                                    {getLocalisedText(element.label)}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    globalDiscountTypeChanged(event, key) {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration === undefined || productTableConfiguration === null) {
            productTableConfiguration = TemplateSettingsManager.getDefaultProductTableConfiguration()
        }

        productTableConfiguration[key] = event.target.value
        TemplateSettingsManager.updateProductTableConfiguration(productTableConfiguration)
        this.dataChanged(this.state.data)
    }

    getGlobalDiscountType(key, value) {
        let globalDiscountType = Utility.getProductTableConfiguration(key)
        if(value) {
            return value === globalDiscountType
        }

        return false
    }


    renderComponentSection() {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: 'row',
                    position: "relative",
                    paddingTop: 5,
                    paddingBottom: 15,
                    width: '100%',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        color: "black",
                        fontSize: 16,
                        fontWeight: "bold",
                        paddingLeft: 5,
                        paddingTop: 5,
                    }}
                >
                    <text>{getLocalisedText('component')}</text>
                </div>
                <div>
                    {this.props.isAddComponentPopupRequired &&
                        <AddComponentPopup
                            id={this.props.id}
                            componentList={this.props.componentList}
                            onAddComponent={(selected, componentList, id, action) => {
                                this.props.onAddComponentTapped(selected, componentList, id, action)
                            }}
                            onCancelPressed={() => {
                                this.props.onCancelPressed()
                            }}
                            onSelectSystemField={() => {
                                this.props.onSelectCustomField()
                            }}
                        />
                    }
                    {this.props.isViewComponentPopupRequired &&
                        <ViewComponentListPopup
                            componentList={this.props.componentList}
                            onSelectComponent={this.props.onSelectComponent}
                            onCancelPressed={() => {
                                this.props.onCancelPressed()
                            }}
                            onRemoveComponent={this.props.onRemoveComponent}
                        />
                    }
                    {this.props.screenState !== ScreenState.VIEW &&
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                whiteSpace: 'nowrap',
                                // marginRight: 7,
                            }}
                        >
                            <button
                                className='popupActionButton'
                                onClick={() => this.props.onAddPopupTapped()}
                            >
                                {'+ ' + getLocalisedText('add')}
                            </button>
                            {this.props.componentList.length > 0 &&
                                <button
                                    className='popupActionButton'
                                    onClick={() => this.props.onViewPopupTapped()}
                                >
                                    {getLocalisedText('view')}
                                </button>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }

    getEInvoiceConfiguration() {
        return <>
            <div className="ListPickerHolderStyle">
                <div className='RowDiv HeaderRow'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader(getLocalisedText('e_invoice_section'))}
                    {this.getHideShowButton(EditorInfoType.eInvoiceSection)}
                </div>
                {this.state.data.showEInvoiceSection && this.getEInvoiceQRResize()}
                {this.state.data.showEInvoiceSection && this.getEInvoicePostion()}
            </div>
        </>
    }

    getEInvoiceQRResize() {
        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getEInvoiceSectionIsQRResize()}
                            onClick={() => this.onUpdateEInvoiceIsQRResize()}
                            style={{ margin: 0 }}
                        />
                    </div>
                    <div className='RowDiv'
                        style={{ marginRight: 0, width: 'auto', whiteSpace: 'nowrap' }} >
                        {this.getSectionHeader('qr_resize')}
                    </div>
                    <div className='RowReverseDiv'>
                        <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.onUpdateEInvoiceQRResize('down')}>
                            -
                        </div>
                        <div className='HeaderButton' onClick={(event) => this.onUpdateEInvoiceQRResize('up')}>
                            +
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getEInvoicePostion() {
        let position = TemplateSettingsManager.getEInvoiceSectionPosition()
        let isTopPosition = (position === eInvoiceSectionPosition.top ? true : false)
        return <div className="RowDiv">
            <div className="RowDiv HeaderRow"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                <div className='RowDiv'
                    style={{ marginRight: 0, whiteSpace: 'nowrap' }} >
                    {this.getSectionHeader('position')}
                </div>
                {this.getTextWithCheckmark(isTopPosition, eInvoiceSectionPosition.top, getLocalisedText('top'), EditorInfoType.eInvoiceSection)}
                {ComponentManager.addHorizontalSpace(4)}
                {this.getTextWithCheckmark(!isTopPosition, eInvoiceSectionPosition.bottom, getLocalisedText('bottom'), EditorInfoType.eInvoiceSection)}
            </div>
        </div>
    }

    onUpdateEInvoicePosition(value) {
        TemplateSettingsManager.updateEInvoiceSectionPosition(value)
        this.dataChanged(this.state.data)
    }

    onUpdateEInvoiceIsQRResize() {
        var isQRResize = TemplateSettingsManager.getEInvoiceSectionIsQRResize()
        TemplateSettingsManager.updateEInvoiceSectionIsQRResize(!isQRResize)
        this.dataChanged(this.state.data)
    }

    onUpdateEInvoiceQRResize(action) {
        var isQRResize = TemplateSettingsManager.getEInvoiceSectionIsQRResize()
        if (!isQRResize) {
            return
        }
        var upperLimit = 100
        var lowerLimit = -100
        var stepValue = 10
        var size = TemplateSettingsManager.getEInvoiceSectionQRResizeValue()
        if (action === 'up') {
            size = size + stepValue
            if (size > upperLimit) {
                size = upperLimit
            }
        }
        else if (action === 'down') {
            size = size - stepValue
            if (size < lowerLimit) {
                size = lowerLimit
            }
        }

        TemplateSettingsManager.updateEInvoiceSectionQRResizeValue(size)
        this.dataChanged(this.state.data)
    }

    getBarcodeConfiguration() {
        return <>
            <div className="ListPickerHolderStyle">
                <div className='RowDiv HeaderRow'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader(getLocalisedText('barcode_settings'))}
                </div>
                {this.getBarcodeCheckbox()}
                {this.getBarcodeResize()}
                {this.getJobCardRunningNumber()}
                {this.getJobCardCheckbox()}
                {this.getInstructionCheckbox()}
                {this.getTaggedBinWarehouseCheckbox('top')}
                {this.getTaggedBinWarehouseCheckbox('bottom')}
                {this.getTaggedBinWarehouseTitle()}
            </div>
        </>
    }

    getBarcodeCheckbox() {
        if (!getIsWorkOrder(this.state.data.type)) {
            return <></>
        }

        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getWorkOrderShowBarcode()}
                            onClick={() => this.onUpdateShowBarcode()}
                            style={{ margin: 0 }}
                        />
                        <div className='RowDiv'
                            style={{ paddingLeft: 4, width: 'auto', whiteSpace: 'nowrap' }} >
                            {this.getSectionHeader('barcode')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onUpdateShowBarcode() {
        let workOrderConfig = TemplateSettingsManager.getWorkOrderSettings()
        workOrderConfig.showBarcode = !TemplateSettingsManager.getWorkOrderShowBarcode()
        TemplateSettingsManager.updateWorkOrderSettings(workOrderConfig)
        this.dataChanged(this.state.data)
    }

    getBarcodeResize() {
        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getBarcodeSettings('isResizeBarcode', false)}
                            onClick={() => this.onUpdateIsBarcodeResize()}
                            style={{ margin: 0 }}
                        />
                    </div>
                    <div className='RowDiv'
                        style={{ marginRight: 0, width: 'auto', whiteSpace: 'nowrap' }} >
                        {this.getSectionHeader('barcode_resize')}
                    </div>
                    <div className='RowReverseDiv'>
                        <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.onUpdateBarcodeResize('down')}>
                            -
                        </div>
                        <div className='HeaderButton' onClick={(event) => this.onUpdateBarcodeResize('up')}>
                            +
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onUpdateIsBarcodeResize() {
        var isBarcodeResize = TemplateSettingsManager.getBarcodeSettings('isResizeBarcode', false)
        TemplateSettingsManager.updateBarcodeSettings('isResizeBarcode', false, !isBarcodeResize)
        this.dataChanged(this.state.data)
    }

    onUpdateBarcodeResize(action) {
        var isBarcodeResize = TemplateSettingsManager.getBarcodeSettings('isResizeBarcode', false)
        if (!isBarcodeResize) {
            return
        }
        var upperLimit = 400
        var lowerLimit = -200
        var stepValue = 25
        var size = TemplateSettingsManager.getBarcodeSettings('resizeValue', 0)
        if (action === 'up') {
            size = size + stepValue
            if (size > upperLimit) {
                size = upperLimit
            }
        }
        else if (action === 'down') {
            size = size - stepValue
            if (size < lowerLimit) {
                size = lowerLimit
            }
        }

        TemplateSettingsManager.updateBarcodeSettings('resizeValue', 0, size)
        this.dataChanged(this.state.data)
    }

    getInstructionCheckbox() {
        if (!getIsWorkOrder(this.state.data.type)) {
            return <></>
        }

        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getWorkOrderShowInstruction()}
                            onClick={() => this.onUpdateShowInstruction()}
                            style={{ margin: 0 }}
                        />
                        <div className='RowDiv'
                            style={{ paddingLeft: 4, width: 'auto', whiteSpace: 'nowrap' }} >
                            {this.getSectionHeader('show_instruction')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onUpdateShowInstruction() {
        let workOrderConfig = TemplateSettingsManager.getWorkOrderSettings()
        workOrderConfig.showInstruction = !TemplateSettingsManager.getWorkOrderShowInstruction()
        TemplateSettingsManager.updateWorkOrderSettings(workOrderConfig)
        this.dataChanged(this.state.data)
    }

    getJobCardCheckbox() {
        if (!getIsWorkOrder(this.state.data.type)) {
            return <></>
        }

        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getWorkOrderShowJobCardStatus()}
                            onClick={() => this.onUpdateShowJobCardStatus()}
                            style={{ margin: 0 }}
                        />
                        <div className='RowDiv'
                            style={{ paddingLeft: 4, width: 'auto', whiteSpace: 'nowrap' }} >
                            {this.getSectionHeader('show_job_status')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onUpdateShowJobCardStatus() {
        let workOrderConfig = TemplateSettingsManager.getWorkOrderSettings()
        workOrderConfig.showJobCardStatus = !TemplateSettingsManager.getWorkOrderShowJobCardStatus()
        TemplateSettingsManager.updateWorkOrderSettings(workOrderConfig)
        this.dataChanged(this.state.data)
    }

    getJobCardRunningNumber() {
        if (!getIsWorkOrder(this.state.data.type)) {
            return <></>
        }

        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getWorkOrderShowJobCardRunningNumber()}
                            onClick={() => this.onUpdateJobCardRunningNumber()}
                            style={{ margin: 0 }}
                        />
                        <div className='RowDiv'
                            style={{ paddingLeft: 4, width: 'auto', whiteSpace: 'nowrap' }} >
                            {this.getSectionHeader('show_running_number')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onUpdateJobCardRunningNumber() {
        let workOrderConfig = TemplateSettingsManager.getWorkOrderSettings()
        workOrderConfig.showJobCardRunningNumber = !TemplateSettingsManager.getWorkOrderShowJobCardRunningNumber()
        TemplateSettingsManager.updateWorkOrderSettings(workOrderConfig)
        this.dataChanged(this.state.data)
    }

    getTaggedBinWarehouseCheckbox(position) {
        if (!getIsWorkOrder(this.state.data.type)) {
            return <></>
        }

        return (
            <div className="RowDiv">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='RowDiv'
                        style={{ marginRight: 4, width: 'auto' }} >
                        <input type="checkbox"
                            checked={TemplateSettingsManager.getWorkOrderShowTaggedBinWarehouse(position)}
                            onClick={() => this.onUpdateShowTaggedBinWarehouse(position)}
                            style={{ margin: 0 }}
                        />
                        <div className='RowDiv'
                            style={{ paddingLeft: 4, width: 'auto', whiteSpace: 'nowrap' }} >
                            {this.getSectionHeader(getLocalisedText('show_tagged_bin_warehouse') + ' (' + getLocalisedText(position) + ')')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onUpdateShowTaggedBinWarehouse(position) {
        let workOrderConfig = TemplateSettingsManager.getWorkOrderSettings()

        if(position === 'top') {
            workOrderConfig.showTaggedBinWarehouseTop = !TemplateSettingsManager.getWorkOrderShowTaggedBinWarehouse(position)

            if (workOrderConfig.showTaggedBinWarehouseTop === true) {
                workOrderConfig.showTaggedBinWarehouseBottom = false
            }
        }
        else if (position === 'bottom') {
            workOrderConfig.showTaggedBinWarehouseBottom = !TemplateSettingsManager.getWorkOrderShowTaggedBinWarehouse(position)

            if (workOrderConfig.showTaggedBinWarehouseBottom === true) {
                workOrderConfig.showTaggedBinWarehouseTop = false
            }
        }
        TemplateSettingsManager.updateWorkOrderSettings(workOrderConfig)
        this.dataChanged(this.state.data)
    }

    getIsAllowEditTaggedBinWarehouseTitle() {
        let top = TemplateSettingsManager.getWorkOrderShowTaggedBinWarehouse('top')
        let bottom = TemplateSettingsManager.getWorkOrderShowTaggedBinWarehouse('bottom')

        return top || bottom
    }

    getTaggedBinWarehouseTitle() {
        if (!getIsWorkOrder(this.state.data.type)) {
            return <></>
        }

        return <input
            id={'tagged_bin_warehouse_title'}
            className="TextField"
            placeholder={getLocalisedText('barcode_for_row_rack_bin')}
            type="text"
            dir={Utility.getInputDirection()}
            value={TemplateSettingsManager.getWorkOrderTaggedBinWarehouseTitle()}
            style={{ marginRight: 5 }}
            disabled={!this.getIsAllowEditTaggedBinWarehouseTitle()}
            onChange={(event) => this.workOrderSettingsChanged(event, 'taggedBinWarehouseTitle')}
        />
    }

    getIsContainsCustomField() {
        if (CustomFieldManager.getCustomFields().length > 0) {
            if (!getIsPayslip(this.state.data.type)
                && !getIsCheque(this.state.data.type)
                && !getIsPayrollCheck(this.state.data.type)
                && !getIsQCDocument(this.state.data.type)) {
                return true
            }
        }

        return false
    }

    getCustomFields() {
        var title = 'custom_field'
        return (
            <div className='ListPickerHolderStyle'>
                <div className='RowDiv HeaderRow'>
                    {this.getSectionHeader(title, false, 12.5, 4)}
                </div>
                <div
                    className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    <div className='ColumnDiv parent-width'>
                        {!getIsShipList(this.state.data.type) &&
                            this.getIsNotAllowEditCompanyAddressTemplate() &&
                            !getIsWorkOrder(this.state.data.type) &&
                            this.getCustomFieldCallback('company_address', EditorInfoType.from)}

                        {!getIsPickList(this.state.data.type) &&
                            !getIsStockTransfer(this.state.data.type) &&
                            !getIsStockRequest(this.state.data.type) &&
                            !getIsStockIssue(this.state.data.type) &&
                            !getIsSecurityGateEntry(this.state.data.type) &&
                            !getIsPurchaseRequisition(this.state.data.type) &&
                            !getIsServiceRequisition(this.state.data.type) &&
                            !getIsServiceOrderTemplate(this.state.data.templateId) &&
                            !getIsWorkOrder(this.state.data.type) &&
                            !getIsQCInspection(this.state.data.type) &&
                            !getIsInspectionReport(this.state.data.type) &&
                            !getIsRequestForQuotation(this.state.data.type) &&
                            this.getCustomFieldCallback(this.state.data.billToTitle, EditorInfoType.clientBillToAddress)}

                        {!getIsPickList(this.state.data.type) &&
                            !getIsPackList(this.state.data.type) &&
                            !getIsStockTransfer(this.state.data.type) &&
                            !getIsStockRequest(this.state.data.type) &&
                            !getIsStockIssue(this.state.data.type) &&
                            !getIsSecurityGateEntry(this.state.data.type) &&
                            !getIsPurchaseRequisition(this.state.data.type) &&
                            !getIsServiceRequisition(this.state.data.type) &&
                            !getIsWorkOrder(this.state.data.type) &&
                            !getIsQCInspection(this.state.data.type) &&
                            !getIsInspectionReport(this.state.data.type) &&
                            !getIsRequestForQuotation(this.state.data.type) &&
                            this.getCustomFieldCallback(this.state.data.shipToTitle, EditorInfoType.clientShipToAddress)}

                        {!getIsFulfillment(this.state.data.type) &&
                            !getIsGoodsReceipt(this.state.data.type) &&
                            !getIsPickList(this.state.data.type) &&
                            !getIsPackList(this.state.data.type) &&
                            !getIsShipList(this.state.data.type) &&
                            !getIsStockTransfer(this.state.data.type) &&
                            !getIsStockRequest(this.state.data.type) &&
                            !getIsStockIssue(this.state.data.type) &&
                            !getIsPurchaseRequisition(this.state.data.type) &&
                            !getIsSecurityGateEntry(this.state.data.type) &&
                            !getIsServiceRequisition(this.state.data.type) &&
                            this.getIsPurchaseOrderAndBillTemplates &&
                            !getIsWorkOrder(this.state.data.type) &&
                            !getIsQCInspection(this.state.data.type) &&
                            !getIsInspectionReport(this.state.data.type) &&
                            !getIsRequestForQuotation(this.state.data.type) &&
                            this.getCustomFieldCallback(this.state.data.shipFromTitle, EditorInfoType.clientShipFromAddress)}

                        {!Utility.getIsReceiptPaperSize() &&
                            !getIsShipList(this.state.data.type) &&
                            this.getCustomFieldCallback('document_details', EditorInfoType.documentDetails)}

                        {!Utility.getIsReceiptPaperSize() &&
                            !getIsPickList(this.state.data.type) &&
                            !getIsPackList(this.state.data.type) &&
                            !getIsShipList(this.state.data.type) &&
                            !getIsSecurityGateEntry(this.state.data.type) &&
                            !getIsWorkOrder(this.state.data.type) &&
                            !getIsQCInspection(this.state.data.type) &&
                            !getIsInspectionReport(this.state.data.type) &&
                            this.getCustomFieldCallback('subTable_configuration', EditorInfoType.additionalLeftFooterTable)}

                        {!Utility.getIsReceiptPaperSize() &&
                            !getIsInspectionReport(this.state.data.type) &&
                            this.getCustomFieldCallback(getLocalisedText('custom_field') + ' (' + getLocalisedText('top') + ')', EditorInfoType.topCustomFieldTable)}

                        {!Utility.getIsReceiptPaperSize() &&
                            !getIsInspectionReport(this.state.data.type) &&
                            this.getCustomFieldCallback(getLocalisedText('custom_field') + ' (' + getLocalisedText('bottom') + ')', EditorInfoType.bottomCustomFieldTable)}

                        {(getIsQuotation(this.state.data.type) ||
                            getIsInvoice(this.state.data.type) ||
                            getIsRequestForQuotation(this.state.data.type) ||
                            getIsPurchaseInwardQuotation(this.state.data.type) ||
                            getIsBill(this.state.data.type) ||
                            getIsOrder(this.state.data.type) ||
                            getIsSalesOrder(this.state.data.type))
                            && this.state.data.templateId === 51
                            && this.getCustomFieldCallback(getLocalisedText('custom_field') + ' (' + Utility.getChildCustomFieldType(this.state.data.type.toLowerCase()) + ')', EditorInfoType.childCustomFieldTable)}

                        {
                            getIsInspectionReport(this.state.data.type) &&
                            this.getCustomFieldCallback(getLocalisedText('custom_field') + ' (' + getLocalisedText('final_inspection') + ')', EditorInfoType.topCustomFieldTable)}
                    </div>
                </div>
            </div>
        );
    }

    getCustomFieldCallback(title, valueStr) {
        return <div
            className="RowDiv"
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                // paddingTop: 6,
                paddingBottom: 6,
            }}>
            <div className='RowDiv'>
                {this.getSectionHeader(title, false, 12.5, 4)}
            </div>
            <div
                onClick={() => {
                    switch (valueStr) {
                        case EditorInfoType.from:
                            this.props.onAddressFormatClick(AddressType.from)
                            break;
                        case EditorInfoType.clientBillToAddress:
                            this.props.onAddressFormatClick(AddressType.billTo)
                            break;
                        case EditorInfoType.clientShipToAddress:
                            this.props.onAddressFormatClick(AddressType.shipTo)
                            break;
                        case EditorInfoType.clientShipFromAddress:
                            this.props.onAddressFormatClick(AddressType.shipFrom)
                            break;
                        case EditorInfoType.documentDetails:
                            this.props.onDocumentDetailsClick()
                            break;
                        case EditorInfoType.additionalLeftFooterTable:
                            this.props.onEditButtonCallbackClicked(EditorInfoType.additionalLeftFooterTable)
                            break;
                        case EditorInfoType.topCustomFieldTable:
                            this.props.onEditButtonCallbackClicked(EditorInfoType.topCustomFieldTable)
                            break;
                        case EditorInfoType.bottomCustomFieldTable:
                            this.props.onEditButtonCallbackClicked(EditorInfoType.bottomCustomFieldTable)
                            break;
                        case EditorInfoType.childCustomFieldTable:
                            this.props.onEditButtonCallbackClicked(EditorInfoType.childCustomFieldTable)
                            break;
                        default:
                            break;
                    }
                }}
                className="HeaderButton"
                style={{ marginRight: 5 }}>
                <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
            </div>
        </div>
    }


    getSelectedValueFor(item) {
        switch (item) {
            case EditorInfoType.documentType:
                return 'showDocumentType'
            case EditorInfoType.companyLogo:
                return 'showCompanyLogo'
            case EditorInfoType.companyName:
                return 'showCompanyName'
            case EditorInfoType.from:
                return 'showFrom'
            case EditorInfoType.clientBillToAddress:
                return 'showClientBillToAddress'
            case EditorInfoType.clientShipToAddress:
                return 'showClientShipToAddress'
            case EditorInfoType.clientShipFromAddress:
                return 'showClientShipFromAddress'
            case EditorInfoType.refNumber:
                return 'showRefNumber'
            case EditorInfoType.termsAndCondition:
                return 'showTermsAndCondition'
            case EditorInfoType.notes:
                return 'showNotes'
            case EditorInfoType.startDate:
                return 'showStartDate'
            case EditorInfoType.dueDate:
                return 'showDueDate'
            case EditorInfoType.additionalDate:
                return 'showAdditionalDate'
            case EditorInfoType.signature:
                return 'showSignature'
            case EditorInfoType.currency:
                return 'showCurrency'
            case EditorInfoType.checkTableCurrency:
                return 'showCheckCurrency'
            case EditorInfoType.eInvoiceSection:
                return 'showEInvoiceSection'
            case EditorInfoType.linkDocumentNumber:
                return 'showLinkDocumentNumber'
            case EditorInfoType.repeatableHeader:
                return 'showRepeatableHeader'
            case EditorInfoType.headerFooter:
                return 'showHeaderFooter'
            case EditorInfoType.paymentFooter:
                return 'showPaymentFooter'
            case EditorInfoType.repeatableHeaderFooter:
                return 'showRepeatableHeaderFooter'
            case EditorInfoType.repeatableFooter:
                return 'showRepeatableFooter'
            case EditorInfoType.additionalLeftFooterTable:
                return 'showAdditionalTable'
            case EditorInfoType.watermark:
                return 'showWatermark'
            case EditorInfoType.placeOfSupply:
                return 'showPlaceOfSupply'
            case EditorInfoType.sourceOfSupply:
                return 'showSourceOfSupply'
            case EditorInfoType.destinationOfSupply:
                return 'showDestinationOfSupply'
            case EditorInfoType.generatedMessage:
                return 'showGeneratedMessage'
            case EditorInfoType.topSectionAboveTable:
                return 'showTopSectionAboveTable'
            case EditorInfoType.payslipOvertime:
                return 'showPayslipOvertime'
            case EditorInfoType.payslipLOP:
                return 'showPayslipLOP'
            case EditorInfoType.payslipBasic:
                return 'showPayslipBasic'
            case EditorInfoType.accountProductTable:
                return 'showAccountProduct'
            case EditorInfoType.documentLabel:
                return 'showDocumentLabel'
            case EditorInfoType.exchangeRate:
                return 'showExchangeRate'
            case EditorInfoType.isIncludesBomProductTable:
                return 'showBomProductTable'
            case EditorInfoType.checkTable: 
                return 'showCheckTable'
            case EditorInfoType.hsnSacTaxTable:
                return 'showHsnSacTaxTable'
            case EditorInfoType.approvalSection:
                return 'showApprovalSection'
            case EditorInfoType.customApprovalSection:
                return 'showCustomApprovalSection'
            default:
                return 'make changes here'

        }
    }   

    getShowHideValueFor(item) {
        switch (item) {
            case EditorInfoType.documentType:
                return this.state.data.showDocumentType
            case EditorInfoType.companyLogo:
                return this.state.data.showCompanyLogo
            case EditorInfoType.companyName:
                return this.state.data.showCompanyName
            case EditorInfoType.from:
                return this.state.data.showFrom
            case EditorInfoType.clientBillToAddress:
                return this.state.data.showClientBillToAddress
            case EditorInfoType.clientShipToAddress:
                return this.state.data.showClientShipToAddress
            case EditorInfoType.clientShipFromAddress:
                return this.state.data.showClientShipFromAddress
            case EditorInfoType.refNumber:
                return this.state.data.showRefNumber
            case EditorInfoType.termsAndCondition:
                return this.state.data.showTermsAndCondition
            case EditorInfoType.notes:
                return this.state.data.showNotes
            case EditorInfoType.startDate:
                return this.state.data.showStartDate
            case EditorInfoType.dueDate:
                return this.state.data.showDueDate
            case EditorInfoType.additionalDate:
                return this.state.data.showAdditionalDate
            case EditorInfoType.signature:
                return this.state.data.showSignature
            case EditorInfoType.currency:
                return this.state.data.showCurrency
            case EditorInfoType.checkTableCurrency:
                return this.state.data.showCheckCurrency;
            case EditorInfoType.eInvoiceSection:
                return this.state.data.showEInvoiceSection
            case EditorInfoType.linkDocumentNumber:
                return this.state.data.showLinkDocumentNumber
            case EditorInfoType.repeatableHeader:
                return TemplateSettingsManager.getRepeatableTableHeader()
            case EditorInfoType.headerFooter:
                return TemplateSettingsManager.getHeaderFooterIsVisible()
            case EditorInfoType.repeatableHeaderFooter:
                return TemplateSettingsManager.getRepeatableHeaderFooterIsVisible()
            case EditorInfoType.repeatableFooter:
                return TemplateSettingsManager.getRepeatableFooterIsVisible()
            case EditorInfoType.additionalLeftFooterTable:
                return TemplateSettingsManager.getAdditionalTableIsVisible()
            case EditorInfoType.watermark:
                return TemplateSettingsManager.getWatermarkVisible()
            case EditorInfoType.placeOfSupply:
                return TemplateSettingsManager.getSupplyConfigurationVisibility('place')
            case EditorInfoType.sourceOfSupply:
                return TemplateSettingsManager.getSupplyConfigurationVisibility('source')
            case EditorInfoType.destinationOfSupply:
                return TemplateSettingsManager.getSupplyConfigurationVisibility('destination')
            case EditorInfoType.paymentFooter:
                return this.state.data.showPaymentFooter
            case EditorInfoType.generatedMessage:
                return TemplateSettingsManager.getGeneratedMessageVisibility()
            case EditorInfoType.topSectionAboveTable:
                return TemplateSettingsManager.getTopSectionAboveTableIsVisible()
            case EditorInfoType.payslipOvertime:
                return TemplateSettingsManager.getIsPayslipOvertimeVisible()
            case EditorInfoType.payslipLOP:
                return TemplateSettingsManager.getIsPayslipLOPVisible(this.state.data)
            case EditorInfoType.payslipBasic:
                return TemplateSettingsManager.getIsPayslipBasicVisible(this.state.data)
            case EditorInfoType.accountProductTable:
                return TemplateSettingsManager.getTableColumnForAccountProductIsVisible()
            case EditorInfoType.documentLabel:
                return TemplateSettingsManager.getDocumentLabelVisible()
            case EditorInfoType.exchangeRate:
                return TemplateSettingsManager.getExchangeRateVisible()
            case EditorInfoType.checkTable: 
                return TemplateSettingsManager.getCheckTableVisible()
            case EditorInfoType.isIncludesBomProductTable:
                return Utility.getIsIncludesBomProductTable()
            case EditorInfoType.documentTitle:
                return true
            case EditorInfoType.hsnSacTaxTable:
                return Utility.getIsShowHsnSacTaxTable()
            case EditorInfoType.approvalSection:
                return TemplateSettingsManager.getApprovalSectionSettingsVisible()
            case EditorInfoType.customApprovalSection:
                return TemplateSettingsManager.getCustomApprovalSectionSettingsVisible()
            default:
                return false
        }
    }

    getGeneratedMessage() {
        var customText = TemplateSettingsManager.getGeneratedMessage().customText
        if (customText !== undefined && customText !== null) {
            if (customText.length > 0) {
                if (customText === 'print_only_message') {
                    return getLocalisedText(customText) + ' ' + getLocalisedText(this.state.data.type)
                }
                return getLocalisedText(customText)
            }
        }
        return ''
    }


    isAccountModule() {
        return getIsAccountModule(this.state.data, undefined) ? getIsAccountModule(this.state.data, undefined) : false
    }

    isInvoiceModule() {
        if (this.state.data.type.toLowerCase() === 'invoice') {
            return true
        }
        else if (getIsDebitOrCreditNote(this.state.data.type)) {
            return true
        }
        return false
    }

    isContainReferenceDateOrDueDate() {
        if (getIsAccountModule(this.state.data, undefined)) {
            if (this.state.data.dueDate !== undefined && this.state.data.dueDate !== '') {
                return true
            }
            else {
                return false
            }
        }
        else if (getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) {
            return false
        }
        else if (getIsJournalEntry(this.state.data.type)) {
            return false
        }
        else if (getIsPickList(this.state.data.type) || getIsPackList(this.state.data.type) || getIsShipList(this.state.data.type)) {
            return false
        }
        else if (getIsPayslip(this.state.data.type)) {
            return false
        }
        else if (getIsCheque(this.state.data.type)) {
            return false
        }
        else {
            return true
        }
    }

    isContainLinkDocument() {
        if (getIsFulfillment(this.state.data.type) ||
            getIsJournalEntry(this.state.data.type) ||
            getIsPackList(this.state.data.type) ||
            getIsShipList(this.state.data.type)) {
            if (this.state.data.linkDocumentType !== '' && this.state.data.linkDocumentNumber !== '') {
                return true
            }
        }
        return false
    }

    isTnCOrNotes(valueStr) {
        return (valueStr === EditorInfoType.termsAndCondition
            || valueStr === EditorInfoType.notes
            || valueStr === EditorInfoType.generatedMessage
            || valueStr === EditorInfoType.hsnSacTaxTable)
    }

    isCheckTable(valueStr) {
        return (valueStr === EditorInfoType.checkTable)
    }

    getYourCompanyNameLabel() {
        if (this.state.data.type.toLowerCase() === 'purchase order' || this.state.data.type.toLowerCase() === 'bill') {
            if (this.getIsPurchaseOrderAndBillTemplates() || getIsServiceOrderTemplate(this.state.data.templateId)) {
                return 'your_company_name'
            }
            return 'vendor_name'
        }
        if (getIsPickList(this.state.data.type)) {
            return 'company_warehouse_name'
        }
        if (getIsPackList(this.state.data.type)) {
            return 'warehouse_name'
        }
        if (getIsBOMModule(this.state.data.type)) {
            return 'product'
        }
        return 'your_company_name'
    }

    onUpdateCurrencyFormat(type) {
        var data = this.state.data
        var format = CurrencyFormatType.SYMBOL
        var currencyFormat = this.state.data.currencyFormat
        var currencyCustomValue = this.state.data.currencyCustomValue
        if (currencyFormat !== undefined && currencyFormat !== null) {
            if (type === 'symbol') {
                if (currencyFormat === CurrencyFormatType.SYMBOL) {
                    format = CurrencyFormatType.CUSTOM
                }
                else if (currencyFormat === CurrencyFormatType.NAME) {
                    format = CurrencyFormatType.SYMBOL_NAME
                    currencyCustomValue = (this.state.data.currencyName + ' ' + this.state.data.currency)
                }
                else if (currencyFormat === CurrencyFormatType.SYMBOL_NAME) {
                    format = CurrencyFormatType.NAME
                    currencyCustomValue = this.state.data.currencyName
                }
                else {
                    format = CurrencyFormatType.SYMBOL
                    currencyCustomValue = this.state.data.currency
                }
            }
            else if (type === 'name') {
                if (currencyFormat === CurrencyFormatType.SYMBOL) {
                    if (this.state.data.currency === undefined || this.state.data.currency === null) {
                        format = CurrencyFormatType.NAME
                        currencyCustomValue = this.state.data.currencyName
                    }
                    else {
                        format = CurrencyFormatType.SYMBOL_NAME
                        currencyCustomValue = (this.state.data.currencyName + ' ' + this.state.data.currency)
                    }
                }
                else if (currencyFormat === CurrencyFormatType.NAME) {
                    format = CurrencyFormatType.CUSTOM
                }
                else if (currencyFormat === CurrencyFormatType.SYMBOL_NAME) {
                    format = CurrencyFormatType.SYMBOL
                    currencyCustomValue = this.state.data.currency
                }
                else {
                    format = CurrencyFormatType.NAME
                    currencyCustomValue = this.state.data.currencyName
                }
            }
            else if (type === 'custom') {
                format = CurrencyFormatType.CUSTOM
            }
        }
        else {
            if (type === 'symbol') {
                format = CurrencyFormatType.SYMBOL
            }
            else if (type === 'name') {
                format = CurrencyFormatType.NAME
            }
            else if (type === 'custom') {
                format = CurrencyFormatType.CUSTOM
            }
        }

        data.currencyCustomValue = currencyCustomValue
        data.currencyFormat = format
        var currencyObj = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency
        currencyObj.format = format
        currencyObj.customValue = currencyCustomValue
        TemplateSettingsManager.updateCurrencySymbol(currencyObj)
        this.dataChanged(data)
    }

    getPickerStyle() {
        if (Utility.getIsArabicLang()) {
            return {
                textAlignLast: 'right',
                direction: 'rtl',
                paddingRight: 24,
            }
        }
        return {}
    }

    getIsArabicAddress(data, addressType) {
        if (Utility.getIsArabicLang()) {
            switch (addressType) {
                case EditorInfoType.companyName:
                    if (data.companyNameInArabic !== undefined && data.companyNameInArabic !== null) {
                        if (data.companyNameInArabic !== '') {
                            return true
                        }
                    }
                    return false
                case EditorInfoType.from:
                    if (data.fromInArabic !== undefined && data.fromInArabic !== null) {
                        if (data.fromInArabic !== '') {
                            return true
                        }
                    }
                    return false
                case EditorInfoType.clientBillToAddress:
                    if (data.clientBillToAddressInArabic !== undefined && data.clientBillToAddressInArabic !== null) {
                        if (data.clientBillToAddressInArabic !== '') {
                            return true
                        }
                    }
                    return false
                case EditorInfoType.clientShipToAddress:
                    if (data.clientShipToAddressInArabic !== undefined && data.clientShipToAddressInArabic !== null) {
                        if (data.clientShipToAddressInArabic !== '') {
                            return true
                        }
                    }
                    return false
                default:
                    return false
            }
        }
        return false
    }

    getCompanyName() {
        var name = ''
        if (getIsPayslip(this.state.data.type)) {
            if (this.state.data.payslip !== undefined && this.state.data.payslip !== null) {
                if (this.state.data.payslip.tenantInfo !== undefined && this.state.data.payslip.tenantInfo !== null) {
                    name = this.state.data.payslip.tenantInfo.organizationName
                }
            }
        }
        else if (this.getIsArabicAddress(this.state.data, EditorInfoType.companyName)) {
            name = this.state.data.companyNameInArabic
        }
        if (this.state.data.type.toLowerCase() === 'purchase order' || this.state.data.type.toLowerCase() === 'bill') {
            if (this.getIsPurchaseOrderAndBillTemplates()) {
                if (this.state.data.tenantInfo) {
                    name = this.state.data.tenantInfo.name
                }
                else {
                    name = this.state.data.companyName
                }
            }
            else if (getIsServiceOrderTemplate(this.state.data.templateId)) {
                name = this.state.data.shipToName
            }
            else {
                name = this.state.data.companyName
            }
        }
        else {
            name = this.state.data.companyName
        }

        return name
    }

    getCompanyAddress() {
        var address = ''
        if (getIsPayslip(this.state.data.type)) {
            if (this.state.data !== undefined && this.state.data !== null) {
                if (this.state.data.payslip !== undefined && this.state.data.payslip !== null) {
                    address = PayslipUtility.getCompanyAddress(this.state.data.payslip, true)
                }
            }
        }
        else if (this.getIsArabicAddress(this.state.data, EditorInfoType.from)) {
            address = this.props.data.fromInArabic
        }
        else if (this.getIsPurchaseOrderAndBillTemplates()) {
            address = this.props.data.tenantAddress
            if (address === undefined) {
                address = this.props.data.from
            }
        }
        else {
            address = this.props.data.from
        }
        return address
    }

    getNotesValue() {
        const customNoteTemplateId = [51, 52, 53, 54, 58, 59]
        if (customNoteTemplateId.includes(this.state.data.templateId)) {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
                return TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
            } else {
                return ''
            }
        }

        return this.state.data.notes
    }

    getEmployeeAddressLabel() {
        if (getIsPayslip(this.state.data.type)) {
            return 'employee_address'
        }

        return this.state.data.billToTitle
    }

    getEmployeeAddress() {
        var address = ''
        if (getIsPayslip(this.state.data.type)) {
            if (this.state.data !== undefined && this.state.data !== null) {
                if (this.state.data.payslip !== undefined && this.state.data.payslip !== null) {
                    address = PayslipUtility.getEmployeeAddress(this.state.data.payslip, true)
                }
            }
        }
        else if (this.getIsArabicAddress(this.state.data, EditorInfoType.companyName)) {
            address = this.state.data.clientBillToAddressInArabic
        }
        else if (getIsCheque(this.state.data.type) && getIsChequeAddressContact(this.state.data.templateId)) {
            if (Array.isArray(this.state.data.lineItems) && this.state.data.lineItems.length > 0) {
                const chequeData = this.state.data.lineItems[0];
                const addressObj = { clientBillToAddressObj: ChequeParser.getContactAddress(chequeData) };
                if (this.state.data.templateId === 4) {
                    address = AddressParser.getVisibleAddressFields(AddressType.billTo, addressObj,this.state.data);
                } else {
                    address = `${chequeData.payTo}<br>${AddressParser.getVisibleAddressFields(AddressType.billTo, addressObj, this.state.data)}`;
                }
            }
        }
        else {
            address = this.state.data.clientBillToAddress
        }
        return address
    }

    getIsPurchaseOrderAndBillTemplates() {
        var templates = [44, 45]
        return templates.includes(this.state.data.templateId)
    }

    getIsExcludedCreditAndDebitNoteShipTo() {
        if (getIsDebitOrCreditNote(this.state.data.type)) {
            if (this.state.data.shipToAddress !== undefined && this.state.data.shipToAddress !== null) {
                return false
            }
            else {
                return true
            }
        } else {
            return false
        }
    }

    getCheckFields() {
        return (
            <div className='ListPickerHolderStyle RowDiv HeaderRow' style={{ flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row' }}>
                {this.getSectionHeader('cheque_fields')}
                <div className="HeaderButton" style={{ marginRight: 5 }} onClick={() => { this.props.onChequeDetailsClick() }}>
                    <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
                </div>
            </div>
        );
    }

    getCheckGridStyleSection() {
        return (
            <div className="ListPickerHolderStyle">
                <div className='RowDiv HeaderRow' style={{ flexDirection:  'row' }}>
                    {this.getSectionHeader('Check Details Style')}
                </div>
            
                <div className="RowDiv HeaderRow bg-white fs-m">
                    <div style={{whiteSpace: 'nowrap'}}>
                        Table 1
                    </div>
                    {this.getCheckTableStyleButton('table0')}
                </div>
                <div className="RowDiv HeaderRow  bg-white fs-m">
                    <div style={{whiteSpace: 'nowrap'}}>
                        Table 2
                    </div>
                    {this.getCheckTableStyleButton('table1')}
                </div>
            </div>
        );
    }

    getCheckTableStyleButton(field) {
        return (
            <div className="RowReverseDiv">
                <div
                    className="HeaderButton clicked-effect d-flex align-items-center"
                    style={{ marginLeft: 10, userSelect: 'none', display: 'flex', padding: 4 }}
                    onClick={(event) => this.updateCheckStyle("down", field, true)}
                >
                    <img src={Asset.icon.ic_refresh_black} alt="reset" style={{ width: 10 }} />
                </div>

                <div
                    className="HeaderButton clicked-effect"
                    style={{ marginLeft: 10, userSelect: 'none' }}
                    onClick={(event) => this.updateCheckStyle("down", field, false)}
                >
                    -
                </div>
                <div
                    className="HeaderButton clicked-effect"
                    style={{ marginLeft: 10, userSelect: 'none' }}
                    onClick={(event) => this.updateCheckStyle("up", field, false)}
                >
                    +
                </div>
            </div>
        );
    }

    getIsSwapAddressSection() {
        return <div
            className="ListPickerHolderStyle RowDiv HeaderRow"
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                fontSize: 12,
                padding: 0,
            }}>
            <div className='RowDiv'>
                <input type="checkbox"
                    checked={TemplateSettingsManager.getIsSwapAddress()}
                    onClick={() => this.onSwapAddressChanged()}
                    style={{ marginRight: 4, marginLeft: 8 }} />
                <div style={{
                    textAlign: 'left',
                    paddingTop: 6,
                    paddingBottom: 6,
                    fontSize: 12,
                    color: "black",
                    fontWeight: "600"
                }}>
                    {getLocalisedText("swap_address")}
                </div>
            </div>
        </div>
    }

    onSwapAddressChanged() {
        const isSwapAddress = !TemplateSettingsManager.getIsSwapAddress()
        TemplateSettingsManager.updateIsSwapAddress(isSwapAddress);
        let data = this.state.data
        AddressParser.swapAddress(data, isSwapAddress)
        this.dataChanged(data);
    }

    getApprovalSection() {
        return (
            <div className='ListPickerHolderStyle RowDiv HeaderRow'
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                {this.getSectionHeader('approval_section')}
                <div onClick={() => {
                    this.props.onEditButtonCallbackClicked(EditorInfoType.approvalSection)
                }}
                    className="HeaderButton"
                    style={{ marginRight: 5 }}>
                    <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
                </div>
            </div>
        );
    }

    renderFontSetting(type) {
        const fontSettings = [EditorInfoType.termsAndCondition, EditorInfoType.notes, EditorInfoType.generatedMessage]

        if (fontSettings.includes(type) && this.getShowHideValueFor(type)) {
            return <>
                {type === EditorInfoType.generatedMessage && this.getGeneralTextAlignSettings(type)}
                {this.getGeneralFontSettings(type)}
                {this.getWordSpacingSettings(type)}
            </>
        }

        return undefined
    }

    getGeneralTextAlignSettings(type) {
        return <div
            className="RowDiv HeaderRow"
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
            }}>
            <div className='RowDiv'>
                {this.getSectionHeader('text', false, 12.5, 4)}
            </div>
            <div className='RowDiv'
                style={{
                    flexDirection: 'row-reverse',
                    marginLeft: 0
                }}>
                <div className='HeaderButton'
                    style={{
                        marginLeft: 10,
                        fontWeight: 'bold',
                        backgroundColor: this.getTnCOrNotesFontSettingsByType(type, 'textAlign', 'center') === 'right' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                        color: this.getTnCOrNotesFontSettingsByType(type, 'textAlign', 'center') === 'right' ? 'white' : undefined,
                    }}
                    onClick={(event) => this.setFontSettingsByType(type, 'textAlign', 'right')}>
                    {getLocalisedText('right')}
                </div>
                <div className='HeaderButton'
                    style={{
                        marginLeft: 10,
                        fontWeight: 'bold',
                        backgroundColor: this.getTnCOrNotesFontSettingsByType(type, 'textAlign', 'center') === 'center' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                        color: this.getTnCOrNotesFontSettingsByType(type, 'textAlign', 'center') === 'center' ? 'white' : undefined,
                    }}
                    onClick={(event) => this.setFontSettingsByType(type, 'textAlign', 'center')}>
                    {getLocalisedText('center')}
                </div>
                <div className='HeaderButton'
                    style={{
                        marginLeft: 10,
                        fontWeight: 'bold',
                        backgroundColor: this.getTnCOrNotesFontSettingsByType(type, 'textAlign', 'center') === 'left' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                        color: this.getTnCOrNotesFontSettingsByType(type, 'textAlign', 'center') === 'left' ? 'white' : undefined,
                    }}
                    onClick={(event) => this.setFontSettingsByType(type, 'textAlign', 'left')}>
                    {getLocalisedText('left')}
                </div>
            </div>
        </div>
    }

    getGeneralFontSettings(type) {
        return <div
            className="RowDiv HeaderRow"
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
            }}>
            <div className='RowDiv'
                style={{ marginRight: 4, width: 'auto' }} >
                <input type="checkbox"
                    checked={this.getIsEnableFontSettings(type)}
                    onClick={() => this.setIsEnableFontSettings(type)}
                    style={{ margin: 0 }}
                />
            </div>
            <div className='RowDiv'>
                {this.getSectionHeader('font', false, 12.5, 4)}
            </div>
            <div className='RowDiv'
                style={{
                    flexDirection: 'row-reverse',
                    marginLeft: 0
                }}>
                <div className='HeaderButton'
                    style={{
                        marginLeft: 10,
                        fontWeight: 'bold',
                        backgroundColor: this.getTnCOrNotesFontSettingsByType(type, 'weight', 'normal') === 'bold' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                        color: this.getTnCOrNotesFontSettingsByType(type, 'weight', 'normal') === 'bold' ? 'white' : undefined,
                    }}
                    onClick={(event) => this.setFontSettingsByType(type, 'weight')}>
                    B
                </div>
                <div className='HeaderButton'
                    style={{
                        marginLeft: 10,
                        fontStyle: 'italic',
                        backgroundColor: this.getTnCOrNotesFontSettingsByType(type, 'style', 'normal') === 'italic' ? 'rgb(19,142,236)' : 'rgb(230, 230, 230)',
                        color: this.getTnCOrNotesFontSettingsByType(type, 'style', 'normal') === 'italic' ? 'white' : undefined,
                    }}
                    onClick={(event) => this.setFontSettingsByType(type, 'style')}>
                    I
                </div>
                <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateTnCOrNotesFontSettings(type, 'fontSize', 'down')}>
                    A-
                </div>
                <div className='HeaderButton' onClick={(event) => this.updateTnCOrNotesFontSettings(type, 'fontSize', 'up')}>
                    A+
                </div>
            </div>
        </div>
    }

    getWordSpacingSettings(type) {
        return <div
            className="RowDiv HeaderRow"
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
            }}>
            <div className='RowDiv'>
                {this.getSectionHeader('word_spacing', false, 12.5, 4)}
            </div>
            <div className='RowDiv'
                style={{
                    flexDirection: 'row-reverse',
                    marginLeft: 0
                }}>
                <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateTnCOrNotesFontSettings(type, 'wordSpacing', 'reset')}>
                    <img src={Asset.icon.ic_refresh_black} alt="reset" style={{ width: 10, pointerEvents: 'none' }} />
                </div>
                <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateTnCOrNotesFontSettings(type, 'wordSpacing', 'down')}>
                    -
                </div>
                <div className='HeaderButton' style={{ marginLeft: 10 }} onClick={(event) => this.updateTnCOrNotesFontSettings(type, 'wordSpacing', 'up')}>
                    +
                </div>
            </div>
        </div>
    }


    getIsEnableFontSettings(type) {
        let isEnable = false
        let settings = Utility.getTnCOrNotesFontSettingsByType(type)

        if (settings) {
            isEnable = settings?.fontSettings?.enable ?? false
        }
        return isEnable
    }

    setIsEnableFontSettings(type) {
        let settings = Utility.getTnCOrNotesFontSettingsByType(type)
        let isEnable = false
        if (type === EditorInfoType.termsAndCondition) {
            isEnable = settings?.fontSettings?.enable ?? false
            settings.fontSettings.enable = !isEnable
            TemplateSettingsManager.setTermsAndConditionsSettings(settings)
        }
        else if (type === EditorInfoType.notes) {
            isEnable = settings?.fontSettings?.enable ?? false
            settings.fontSettings.enable = !isEnable
            TemplateSettingsManager.setNotesSettings(settings)
        }
        else if (type === EditorInfoType.generatedMessage) {
            isEnable = settings?.fontSettings?.enable ?? false
            settings.fontSettings.enable = !isEnable
            TemplateSettingsManager.setGeneratedMessageSettings(settings)
        }
        this.dataChanged(this.state.data);
    }

    getTnCOrNotesFontSettingsByType(type, fontProperty, defaultValue) {
        let settings = Utility.getTnCOrNotesFontSettingsByType(type)
        let value = defaultValue

        if (settings) {
            value = settings?.fontSettings[fontProperty] ?? defaultValue
        }
        return value
    }

    setFontSettingsByType(type, fontProperty, additionalProperty) {
        let settings = Utility.getTnCOrNotesFontSettingsByType(type)

        if (settings) {
            let value = settings?.fontSettings[fontProperty] ?? undefined

            if(value && value !== null) {
                if (fontProperty === 'style') {
                    if (settings.fontSettings[fontProperty] === 'normal') {
                        settings.fontSettings[fontProperty] = 'italic'
                    }
                    else {
                        settings.fontSettings[fontProperty] = 'normal'
                    }
                }
                else if (fontProperty === 'weight') {
                    if(settings.fontSettings[fontProperty] === 'normal') {
                        settings.fontSettings[fontProperty] = 'bold'
                    }
                    else {
                        settings.fontSettings[fontProperty] = 'normal'
                    }
                }
                else if(fontProperty === 'textAlign') {
                    if(additionalProperty && additionalProperty !== null) {
                        settings.fontSettings[fontProperty] = additionalProperty
                    }
                }

                if (type === EditorInfoType.termsAndCondition) {
                    TemplateSettingsManager.setTermsAndConditionsSettings(settings)
                }
                else if (type === EditorInfoType.notes) {
                    TemplateSettingsManager.setNotesSettings(settings)
                }
                else if (type === EditorInfoType.generatedMessage) {
                    TemplateSettingsManager.setGeneratedMessageSettings(settings)
                }
                this.dataChanged(this.state.data);
            }
        }
    }

    updateTnCOrNotesFontSettings(type, fontProperty, action) {
        let settings = Utility.getTnCOrNotesFontSettingsByType(type)

        if (settings) {
            settings = Utility.updateTnCOrNotesFontSettings(settings, fontProperty, action)

            if (type === EditorInfoType.termsAndCondition) {
                TemplateSettingsManager.setTermsAndConditionsSettings(settings)
            }
            else if (type === EditorInfoType.notes) {
                TemplateSettingsManager.setNotesSettings(settings)
            }
            else if (type === EditorInfoType.generatedMessage) {
                TemplateSettingsManager.setGeneratedMessageSettings(settings)
            }
            this.dataChanged(this.state.data);
        }
        
    }

    renderPageCountSettings(type) {
        const types = [EditorInfoType.repeatableHeaderFooter]

        if (types.includes(type) && TemplateSettingsManager.getRepeatableHeaderFooterIsVisible()) {
            return <>
                {this.getPageCountSettings(type)}
            </>
        }
        return undefined
    }

    getPageCountSettings() {
        const isEnable = TemplateSettingsManager.getPageCountSettings()?.enable ?? false
        return <div
            className="RowDiv HeaderRow"
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
            }}>
            <div className='RowDiv'
                style={{ marginRight: 4, width: 'auto', alignSelf: 'center' }} >
                <input type="checkbox"
                    checked={isEnable}
                    onClick={() => this.setIsEnablePageCountSettings()}
                    style={{ margin: 0 }}
                />
            </div>
            <div className='RowDiv'>
                {this.getSectionHeader('page_count_settings', false, 12.5, 4)}
            </div>
            <div className='RowDiv'
                style={{
                    flexDirection: 'row-reverse',
                    marginLeft: 0
                }}>
                <div className="HeaderButton"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                        this.props.onEditButtonCallbackClicked(EditorInfoType.pageCountSettings)
                    }}>
                    <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
                </div>
            </div>
        </div>
    }

    setIsEnablePageCountSettings() {
        let settings = TemplateSettingsManager.getPageCountSettings()
        const isEnable = settings?.enable ?? false
        settings.enable = !isEnable
        TemplateSettingsManager.setPageCountSettings(settings)

        this.dataChanged(this.state.data);
    }

    getPaperOrientationListPicker() {
        var paperOrientation = ['landscape', 'portrait']
        return (
            <div className="ListPickerHolderStyle">
                {
                    !Utility.getIsReceiptPaperSize() &&
                    <div className="RowDiv HeaderRow"
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                        {this.getSectionHeader('paper_margin')}
                        <div
                            onClick={() => {
                                this.props.onEditButtonCallbackClicked(EditorInfoType.paperMargin)
                            }}
                            className="HeaderButton"
                            style={{ marginRight: 5 }}>
                            <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
                        </div>
                    </div>
                    
                }
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('paper_orientation')}
                </div>
                <div className="RowDiv">
                    <select
                        style={this.getPickerStyle()}
                        onChange={(event) => this.paperOrientationChanged(event)}
                        className="TextField ListPicker">
                        {paperOrientation.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={this.getPaperOrientation() === key.toLowerCase()}>
                                    {getLocalisedText(key)}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    getPaperOrientation() {
        var orientation = TemplateSettingsManager.defaultTemplateSettings.templateInfo.orientation

        if (orientation === undefined) {
            return 'portrait'
        }

        return orientation
    }

    paperOrientationChanged(event) {
        var orientation = event.target.value
        TemplateSettingsManager.updateTemplateOrientation(orientation)
        this.dataChanged(this.state.data)
    }

    getTransporterDetailsPicker() {
        var transporterTypeList = [
            { label: 'bill_to', value: AddressType.billTo },
            { label: 'ship_to', value: AddressType.shipTo },
            { label: 'ship_from', value: AddressType.shipFrom }
        ]

        return (
            <div className="ListPickerHolderStyle">
                <div className="RowDiv HeaderRow"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    }}>
                    {this.getSectionHeader('transporter_detail')}
                </div>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.transporterDetailsChanged(event)}
                        className="TextField ListPicker"
                        style={this.getPickerStyle()}
                    >
                        {transporterTypeList.map((element) => {
                            return <>
                                <option
                                    value={element.value}
                                    selected={this.getTransporterDetailsType() === element.value}>
                                    {getLocalisedText(element.label)}
                                </option>
                            </>
                        })}
                    </select>
                    {this.getListPickerArrow()}
                </div>
            </div>
        );
    }

    getTransporterDetailsType() {
        let type = AddressType.billTo
        var transporterDetails = TemplateSettingsManager.getTransporterDetails()

        if (transporterDetails && transporterDetails !== null) {
            type = transporterDetails.transporterType ?? AddressType.billTo
        }

        return type
    }

    transporterDetailsChanged(event) {
        var transporterDetails = TemplateSettingsManager.getTransporterDetails()
        transporterDetails.transporterType = event.target.value
        TemplateSettingsManager.updateTransporterDetails(transporterDetails)
        this.dataChanged(this.state.data)
    }

    getCRMTableSettings() {
        const crmTableOption = [{ key: 'show_zero', label: getLocalisedText('show_zero') },
                                { key: 'show_total', label: getLocalisedText('show_total') }]
        let crmTableSettings = TemplateSettingsManager.getCRMTableSettings()

        if(crmTableSettings === undefined) {
            crmTableSettings = {
                optionalGroupTotal: 'show_zero'
            }
        }
        
        return (
            <div className="RowDiv">
                <select
                    style={this.getPickerStyle()}
                    onChange={(event) => this.crmTableSettingsChanged(event)}
                    className="TextField ListPicker">
                    {crmTableOption.map((option) => {
                        return <>
                            <option
                                value={option.key}
                                selected={crmTableSettings?.optionalGroupTotal === option.key.toLowerCase()}>
                                {option.label}
                            </option>
                        </>
                    })}
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    crmTableSettingsChanged(event) {
        let crmTableSettings = TemplateSettingsManager.getCRMTableSettings()

        if (crmTableSettings === undefined) {
            crmTableSettings = {
                optionalGroupTotal: 'show_zero'
            }
        }

        crmTableSettings.optionalGroupTotal = event.target.value
        TemplateSettingsManager.updateCRMTableSettings(crmTableSettings)

        this.dataChanged(this.state.data)
    }

    renderDraftWatermark() {
        return <div
            className='RowDiv HeaderRow'
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
            }}>
            {this.getSectionHeader('draft_watermark')}
            <div
                onClick={() => {
                    this.props.onEditButtonCallbackClicked(EditorInfoType.draftWatermark)
                }}
                className="HeaderButton"
                style={{ marginRight: 5 }}>
                <img src={Asset.icon.ic_edit} alt="edit" style={{ width: 10 }} />
            </div>
        </div>
    }

    getIsNotAllowEditCompanyAddressTemplate() { 
        let templateIdList = [51, 52, 53, 54, 61, 62, 63] //51: Tax Inv, 52: ST , 53: SI, 54: SR, 61, 63: PO, 62: SO

        return !getIsPurchaseRequisitionTemplate(this.state.data.templateId) &&
            !getIsServiceRequisitionTemplate(this.state.data.templateId) &&
            !getIsServiceOrderTemplate(this.state.data.templateId) &&
            !templateIdList.includes(this.state.data.templateId)
    }

}

export const DateType = {
    START: 0,
    END: 1,
    ADDITIONAL: 2,
}
