import ComponentManager from "../Manager/ComponentManager"
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate, getIsFulfillment, getIsGoodsReceipt, getIsMakePayment, getIsReceivePayment } from "../Utilities/Utility"
import NewDataParser from "./NewDataParser"

export default class LineItemsParser {
    static getProductLineItem(dataFromAPI, isGeneratedData, dateFormat, productLineItem = undefined) {
        var productTrackingInfo = dataFromAPI.productTrackingInfo
        var advancedTracking = undefined

        var items =  dataFromAPI.lineItems
        if(productLineItem !== undefined) {
            items = productLineItem
        }

        var lineItems = items.map((product, index) => {
            let modifiedProduct = product
            if(modifiedProduct.product !== undefined && modifiedProduct.product !== null) {
                if (modifiedProduct.product.hsnOrSacCode !== null && modifiedProduct.product.hsnOrSacCode !== undefined && !isGeneratedData) {
                    modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + modifiedProduct.product.hsnOrSacCode
                    modifiedProduct.hsnOrSacCode = modifiedProduct.product.hsnOrSacCode
                }

                if(product.name === undefined || product.name === null) {
                    product.name = modifiedProduct.product.name
                }
            }
            if (product.hsnOrSacCode !== null && product.hsnOrSacCode !== undefined && !isGeneratedData) {
                modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + product.hsnOrSacCode
                modifiedProduct.hsnOrSacCode = product.hsnOrSacCode
            }
            modifiedProduct.lineNumber = (product.lineNumber !== undefined && product.lineNumber !== null) ? product.lineNumber : index + 1
            modifiedProduct.code = product.code
            if (product.tax !== null && product.quantity !== null && product.unitPrice !== null) {
                let total_amount = product.quantity * product.unitPrice
                let total_discount = product.discount
                if (product.discountInPercent && product.discountInPercent === true && !isGeneratedData) {
                    if (total_discount !== undefined && total_discount !== null) {
                        if (parseFloat(total_discount) > 0) {
                            modifiedProduct.discountPercent = modifiedProduct.discount
                            modifiedProduct.isDiscountInPercent = product.discountInPercent
                        }
                    }
                    if ((product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax) {
                        total_discount = (ComponentManager.getTaxInclusiveSubtotalItemPrice(product) * product.discount) / 100
                    } else {
                        total_discount = (total_amount * product.discount) / 100
                    }
                    if (modifiedProduct.isDiscountInPercent === undefined) {
                        modifiedProduct.discount = Number(product.discount)
                    }
                }
                modifiedProduct.priceInclusiveOfTax = (product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax
                if (modifiedProduct.isDiscountInPercent === undefined) {
                    modifiedProduct.discount = Number(product.discount)
                }
                modifiedProduct.tax = Number(product.tax)
                if (product.uom !== undefined) {
                    modifiedProduct.uom = product.uom
                }
                else {
                    modifiedProduct.uom = 'N/A'
                }
            }
            else { //expense invoice
                if(modifiedProduct.tax !== undefined) {
                    let taxAmount = Number(modifiedProduct.tax)
                    if (taxAmount !== null && taxAmount !== undefined) {
                        modifiedProduct.taxAmount = taxAmount
                    }
                    else {
                        modifiedProduct.tax = 0
                        if (modifiedProduct.taxInfo !== null && modifiedProduct.taxInfo !== undefined) {
                            modifiedProduct.tax = modifiedProduct.taxInfo.percent ? modifiedProduct.taxInfo.percent : 0
                        }
                        modifiedProduct.taxAmount = Number(modifiedProduct.amount) * (Number(modifiedProduct.tax) / 100)
                    }
                }

            }

            modifiedProduct.bomComponentGroups = undefined
            modifiedProduct.bomAddCostConfiguration = undefined
            modifiedProduct.bomOperationsConfiguration = undefined

            modifiedProduct.bomProductsConfiguration = undefined

            modifiedProduct.supplierPartDescription = product.supplierPartDescription;
            modifiedProduct.supplierPartName = product.supplierPartName;
            modifiedProduct.supplierPartNumber = product.supplierPartNumber;


            if (modifiedProduct.bomComponentGroupDetails && modifiedProduct.bomComponentGroupDetails !== null) {
                if (modifiedProduct.bomComponentGroupDetails.bomComponentGroups &&
                    modifiedProduct.bomComponentGroupDetails.bomComponentGroups !== null) {
                    modifiedProduct.bomComponentGroups = modifiedProduct.bomComponentGroupDetails.bomComponentGroups
                }
                if (modifiedProduct.bomComponentGroupDetails.bomAddCostConfiguration &&
                    modifiedProduct.bomComponentGroupDetails.bomAddCostConfiguration !== null) {
                    modifiedProduct.bomAddCostConfiguration = modifiedProduct.bomComponentGroupDetails.bomAddCostConfiguration
                }
                if (modifiedProduct.bomComponentGroupDetails.bomOperationsConfiguration &&
                    modifiedProduct.bomComponentGroupDetails.bomOperationsConfiguration !== null) {
                    modifiedProduct.bomOperationsConfiguration = modifiedProduct.bomComponentGroupDetails.bomOperationsConfiguration
                }
            }

            if (modifiedProduct.product !== undefined && modifiedProduct.product !== null) {
                if (modifiedProduct.product.images !== undefined && modifiedProduct.product.images !== null) {
                    if (modifiedProduct.product.images.length > 0) {
                        modifiedProduct.image = modifiedProduct.product.images[0]
                    }
                }
                if (modifiedProduct.product.advancedTracking !== undefined && modifiedProduct.product.advancedTracking !== null) {
                    advancedTracking = modifiedProduct.product.advancedTracking
                }
                if (modifiedProduct.product.bomProductsConfiguration !== undefined && modifiedProduct.product.bomProductsConfiguration !== null) {
                    modifiedProduct.bomProductsConfiguration = modifiedProduct.product.bomProductsConfiguration
                }
            }
            var referenceCode = undefined
            if (modifiedProduct.purchaseInvoiceItemCode !== undefined && modifiedProduct.purchaseInvoiceItemCode !== null) {
                referenceCode = modifiedProduct.purchaseInvoiceItemCode
            }
            else if (modifiedProduct.salesInvoiceItemCode !== undefined && modifiedProduct.salesInvoiceItemCode !== null) {
                referenceCode = modifiedProduct.salesInvoiceItemCode
            }

            if (modifiedProduct.documentUOMSchemaDefinitionPrint !== undefined && modifiedProduct.documentUOMSchemaDefinitionPrint !== null) {
                if (modifiedProduct.documentUOMSchemaDefinitionPrint.sourceConversionValue !== undefined &&
                    modifiedProduct.documentUOMSchemaDefinitionPrint.sourceUOMName !== undefined) {
                    modifiedProduct.alterUOM = '(' + modifiedProduct.documentUOMSchemaDefinitionPrint.sourceConversionValue + " " + modifiedProduct.documentUOMSchemaDefinitionPrint.sourceUOMName + ')'
                }
            }

            if(referenceCode !== undefined) {
                if (productTrackingInfo !== undefined && productTrackingInfo !== null) {
                    if (productTrackingInfo.length > 0) {
                        var serialBatch = []
                        var serialNumber = []
                        var batchNumber = []
                        var manufacturingDate = []
                        var expiryDate = []

                        modifiedProduct.serialBatchCustomFieldType = 'SERIAL_BATCH'
                        let serialBatchCustomFields = {}
                        let serialBatchList = []

                        productTrackingInfo.forEach(trackingItem => {
                            if (trackingItem.documentItemCode === referenceCode) {
                                if(advancedTracking !== undefined) {
                                    if (advancedTracking === 'BATCH') {
                                        batchNumber.push(trackingItem.serialBatchNumber)
                                    }
                                    else if (advancedTracking === 'SERIAL') {
                                        serialNumber.push(trackingItem.serialBatchNumber)
                                    }
                                }

                                if (trackingItem.serialBatchNumber && trackingItem.serialBatchNumber !== null) {
                                    serialBatchList.push(trackingItem.serialBatchNumber)
                                }
                                if (trackingItem.manufacturingDate !== null && trackingItem.manufacturingDate !== undefined && trackingItem.manufacturingDate !== "") {
                                    manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.manufacturingDate), dateFormat))
                                }
                                if (trackingItem.expiryDate !== null && trackingItem.expiryDate !== undefined && trackingItem.expiryDate !== "" && advancedTracking === 'BATCH') {
                                    serialBatch.push(trackingItem.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat) + ')')
                                    expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat))
                                }
                                else {
                                    serialBatch.push(trackingItem.serialBatchNumber)
                                }

                                if (trackingItem.serialBatchNumber && trackingItem.serialBatchNumber !== null) {
                                    serialBatchCustomFields[trackingItem.serialBatchNumber] = trackingItem.customField
                                }
                            }
                        });

                        modifiedProduct.serialBatch = ''
                        modifiedProduct.serialNumber = ''
                        modifiedProduct.batchNumber = ''
                        modifiedProduct.manufacturingDate = ''
                        modifiedProduct.expiryDate = ''

                        if (serialBatch.length > 0) {
                            modifiedProduct.serialBatch = serialBatch.join('\n')
                        }
                        if (serialNumber.length > 0) {
                            modifiedProduct.serialNumber = serialNumber.join('\n')
                        }
                        if (batchNumber.length > 0) {
                            modifiedProduct.batchNumber = batchNumber.join('\n')
                        }
                        if (manufacturingDate.length > 0) {
                            modifiedProduct.manufacturingDate = manufacturingDate.join('\n')
                        }
                        if (expiryDate.length > 0) {
                            modifiedProduct.expiryDate = expiryDate.join('\n')
                        }

                        modifiedProduct.serialBatchList = serialBatchList
                        modifiedProduct.serialBatchCustomFields = serialBatchCustomFields
                    }
                }
            }
            return modifiedProduct
        })
        return lineItems
    }

    static getAccountItem(data) {
        if (data.lineItems === undefined || data.lineItems.lineItems === null) {
            return []
        }

        var accountLineItems = []
        data.lineItems.forEach((element, index) => {
            var accountLineItem = element
            accountLineItem.lineNumber = index + 1
            accountLineItem.name = (element.name !== null) ? element.name : ''
            accountLineItem.description = (element.description !== null) ? element.description : ''
            accountLineItem.amount = (element.amount !== null) ? element.amount : 0.00

            if (element.tax !== null && element.tax !== undefined) {
                accountLineItem.taxAmount = element.tax
            }
            else {
                accountLineItem.tax = 0
                if (element.taxInfo !== null && element.taxInfo !== undefined) {
                    accountLineItem.tax = element.taxInfo.percent ? element.taxInfo.percent : 0
                }
                accountLineItem.taxAmount = Number(accountLineItem.amount) * (Number(accountLineItem.tax) / 100)
            }

            var priceInclusiveOfTax = false
            if (data.priceInclusiveOfTax !== undefined && data.priceInclusiveOfTax !== null) {
                if(data.priceInclusiveOfTax) {
                    priceInclusiveOfTax = data.priceInclusiveOfTax
                }
            }

            accountLineItem.paymentAmount = Number(accountLineItem.amount) + Number(accountLineItem.taxAmount)
            if (priceInclusiveOfTax) {
                accountLineItem.paymentAmount = Number(accountLineItem.amount)
            }
            accountLineItem.customFields = element.customFields
            accountLineItems.push(accountLineItem)
        });

        return accountLineItems
    }

    static getPaymentItem(data, type) {
        if (data.lineItems === undefined || data.lineItems.lineItems === null) {
            return []
        }

        var country = ''
        if (data.tenantInfo !== undefined && data.tenantInfo !== null) {
            if (data.tenantInfo.country !== undefined && data.tenantInfo.country !== null) {
                country = data.tenantInfo.country
            }
        }

        var paymentLineItems = []
        data.lineItems.forEach((element, index) => {
            var paymentLineItem = element
            paymentLineItem.lineNumber = index + 1
            paymentLineItem.snNumber = data.refNumber
            var parentDocumentTotalAmount = 0
            var parentDocumentDueAmount = 0
            var parentDocumentCurrency = undefined
            if (data.parentDocument !== undefined && data.parentDocument !== null) {
                if (NewDataParser.getDefaultValue(data.parentDocument.totalAmount) !== '') {
                    parentDocumentTotalAmount = NewDataParser.getDefaultValue(data.parentDocument.totalAmount)
                }
                if (NewDataParser.getDefaultValue(data.parentDocument.dueAmount) !== '') {
                    parentDocumentDueAmount = NewDataParser.getDefaultValue(data.parentDocument.dueAmount)
                }
                parentDocumentCurrency = data.parentDocument.currency
            }
            if (getIsMakePayment(type)) {
                paymentLineItem.billNumber = NewDataParser.getDefaultValue(element.documentSequenceCode)
                paymentLineItem.totalBillAmount = parentDocumentTotalAmount
                paymentLineItem.currentBalance = parentDocumentDueAmount
            }
            if (getIsReceivePayment(type)) {
                paymentLineItem.invoiceNumber = NewDataParser.getDefaultValue(element.documentSequenceCode)
                paymentLineItem.totalInvoiceAmount = parentDocumentTotalAmount
                paymentLineItem.currentDueAmount = parentDocumentDueAmount
            }
            paymentLineItem.parentDocumentCurrency = parentDocumentCurrency
            paymentLineItem.paymentMade = element.amount
            paymentLineItem.paymentMedium = Utility.getPaymentMethod(NewDataParser.getDefaultValue(data.paymentType), country)
            paymentLineItems.push(paymentLineItem)
        });
        return paymentLineItems
    }

    static getFulfillmentItem(data, dateFormat) {
        if (data.fulfillment === undefined || data.fulfillment === null) {
            return []
        }

        if (data.fulfillment.fulfillmentItems === undefined || data.fulfillment.fulfillmentItems === null) {
            return []
        }

        var fulfillmentItems = []
        data.fulfillment.fulfillmentItems.forEach((element, index) => {
            var fulfillmentItem = {}
            fulfillmentItem.lineNumber = index + 1
            fulfillmentItem.code = NewDataParser.getDefaultValue(element.documentSequenceCode)
            fulfillmentItem.name = NewDataParser.getDefaultValue(element.productName)
            fulfillmentItem.description = NewDataParser.getDefaultValue(element.productDescription)
            fulfillmentItem.uom = NewDataParser.getDefaultValue(element.uomName) !== '' ? NewDataParser.getDefaultValue(element.uomName) : 'N/A'
            fulfillmentItem.committedQuantity = NewDataParser.getDefaultValue(element.uomFulfilledQuantity)
            if (element.uomQuantityRequired !== undefined && element.uomQuantityRequired !== null) {
                fulfillmentItem.requiredQuantity = element.uomQuantityRequired
            }
            else {
                var requiredQuantity = 0
                if (element.uomFulfilledQuantity !== undefined && element.uomFulfilledQuantity !== null) {
                    requiredQuantity = requiredQuantity + Utility.quantityRoundingOffToPrecision(parseFloat(element.uomFulfilledQuantity))
                }
                if (element.uomPendingQuantity !== undefined && element.uomPendingQuantity !== null) {
                    requiredQuantity = requiredQuantity + Utility.quantityRoundingOffToPrecision(parseFloat(element.uomPendingQuantity))
                }
                fulfillmentItem.requiredQuantity = requiredQuantity
            }
            // fulfillmentItem.requiredQuantity = element.uomQuantityRequired !== null ? NewDataParser.getDefaultValue(element.uomQuantityRequired) : NewDataParser.getDefaultValue(element.uomFulfilledQuantity)
            fulfillmentItem.warehouseCode = this.getWareHouseCode(element, data.type)
            fulfillmentItem.warehouseName = this.getWareHouseName(element, data.type)
            fulfillmentItem.customFields = element.customField
            if (element.trackingInformation !== undefined && element.trackingInformation !== null) {
                if (element.trackingInformation.length > 0) {
                    var serialBatch = []
                    var serialNumber = []
                    var batchNumber = []
                    var manufacturingDate = []
                    var expiryDate = []

                    fulfillmentItem.serialBatchCustomFieldType = 'SERIAL_BATCH'
                    let serialBatchCustomFields = {}
                    let serialBatchList = []

                    element.trackingInformation.forEach(item => {
                        var serialBatchNumber = item.serialBatchNumber
                        if (item.expiryDate !== null && item.expiryDate !== undefined && element.advancedTrackingType === 'BATCH') {
                            serialBatchNumber = item.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat) + ')'
                            expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat))
                        }
                        serialBatch.push(serialBatchNumber)
                        if (item.serialBatchNumber && item.serialBatchNumber !== null) {
                            serialBatchList.push(item.serialBatchNumber)
                        }
                        if (element.advancedTrackingType === 'BATCH') {
                            batchNumber.push(item.serialBatchNumber)
                        }
                        else if (element.advancedTrackingType === 'SERIAL') {
                            serialNumber.push(item.serialBatchNumber)
                        }

                        if (item.manufacturingDate !== null && item.manufacturingDate !== undefined && item.manufacturingDate !== "") {
                            manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(item.manufacturingDate), dateFormat))
                        }

                        if (item.serialBatchNumber && item.serialBatchNumber !== null) {
                            serialBatchCustomFields[item.serialBatchNumber] = item.customField
                        }
                    });

                    fulfillmentItem.advancedTrackingType = element.advancedTrackingType

                    if (serialBatch.length > 0) {
                        fulfillmentItem.serialBatch = serialBatch.join('\n')
                    }
                    if (serialNumber.length > 0) {
                        fulfillmentItem.serialNumber = serialNumber.join('\n')
                    }
                    if (batchNumber.length > 0) {
                        fulfillmentItem.batchNumber = batchNumber.join('\n')
                    }
                    if (manufacturingDate.length > 0) {
                        fulfillmentItem.manufacturingDate = manufacturingDate.join('\n')
                    }
                    if (expiryDate.length > 0) {
                        fulfillmentItem.expiryDate = expiryDate.join('\n')
                    }

                    fulfillmentItem.serialBatchList = serialBatchList
                    fulfillmentItem.serialBatchCustomFields = serialBatchCustomFields
                }
            }
            fulfillmentItems.push(fulfillmentItem)
        });

        return fulfillmentItems
    }

    static getGoodsReceiptItem(data, dateFormat) {
        if (data.goodsReceipt === undefined || data.goodsReceipt === null) {
            return []
        }

        if (data.goodsReceipt.items === undefined || data.goodsReceipt.items === null) {
            return []
        }

        var goodsReceiptItems = []
        data.goodsReceipt.items.forEach((element, index) => {
            var goodsReceiptItem = {}
            goodsReceiptItem.lineNumber = index + 1
            goodsReceiptItem.code = NewDataParser.getDefaultValue(element.documentSequenceCode)
            goodsReceiptItem.name = NewDataParser.getDefaultValue(element.productName)
            if (data.parentDocumentData !== undefined && data.parentDocumentData !== null) {
                goodsReceiptItem.orderNo = NewDataParser.getDefaultValue(data.parentDocumentData.documentCode)
            }
            goodsReceiptItem.description = NewDataParser.getDefaultValue(element.productDescription)
            goodsReceiptItem.warehouseCode = this.getWareHouseCode(element,data.type)
            goodsReceiptItem.warehouseName = this.getWareHouseName(element,data.type)
            goodsReceiptItem.uom = NewDataParser.getDefaultValue(element.uomName) !== '' ? NewDataParser.getDefaultValue(element.uomName) : 'N/A'
            goodsReceiptItem.receivedQuantity = NewDataParser.getDefaultValue(element.uomReceiptQuantity)
            if (element.uomQuantityRequired !== undefined && element.uomQuantityRequired !== null) {
                goodsReceiptItem.requiredQuantity = element.uomQuantityRequired
            }
            else {
                var requiredQuantity = 0
                if (element.uomReceiptQuantity !== undefined && element.uomReceiptQuantity !== null) {
                    requiredQuantity = requiredQuantity +  Utility.quantityRoundingOffToPrecision(parseFloat(element.uomReceiptQuantity))
                }
                if (element.uomPendingQuantity !== undefined && element.uomPendingQuantity !== null) {
                    requiredQuantity = requiredQuantity + Utility.quantityRoundingOffToPrecision(parseFloat(element.uomPendingQuantity))
                }
                goodsReceiptItem.requiredQuantity = requiredQuantity
            }

            goodsReceiptItem.customFields = element.customField
            if (element.advancedTrackingMetaData !== undefined && element.advancedTrackingMetaData !== null) {
                if (element.advancedTrackingMetaData.length > 0) {
                    var serialBatch = []
                    var serialNumber = []
                    var batchNumber = []
                    var manufacturingDate = []
                    var expiryDate = []

                    goodsReceiptItem.serialBatchCustomFieldType = 'SERIAL_BATCH'
                    let serialBatchCustomFields = {}
                    let serialBatchList = []

                    element.advancedTrackingMetaData.forEach(item => {
                        var serialBatchNumber = item.serialBatchNumber
                        if (item.expiryDate !== null && item.expiryDate !== undefined && element.advancedTrackingType === 'BATCH') {
                            serialBatchNumber = item.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat) + ')'
                            expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat))
                        }
                        serialBatch.push(serialBatchNumber)
                        if (item.serialBatchNumber && item.serialBatchNumber !== null) {
                            serialBatchList.push(item.serialBatchNumber)
                        }
                        if (element.advancedTrackingType === 'BATCH') {
                            batchNumber.push(item.serialBatchNumber)
                        }
                        else if (element.advancedTrackingType === 'SERIAL') {
                            serialNumber.push(item.serialBatchNumber)
                        }

                        if (item.manufacturingDate !== null && item.manufacturingDate !== undefined && item.manufacturingDate !== "") {
                            manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(item.manufacturingDate), dateFormat))
                        }

                        if (item.serialBatchNumber && item.serialBatchNumber !== null) {
                            serialBatchCustomFields[item.serialBatchNumber] = item.customField
                        }
                    });

                    goodsReceiptItem.advancedTrackingType = element.advancedTrackingType

                    if (serialBatch.length > 0) {
                        goodsReceiptItem.serialBatch = serialBatch.join('\n')
                    }
                    if (serialNumber.length > 0) {
                        goodsReceiptItem.serialNumber = serialNumber.join('\n')
                    }
                    if (batchNumber.length > 0) {
                        goodsReceiptItem.batchNumber = batchNumber.join('\n')
                    }
                    if (manufacturingDate.length > 0) {
                        goodsReceiptItem.manufacturingDate = manufacturingDate.join('\n')
                    }
                    if (expiryDate.length > 0) {
                        goodsReceiptItem.expiryDate = expiryDate.join('\n')
                    }

                    goodsReceiptItem.serialBatchList = serialBatchList
                    goodsReceiptItem.serialBatchCustomFields = serialBatchCustomFields
                }
            }
            // populate goods receipt note template data
            LineItemsParser.populateGoodsReceiptNoteTemplateDate(goodsReceiptItem, element, data, dateFormat)

            goodsReceiptItems.push(goodsReceiptItem)
        });

        return goodsReceiptItems
    }

    static populateGoodsReceiptNoteTemplateDate(goodsReceiptItem, element, data, dateFormat) {
        let shortExcessRejectedQty = 0
        if (element.rejectedQty !== undefined && element.rejectedQty !== null) {
            shortExcessRejectedQty = element.rejectedQty
        }
        else if (!isNaN(parseFloat(goodsReceiptItem.requiredQuantity)) && !isNaN(parseFloat(goodsReceiptItem.receivedQuantity))) {
            shortExcessRejectedQty = Math.abs(parseFloat(goodsReceiptItem.requiredQuantity) - parseFloat(goodsReceiptItem.receivedQuantity))
        }

        goodsReceiptItem.shortExcessRejectedQty = shortExcessRejectedQty
        goodsReceiptItem.acceptedQty = element.acceptedQty || element.uomReceiptQuantity

        const parentDocument = data.parentDocumentData
        if (parentDocument !== undefined && parentDocument !== null) {
            goodsReceiptItem.parentDocumentCode = parentDocument.documentCode
            goodsReceiptItem.parentDocumentDate = getConvertedDate(convertDateFromERPFormatToDocFormat(parentDocument.documentDate), dateFormat)

            if (Array.isArray(parentDocument.productLineItem)) {
                const parentDocumentLineItem = parentDocument.productLineItem.find((lineItem) => {
                    return lineItem.purchaseInvoiceItemCode === element.documentItemCode || lineItem.purchaseOrderItemCode === element.documentItemCode;
                });

                if (parentDocumentLineItem) {
                    goodsReceiptItem.unitPrice = parentDocumentLineItem.unitPrice

                    goodsReceiptItem.basicAmt = 0
                    if (!Utility.isEmpty(goodsReceiptItem.acceptedQty) && !Utility.isEmpty(goodsReceiptItem.unitPrice)) {
                        goodsReceiptItem.basicAmt = goodsReceiptItem.acceptedQty * goodsReceiptItem.unitPrice
                    }

                    const taxBreakdown = [];
                    if (data?.tenantInfo?.gstRegistered) {
                        if (!Utility.isEmpty(parentDocumentLineItem.cgstRate)) {
                            taxBreakdown.push({
                                name: "cgst",
                                rate: parentDocumentLineItem.cgstRate,
                                amount: ((parentDocumentLineItem.cgstRate * goodsReceiptItem.basicAmt) / 100)
                            });
                        }
                        if (!Utility.isEmpty(parentDocumentLineItem.sgstRate)) {
                            taxBreakdown.push({
                                name: "sgst",
                                rate: parentDocumentLineItem.sgstRate,
                                amount: ((parentDocumentLineItem.sgstRate * goodsReceiptItem.basicAmt) / 100)
                            });
                        }
                        if (!Utility.isEmpty(parentDocumentLineItem.igstRate)) {
                            taxBreakdown.push({
                                name: "igst",
                                rate: parentDocumentLineItem.igstRate,
                                amount: ((parentDocumentLineItem.igstRate * goodsReceiptItem.basicAmt) / 100)
                            });
                        }
                    } else {
                        if (!Utility.isEmpty(parentDocumentLineItem.taxDto)) {
                            taxBreakdown.push({
                                name: "gst",
                                rate: parentDocumentLineItem.taxDto.percent,
                                amount: ((parentDocumentLineItem.taxDto.percent * goodsReceiptItem.basicAmt) / 100)
                            });
                        }
                    }

                    goodsReceiptItem.taxBreakdown = taxBreakdown
                }
            }
        }
    }

    static getWareHouseCode(item, type) {
        let warehouseInventoryData = item.warehouseInventoryData;

        if (item.advancedTrackingType === 'SERIAL' || item.advancedTrackingType === 'BATCH') {
            if (getIsFulfillment(type)) {
                warehouseInventoryData = item.trackingInformation;
                
            }

            if (getIsGoodsReceipt(type)) {
                warehouseInventoryData = item.advancedTrackingMetaData;
            }
        }

        if (Array.isArray(warehouseInventoryData)) {
            return warehouseInventoryData.map(wData => wData["warehouseCode"]).join(', ')
        } else {
            if (item.warehouseCode !== undefined && item.warehouseCode !== null) {
                return item.warehouseCode
            }
        }

        return ''
    }

    static getWareHouseName(item, type) {
        let warehouseInventoryData = item.warehouseInventoryData;

        if (item.advancedTrackingType === 'SERIAL' || item.advancedTrackingType === 'BATCH') {
            if (getIsFulfillment(type)) {
                warehouseInventoryData = item.trackingInformation;
                
            }

            if (getIsGoodsReceipt(type)) {
                warehouseInventoryData = item.advancedTrackingMetaData;
            }
        }

        if (Array.isArray(warehouseInventoryData)) {
            return warehouseInventoryData.map(wData => wData["warehouseName"]).join(', ')
        }

        return ''
    }

    static getJournalEntryItem(data, dateFormat) {
        if (data.journalEntry === undefined || data.journalEntry === null) {
            return []
        }

        if (data.journalEntry.lineItems === undefined || data.journalEntry.lineItems === null) {
            return []
        }

        var journalEntryNumber = ''
        var journalEntryDate = ''

        if (data.journalEntry.jeCode !== undefined && data.journalEntry.jeCode !== null) {
            journalEntryNumber = data.journalEntry.jeCode
        }

        if (data.journalEntry.jeDate !== undefined && data.journalEntry.jeDate !== null) {
            journalEntryDate = data.journalEntry.jeDate
        }

        // ZEN-10497 - JE line item order changes when clicked on print and design
        // Ascending sort
        var sortedJournalEntryList = data.journalEntry.lineItems.sort((a, b) => {
            return a.lineNumber - b.lineNumber
        })

        // var journalEntryList_debit = []
        // var journalEntryList_credit = []

        // data.journalEntry.lineItems.forEach(element => {
        //     if (element.cdType === 'CREDIT') {
        //         journalEntryList_credit.push(element)
        //     }
        //     else if (element.cdType === 'DEBIT') {
        //         journalEntryList_debit.push(element)
        //     }
        // });

        // if (journalEntryList_credit.length > 0 || journalEntryList_debit.length > 0) {
        //     sortedJournalEntryList = []
        //     if (journalEntryList_debit.length > 0) {
        //         journalEntryList_debit.forEach(element => {
        //             sortedJournalEntryList.push(element)
        //         });
        //     }
        //     if (journalEntryList_credit.length > 0) {
        //         journalEntryList_credit.forEach(element => {
        //             sortedJournalEntryList.push(element)
        //         });
        //     }
        // }

        var journalEntryItems = []
        sortedJournalEntryList.forEach((element, index) => {
            var journalEntryItem = {}
            journalEntryItem.lineNumber = index + 1
            journalEntryItem.date = journalEntryDate
            journalEntryItem.journalEntryNumber = journalEntryNumber
            journalEntryItem.code = NewDataParser.getDefaultValue(element.code)
            journalEntryItem.accountName = NewDataParser.getDefaultValue(element.accountName)
            journalEntryItem.accountCode = NewDataParser.getDefaultValue(element.accountCode)
            journalEntryItem.contactCode = NewDataParser.getDefaultValue(element.contactCode)
            journalEntryItem.contactName = NewDataParser.getDefaultValue(element.contactName)
            journalEntryItem.description = NewDataParser.getDefaultValue(element.description)
            journalEntryItem.credit = 0
            journalEntryItem.debit = 0
            journalEntryItem.customField = element.customField
            if (element.cdType !== undefined && element.cdType !== null
                & element.amount !== undefined && element.amount !== null) {
                if (element.cdType === 'CREDIT') {
                    journalEntryItem.credit = element.amount
                }
                else if (element.cdType === 'DEBIT') {
                    journalEntryItem.debit = element.amount
                }
            }

            journalEntryItems.push(journalEntryItem)
        });


        return journalEntryItems
    }

    static getPickItem(data) {
        if (this.getPayloadData(data) === undefined) {
            return []
        }

        if (this.getPayloadData(data).items === undefined || this.getPayloadData(data).items === null) {
            return []
        }

        var pickingItems = []
        this.getPayloadData(data).items.forEach((element, index) => {
            var pickingItem = {}
            pickingItem.lineNumber = index + 1
            pickingItem.invoiceOrQuotation = element.orderNo
            pickingItem.code = element.productCode
            if (element.productDocumentSequenceCode && element.productDocumentSequenceCode !== null) {
                pickingItem.code = element.productDocumentSequenceCode 
            }
            pickingItem.name = NewDataParser.getDefaultValue(element.name)
            pickingItem.barcode = NewDataParser.getDefaultValue(element.barcode)
            pickingItem.quantityToPick = element.lq
            pickingItem.quantityForOrder = element.quantity
            pickingItem.picked = ''
            if (element.isPicked !== undefined && element.isPicked !== null) {
                pickingItem.picked = element.isPicked.toLowerCase() === 'yes' ? true : false
            }
            pickingItem.warehouseName = NewDataParser.getDefaultValue(element.warehouseName)
            pickingItem.warehouseCode = NewDataParser.getDefaultValue(element.warehouseCode)

            pickingItems.push(pickingItem)
        });

        return pickingItems
    }

    static getPackItem(data, payloadIndex) {
        const index = payloadIndex ?? 0

        if (this.getPayloadData(data, index) === undefined) {
            return []
        }

        if (this.getPayloadData(data, index).items === undefined || this.getPayloadData(data, index).items === null) {
            return []
        }

        var packingItems = []

        const filterItem = this.getPayloadData(data, index).items
        filterItem.forEach((element, index) => {
            var packingItem = {}
            packingItem.lineNumber = index + 1
            packingItem.id = element.id
            packingItem.code = element.productCode
            if (element.productDocumentSequenceCode && element.productDocumentSequenceCode !== null) {
                packingItem.code = element.productDocumentSequenceCode
            }
            packingItem.name = NewDataParser.getDefaultValue(element.name)
            packingItem.barcode = NewDataParser.getDefaultValue(element.barcode)
            packingItem.unit = element.uom
            packingItem.packedQuantity = element.paq ?? 0
            packingItem.serialBatch = this.getSerialBatchNo(element)
            packingItems.push(packingItem)
        });

        return packingItems
    }

    static getSerialBatchNo(element) {
        return element?.advancedTracking?.map(advTrk=>advTrk.serialBatchNumber)?.join('\n') || ''
    }

    static getShipItem(data, payloadIndex) {
        if (this.getPayloadData(data) === undefined) {
            return []
        }

        var item = this.getPayloadData(data, payloadIndex)
        var shipItem = {
            delivery: item.delivery,
            carrier: item.carrier,
            numberOfCarton: item.n,
            weight: item.weight,
            trackingNumber: item.trackingNo
        }
        return [shipItem]
    }

    static getChequeItem(data, dateFormat) {
        var items = []
        if(data.chequePayload === undefined || data.chequePayload === null) {
            return items
        }

        if(data.chequePayload.length === 0) {
            return items
        }

        data.chequePayload.forEach((element, index) => {
            var item = {}
            item.lineNumber = index
            item.payTo = element.clientName ? element.clientName : ''
            item.dateFormat = dateFormat.toLowerCase()
            item.date = getConvertedDate(convertDateFromERPFormatToDocFormat(element.date), dateFormat)
            item.amountInWords = element.amountInWords
            item.amount = element.amount
            item.signature = item.payTo // + '<br/>' + element.companyName
            item.notes = element.notes // Memo
            item.contactInfo = element.contactInfo // contact information like address
            item.companyName = element.companyName
            item.accountName = element.accountName
            item.accountCode = element.accountCode
            item.parentDocument = element.parentDocument
            
            item.lineItems = element.lineItems.map(lineItem => {
                let parentDocument = element.parentDocument;
                if (Array.isArray(element.bulkParentDocument)) {
                    parentDocument = element.bulkParentDocument.find(pd => pd.documentCode === lineItem.documentSequenceCode);
                }

                const amountPaid = Number(lineItem.amount || 0);
                const discount = Number(parentDocument?.totalDiscount || 0);
                const amountBeforeDiscount = amountPaid + discount;

                return {
                    ...lineItem,
                    documentSequenceCode: lineItem.documentSequenceCode,
                    supplierInvoiceNo: parentDocument?.supplierInvoiceNo,
                    ref: element.referenceNumber,
                    documentDate: parentDocument?.documentDate ? getConvertedDate(convertDateFromERPFormatToDocFormat(parentDocument?.documentDate), dateFormat) : "",
                    amount: amountPaid,
                    discountedAmount: amountBeforeDiscount,
                    totalDiscount: discount,
                    amtPaid: amountPaid,
                };
            });
            
            items.push(item)
        });
        return items
    }

    static getPayloadData(data, payloadIndex) {
        const index = payloadIndex ?? 0
        if (data.payload === undefined || data.payload === null) {
            return undefined
        }

        if (data.payload.length === 0) {
            return undefined
        }

        if (data.payload.length <= index) {
            return undefined
        }
        return data.payload[index]
    }

    static getWorkOrderItems(data, dateFormat) {
        var items = []
        var rawItems = []
        if(data.workOrderResponse !== undefined && data.workOrderResponse !== null) {
            if(data.workOrderResponse.workOrderItems !== undefined && data.workOrderResponse.workOrderItems !== null) {
                rawItems = data.workOrderResponse.workOrderItems
            }
        }

        if(rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = {}
                var productName = element.productName
                var documentSequenceCode = element.productDocSeqCode
                item.canHideProductName = false
                var productSubstitutes = []
                var productSubstitutesCode = []
                var allProductSubstitutes = []
                var allProductSubstitutesCode = []
                if (element.productSubstitutesDetails !== undefined && element.productSubstitutesDetails !== null) {
                    if (element.productSubstitutesDetails.length > 0) {
                        element.productSubstitutesDetails.forEach(element => {
                            if (element.warehouseInventoryData !== undefined && element.warehouseInventoryData !== null) {
                                if (element.warehouseInventoryData.length > 0) {
                                    var sum = 0
                                    element.warehouseInventoryData.forEach(element => {
                                        sum = element.quantity + sum
                                    });

                                    if(sum > 0) {
                                        productSubstitutes.push(element.productName)
                                        productSubstitutesCode.push(element.productDocSeqCode)
                                    }
                                }
                            }
                            allProductSubstitutes.push(element.productName)
                            allProductSubstitutesCode.push(element.productDocSeqCode)
                        });
                    }

                    if (allProductSubstitutes.length > 0) {
                        item.canHideProductName = true
                        // if(this.getIsContainsWOWarehouseInventoryData(element)) {
                        //     productName = productName + '<br/>' + productSubstitutes.join('<br/>')
                        //     documentSequenceCode = documentSequenceCode + '<br/>' + productSubstitutesCode.join('<br/>')
                        // }
                        // else {
                        //     productName = productSubstitutes.join('<br/>')
                        //     documentSequenceCode = productSubstitutesCode.join('<br/>')
                        // }

                    }
                    else if (this.getIsContainsWOWarehouseInventoryData(element)) {
                        item.canHideProductName = true
                    }

                }

                item.name = `${productName}${element.isAdHocItem ? ' *' : ''}`
                item.code = documentSequenceCode
                item.lineNumber = index + 1
                item.description = element.productDescription
                item.customFields = element.productCustomField
                item.productSubstitutes = productSubstitutes
                item.productSubstitutesCode = productSubstitutesCode
                item.allProductSubstitutes = allProductSubstitutes
                item.allProductSubstitutesCode = allProductSubstitutesCode
                item.produceProductType = element.produceProductType
                item.quantity = element.plannedUomQuantity
                item.serialBatch = ''
                item.type = element.type ?? ''
                item.productGroupName = element.productGroupName ?? []

                let advancedTrackingMetaDtoList = []
                const reservedQuantitiesData = element.reservedQuantitiesData ?? []
                if(reservedQuantitiesData.length > 0) {
                    reservedQuantitiesData.forEach(reservedItem => {
                        const advancedTrackingMetaDtos = reservedItem.advancedTrackingMetaDtos ?? undefined

                        if(advancedTrackingMetaDtos) {
                            advancedTrackingMetaDtoList.push(...advancedTrackingMetaDtos)
                        }
                    });
                }
                if (advancedTrackingMetaDtoList.length > 0) {
                    var serialBatch = []
                    advancedTrackingMetaDtoList.forEach(advancedTrackingElement => {
                        serialBatch.push(advancedTrackingElement.serialBatchNumber)
                    });
                    if (serialBatch.length > 0) {
                        item.serialBatch = serialBatch.join('\n')
                    }
                }
                items.push(item)
            });
        }

        return items
    }

    static getIsContainsWOWarehouseInventoryData(item) {
        var isContain = false

        if (item.warehouseInventoryData !== undefined && item.warehouseInventoryData !== null) {
            isContain = item.warehouseInventoryData.length > 0
        }
        return isContain
    }

    static getStockAdjustmentItems(data, dateFormat) {
        var items = []
        var rawItems = []
        if (data.stockAdjustmentInfo !== undefined && data.stockAdjustmentInfo !== null) {
            if (data.stockAdjustmentInfo.stockAdjustmentItems !== undefined && data.stockAdjustmentInfo.stockAdjustmentItems !== null) {
                rawItems = data.stockAdjustmentInfo.stockAdjustmentItems
            }
        }
        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = element
                item.name = element.productName
                item.code = element.productVariantCode
                if (element.productSequenceCode && element.productSequenceCode !== null) {
                    item.code = element.productSequenceCode
                }
                item.description = (element.productDescription !== undefined && element.productDescription !== null) ? element.productDescription : ''
                item.lineNumber = index + 1
                item.customFields = element.customField
                item.quantity = element.quantity
                if (element.documentUOMSchemaDefinition?.name !== undefined) {
                    item.uom = element.documentUOMSchemaDefinition?.name
                }
                else {
                    item.uom = 'N/A'
                }

                item.accountName = ''
                if (element.stockAdjustmentAccountName !== undefined && element.stockAdjustmentAccountName !== null) {
                    item.accountName = element.stockAdjustmentAccountName
                }

                item.accountCode = ''
                if (element.stockAdjustmentAccountCode !== undefined && element.stockAdjustmentAccountCode !== null) {
                    item.accountCode = element.stockAdjustmentAccountCode
                }
                item = this.getStockAdjustmentSerialBatchInfo(data, item, dateFormat)

                //add serial batch custom field 
                // item.serialBatchCustomFields = []
                // item.serialBatchCustomFieldType = 'NONE'
                // if (element.batchDetails.length > 0) {
                //     item.serialBatchCustomFields = element.batchDetails ?? []
                //     if(item.serialBatchCustomFields.length > 0) {
                //         item.serialBatchCustomFieldType = 'BATCH'
                //     }
                // }

                // if (element.serialNumbers.length > 0) {
                //     item.serialBatchCustomFieldType = 'SERIAL'
                //     item.serialBatchCustomFields = element.serialCustomFields ?? []
                // }
                items.push(item)
            });
        }

        return items
    }

    static getStockAdjustmentSerialBatchInfo(data, item, dateFormat) {

        let advancedTrackingMetaDtoList = []

        if (data.stockAdjustmentInfo !== undefined && data.stockAdjustmentInfo !== null) {
            if (data.stockAdjustmentInfo.advancedTrackingMetaDtoList !== undefined && data.stockAdjustmentInfo.advancedTrackingMetaDtoList !== null) {
                advancedTrackingMetaDtoList = data.stockAdjustmentInfo.advancedTrackingMetaDtoList
            }
        }
        if (advancedTrackingMetaDtoList.length > 0) {
            var advancedTracking = advancedTrackingMetaDtoList.filter(x => x.productVariantCode === item.productVariantCode)
            if (advancedTracking.length > 0) {
                var serialBatch = []
                var serialNumber = []
                var batchNumber = []
                var manufacturingDate = []
                var expiryDate = []

                item.serialBatchCustomFieldType = 'SERIAL_BATCH'
                let serialBatchCustomFields = {}
                let serialBatchList = []

                advancedTracking.forEach(element => {
                    batchNumber.push(element.serialBatchNumber)
                    serialNumber.push(element.serialBatchNumber)

                    if (element.manufacturingDate !== null && element.manufacturingDate !== undefined && element.manufacturingDate !== "") {
                        manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.manufacturingDate), dateFormat))
                    }
                    if (element.expiryDate !== null && element.expiryDate !== undefined && element.expiryDate !== "" && element.advancedTrackingType === 'BATCH') {
                        serialBatch.push(element.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat) + ')')
                        expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat))
                    }
                    else {
                        serialBatch.push(element.serialBatchNumber)
                    }
                    
                    if (element.serialBatchNumber && element.serialBatchNumber !== null) {
                        serialBatchList.push(element.serialBatchNumber)
                        serialBatchCustomFields[element.serialBatchNumber] = element.customField
                    }
                });

                item.serialBatch = ''
                item.serialNumber = ''
                item.batchNumber = ''
                item.manufacturingDate = ''
                item.expiryDate = ''

                if (serialBatch.length > 0) {
                    item.serialBatch = serialBatch.join('\n')
                }
                if (serialNumber.length > 0) {
                    item.serialNumber = serialNumber.join('\n')
                }
                if (batchNumber.length > 0) {
                    item.batchNumber = batchNumber.join('\n')
                }
                if (manufacturingDate.length > 0) {
                    item.manufacturingDate = manufacturingDate.join('\n')
                }
                if (expiryDate.length > 0) {
                    item.expiryDate = expiryDate.join('\n')
                }

                item.serialBatchList = serialBatchList
                item.serialBatchCustomFields = serialBatchCustomFields
            }
        }
        return item
    }

    static getStockTransferItems(data, dateFormat) {
        var items = []
        var rawItems = []
        if (data.stockTransferInfo !== undefined && data.stockTransferInfo !== null) {
            if (data.stockTransferInfo.stockTransferItems !== undefined && data.stockTransferInfo.stockTransferItems !== null) {
                rawItems = data.stockTransferInfo.stockTransferItems
            }
        }
        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = element
                item.name = element.productName
                item.code = element.productVariantCode
                if (element.productSequenceCode && element.productSequenceCode !== null) {
                    item.code = element.productSequenceCode
                }
                item.description = (element.productDescription !== undefined && element.productDescription !== null) ? element.productDescription : ''
                item.lineNumber = index + 1
                item.customFields = element.customField
                item.quantity = element.quantity
                if (element.documentUOMSchemaDefinition?.name !== undefined) {
                    item.uom = element.documentUOMSchemaDefinition?.name
                }
                else {
                    item.uom = 'N/A'
                }

                item.accountName = ''
                if (element.stockAdjustmentAccountName !== undefined && element.stockAdjustmentAccountName !== null) {
                    item.accountName = element.stockAdjustmentAccountName
                }

                item.accountCode = ''
                if (element.stockAdjustmentAccountCode !== undefined && element.stockAdjustmentAccountCode !== null) {
                    item.accountCode = element.stockAdjustmentAccountCode
                }

                item = this.getStockTransferSerialBatchInfo(item, dateFormat)
                items.push(item)
            });
        }

        return items
    }

    static getStockTransferSerialBatchInfo(item, dateFormat) {
        if (item.stockTransferWarehouseInventoryData !== undefined && item.stockTransferWarehouseInventoryData !== null ) {
            var serialBatch = []
            var serialNumber = []
            var batchNumber = []
            var manufacturingDate = []
            var expiryDate = []

            item.serialBatchCustomFieldType = 'SERIAL_BATCH'
            let serialBatchCustomFields = {}
            let serialBatchList = []

            let advancedTrackingType = item.stockTransferWarehouseInventoryData.advancedTrackingType
            if(advancedTrackingType !== undefined && advancedTrackingType !== null) {
                let advancedTrackingData = item.stockTransferWarehouseInventoryData.advancedTrackingData
                if (advancedTrackingData !== undefined && advancedTrackingData !== null) {
                    if (advancedTrackingData.length > 0) {
                        advancedTrackingData.forEach(element => {
                            if (advancedTrackingType !== undefined && advancedTrackingType !== null) {
                                if (advancedTrackingType === 'BATCH') {
                                    batchNumber.push(element.destSerialBatchNumber)
                                }
                                else if (advancedTrackingType === 'SERIAL') {
                                    serialNumber.push(element.destSerialBatchNumber)
                                }
                                if (element.destSerialBatchNumber && element.destSerialBatchNumber !== null) {
                                    serialBatchList.push(element.destSerialBatchNumber)
                                }
                            }
                            if (element.manufacturingDate !== null && element.manufacturingDate !== undefined && element.manufacturingDate !== "") {
                                manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.manufacturingDate), dateFormat))
                            }
                            if (element.expiryDate !== null && element.expiryDate !== undefined && element.expiryDate !== "" && element.advancedTrackingType === 'BATCH') {
                                serialBatch.push(element.destSerialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat) + ')')
                                expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat))
                            }
                            else {
                                serialBatch.push(element.destSerialBatchNumber)
                            }

                            if (element.destSerialBatchNumber && element.destSerialBatchNumber !== null) {
                                serialBatchCustomFields[element.destSerialBatchNumber] = element.customField
                            }
                        });
                    }
                }
            }
            item.serialBatch = ''
            item.serialNumber = ''
            item.batchNumber = ''
            item.manufacturingDate = ''
            item.expiryDate = ''

            if (serialBatch.length > 0) {
                item.serialBatch = serialBatch.join('\n')
            }
            if (serialNumber.length > 0) {
                item.serialNumber = serialNumber.join('\n')
            }
            if (batchNumber.length > 0) {
                item.batchNumber = batchNumber.join('\n')
            }
            if (manufacturingDate.length > 0) {
                item.manufacturingDate = manufacturingDate.join('\n')
            }
            if (expiryDate.length > 0) {
                item.expiryDate = expiryDate.join('\n')
            }

            item.serialBatchList = serialBatchList
            item.serialBatchCustomFields = serialBatchCustomFields
        }

        return item
    }

    static getQCDocumentItems(data) {
        var items = []
        var rawItems = []
        if (data.qcDocumentPrintDetail !== undefined && data.qcDocumentPrintDetail !== null) {
            if (data.qcDocumentPrintDetail['qcParameterDetails'] !== undefined && data.qcDocumentPrintDetail['qcParameterDetails'] !== null) {
                rawItems = data.qcDocumentPrintDetail['qcParameterDetails']
            }
        }

        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = {}
                item.lineNumber = (index + 1).toFixed(0)
                item.id = element.parameterId
                item.name = element.parameterName
                item.value = element.parameterValue
                item.templateId = element.templateId
                items.push(item)
            });
        }

        return items
    }

    static getSalesReturnItem(data) {
        if (data.lineItems === undefined || data.lineItems === null) {
            return []
        }

        var lineItems = []
        data.lineItems.forEach((element, index) => {
            var item = {...element}
            item.lineNumber = index + 1
            item.code = NewDataParser.getDefaultValue(element.code)
            item.name = NewDataParser.getDefaultValue(element.name)
            item.description = NewDataParser.getDefaultValue(element.description)
            item.description = NewDataParser.getDefaultValue(element.description)
            item.fulfilledQuantity = NewDataParser.getDefaultValue(element.fulfilledQuantity)
            item.returnedQuantity = NewDataParser.getDefaultValue(element.returnedQuantity)
            lineItems.push(item)
        });

        return lineItems
    }

    static getJobOutWorkOrder(data) {
        if (data.jobWorkOutOrderItems === undefined || data.jobWorkOutOrderItems === null) {
            return []
        }

        var lineItems = []
        data.jobWorkOutOrderItems.forEach((element, index) => {
            var item = { ...element }
            item.lineNumber = element.lineNumber ? element.lineNumber : index + 1
            item.code = NewDataParser.getDefaultValue(element.productId)
            item.name = NewDataParser.getDefaultValue(element.product?.name ? element.product?.name : '')
            item.description = NewDataParser.getDefaultValue(element.product?.description ? element.product?.description : '')
            item.quantity = NewDataParser.getDefaultValue(element.productQuantity)
            item.uom = NewDataParser.getDefaultValue(element.documentUomName)
            item.unitPrice = NewDataParser.getDefaultValue(element.unitPrice)
            item.discount = NewDataParser.getDefaultValue(element.discount)
            item.discountInPercent = NewDataParser.getDefaultValue(element.discountInPercent)
            item.amount = NewDataParser.getDefaultValue(element.totalAmount)
            item.customFields = element.customField

            lineItems.push(item)
        });

        return lineItems
    }

    static getServiceRequisitionLineItems(dataFromAPI, dateFormat) {
        var lineItems = dataFromAPI.lineItems.map((product, index) => {
            let modifiedProduct = product;
            
            modifiedProduct.lineNumber = product.lineNumber || index + 1
            modifiedProduct.name = modifiedProduct.product.name
            modifiedProduct.description = modifiedProduct.description ?? ''
            modifiedProduct.code = modifiedProduct.product?.documentSequenceCode ?? ''
            modifiedProduct.uom = modifiedProduct.uom || ""
            modifiedProduct.stockQty = modifiedProduct.availableQuantity || 0
            modifiedProduct.discount = 0
            modifiedProduct.unitPrice = 0
            modifiedProduct.estimateRate = 0

            if (Array.isArray(product.customFields)) {
                product.customFields.forEach((cf) => {
                    switch (cf.label) {
                        case "Estimated Rate":
                            if (!Utility.isEmpty(cf.value)) {
                                modifiedProduct.unitPrice = cf.value;
                                modifiedProduct.estimateRate = cf.value;
                            }
                            break;
                        default:
                            break;
                    }
                });
            }

            if (modifiedProduct.quantity && modifiedProduct.estimateRate) {
                modifiedProduct.amount = modifiedProduct.quantity * modifiedProduct.estimateRate
                modifiedProduct.estimateValue = modifiedProduct.amount
            } else {
                modifiedProduct.amount = 0
                modifiedProduct.estimateValue = 0
            }
            return modifiedProduct;
        })
        return lineItems;
    }

    static getPurchaseRequisitionLineItems(dataFromAPI, dateFormat) {
        var lineItems = dataFromAPI.lineItems.map((product, index) => {
            let modifiedProduct = product;
            
            modifiedProduct.lineNumber = product.lineNumber || index + 1
            modifiedProduct.name = modifiedProduct.product.name
            modifiedProduct.description = modifiedProduct.description ?? ''
            modifiedProduct.code = modifiedProduct.product?.documentSequenceCode ?? ''
            modifiedProduct.uom = modifiedProduct.uom || ""
            modifiedProduct.stockQty = modifiedProduct.availableQuantity || 0
            modifiedProduct.availableQuantity = modifiedProduct.availableQuantity || 0
            modifiedProduct.discount = 0
            modifiedProduct.unitPrice = 0
            modifiedProduct.estimateRate = 0

            if (Array.isArray(product.customFields)) {
                product.customFields.forEach((cf) => {
                    switch (cf.label) {
                        case "Estimated Rate":
                            if (!Utility.isEmpty(cf.value)) {
                                modifiedProduct.unitPrice = cf.value;
                                modifiedProduct.estimateRate = cf.value;
                            }
                            break;
                        default:
                            break;
                    }
                });
            }

            if (modifiedProduct.quantity && modifiedProduct.estimateRate) {
                modifiedProduct.amount = modifiedProduct.quantity * modifiedProduct.estimateRate
                modifiedProduct.estimateValue = modifiedProduct.amount
            } else {
                modifiedProduct.amount = 0
                modifiedProduct.estimateValue = 0
            }

            return modifiedProduct;
        });

        return lineItems;
    }
}
