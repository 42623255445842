import { TableColumnPopupType } from "../../../Constants/Constants";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";
export class AgedReceivableTableItem {
    lineNumber: number;
    documentNumber: string;
    documentDate: string;
    documentDueDate: string;
    description: string;
    amount: number;
    balance: number;
    slab1: number;
    slab2: number;
    slab3: number;
    slab4: number;

    currency: string;
    data: any;
    rawItemValue: any;

    constructor(lineItem: any, data: any, currency: string) {
        this.lineNumber = lineItem.lineNumber
        this.documentNumber = lineItem.documentNumber
        this.documentDate = this.getDocumentDate(lineItem, data)
        this.documentDueDate = this.getDocumentDueDate(lineItem, data)
        this.description = lineItem.description
        this.amount = lineItem.amount ?? 0
        this.balance = lineItem.balance ?? 0
        this.slab1 = lineItem.slab1 ?? 0
        this.slab2 = lineItem.slab2 ?? 0
        this.slab3 = lineItem.slab3 ?? 0
        this.slab4 = lineItem.slab4 ?? 0
        this.currency = currency

        this.data = data
        this.rawItemValue = this.lineNumber
    }

    getDocumentDate(lineItem: any, data: any) {
        var date = ''
        if (lineItem !== undefined && lineItem !== null) {
            if (lineItem.documentDate !== undefined && lineItem.documentDate !== null) {
                date = lineItem.documentDate
            }
        }
        return date
    }

    getDocumentDueDate(lineItem: any, data: any) {
        var date = ''
        if (lineItem !== undefined && lineItem !== null) {
            if (lineItem.documentDueDate !== undefined && lineItem.documentDueDate !== null) {
                date = lineItem.documentDueDate
            }
        }
        return date
    }

    toString(tableConfig?: any) {
        var sortedHeader = getSortedHeader(tableConfig);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                var footerList = TableFooterUtility.getFooterListByType('soa')
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber.toFixed()
            case TableColumnPopupType.documentDate:
                return this.documentDate ?? ''
            case TableColumnPopupType.documentNumber:
                return this.documentNumber ?? ''
            case TableColumnPopupType.description:
                return this.description ?? ''
            case TableColumnPopupType.documentDueDate:
                return this.documentDueDate ?? ''
            case TableColumnPopupType.slab1:
                return Utility.toCurrencyFormat(this.slab1 * 1, this.currency)
            case TableColumnPopupType.slab2:
                return Utility.toCurrencyFormat(this.slab2 * 1, this.currency)
            case TableColumnPopupType.amount:
                return Utility.toCurrencyFormat(this.amount * 1, this.currency)
            case TableColumnPopupType.balance:
                return Utility.toCurrencyFormat(this.balance * 1, this.currency)
            case TableColumnPopupType.slab3:
                return Utility.toCurrencyFormat(this.slab3 * 1, this.currency)
            case TableColumnPopupType.slab4:
                return Utility.toCurrencyFormat(this.slab4 * 1, this.currency)
            case TableColumnPopupType.currency:
                return this.currency ?? ''
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber.toString(),
            this.documentNumber,
            this.documentDate,
            this.documentDueDate,
            this.description,
            Utility.toCurrencyFormat(this.amount * 1, this.currency),
            Utility.toCurrencyFormat(this.balance * 1, this.currency),
            Utility.toCurrencyFormat(this.slab1 * 1, this.currency),
            Utility.toCurrencyFormat(this.slab2 * 1, this.currency),
            Utility.toCurrencyFormat(this.slab3 * 1, this.currency),
            Utility.toCurrencyFormat(this.slab4 * 1, this.currency),
        ]
    }
}
