import { DateFormat } from "../../Constants/Constants";
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getDateFrom, getDateString } from "../../Utilities/Utility";
import Address from "../Address";
import AddressParser, { AddressType, OwnerType } from "../AddressParser";
import TemplateParser from "./TemplateParser";

export default class AgedReceivableParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name ?? '')
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = undefined
        if (dataFromAPI.contactInfo !== undefined && dataFromAPI.contactInfo !== null) {
            if (dataFromAPI.contactInfo.billingAddress !== undefined && dataFromAPI.contactInfo.billingAddress !== null) {
                clientBillToAddressObj = new Address(dataFromAPI.clientName, dataFromAPI.contactInfo.billingAddress, undefined, undefined, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
            }
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)
        
        templateDataToReturn.accountNo = dataFromAPI?.contactInfo?.documentSequenceCode ?? ''

        //date
        if (dataFromAPI.asOfDate && dataFromAPI.asOfDate !== null) {
            templateDataToReturn.asOfDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.asOfDate), templateDataToReturn.dateFormat)
        }
        if (dataFromAPI.fromDate && dataFromAPI.fromDate !== null) {
            templateDataToReturn.fromDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.fromDate), templateDataToReturn.dateFormat)
        }
        if (dataFromAPI.endDate && dataFromAPI.endDate !== null) {
            templateDataToReturn.toDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.endDate), templateDataToReturn.dateFormat)
        }
        // templateDataToReturn.exportDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.create), templateDataToReturn.dateFormat)
        templateDataToReturn.agingSlabDuration = dataFromAPI.agingSlabDuration ?? 30
        
        templateDataToReturn.currency = dataFromAPI.currencySymbol ?? ''
        templateDataToReturn.currencyName = dataFromAPI.currency ?? ''
        templateDataToReturn = TemplateParser.assignCurrencySymbol(templateDataToReturn)

        templateDataToReturn.lastPaymentAmount = dataFromAPI.lastPaymentAmount ?? 0
        templateDataToReturn.currentAmount = dataFromAPI.agedReceivableReport?.totalCurrentAmount ?? 0

        templateDataToReturn.lineItems = this.getLineItem(dataFromAPI, templateDataToReturn.dateFormat)
        templateDataToReturn = this.getTotalValue(templateDataToReturn)

        return templateDataToReturn
    }

    static getLineItem(dataFromAPI, dateFormat) {
        var lineItems = []
        var rawItems = dataFromAPI.agedReceivableReport?.details ?? []

        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                let item = {}
                item.lineNumber = index + 1
                item.documentNumber = element.documentNumber
                item.documentType = element.documentType
                item.documentDate = ''
                if (element.documentDate && element.documentDate !== null) {
                    item.documentDate = getDateString(getDateFrom(element.documentDate, DateFormat.YYYY_MM_DD_DASH), dateFormat);
                }
                item.documentDueDate = ''
                if (element.documentDueDate && element.documentDueDate !== null) {
                    item.documentDueDate = getDateString(getDateFrom(element.documentDueDate, DateFormat.YYYY_MM_DD_DASH), dateFormat);
                }
                item.amount = element.documentAmount
                item.balance = element.documentDueAmount
                item.slab1 = element.amount1To30 ?? 0
                item.slab2 = element.amount30To60 ?? 0
                item.slab3 = element.amount60To90 ?? 0
                item.slab4 = element.amountGreaterThan90 ?? 0

                lineItems.push(item)
            });
        }

        return lineItems
    }

    static getTotalValue(templateDataToReturn) {
        let amount = 0
        let balance = 0
        let slab1 = 0
        let slab2 = 0
        let slab3 = 0
        let slab4 = 0
        let invoiceList = []
        let balanceList = []

        const lineItems = templateDataToReturn?.lineItems ?? []
        lineItems.forEach((element) => {
            if (!isNaN(element.amount)) {
                amount = amount + Number(element.amount)
            }
            if (!isNaN(element.balance)) {
                balance = balance + Number(element.balance)
            }
            if (!isNaN(element.slab1)) {
                slab1 = slab1 + Number(element.slab1)
            }
            if (!isNaN(element.slab2)) {
                slab2 = slab2 + Number(element.slab2)
            }
            if (!isNaN(element.slab3)) {
                slab3 = slab3 + Number(element.slab3)
            }
            if (!isNaN(element.slab4)) {
                slab4 = slab4 + Number(element.slab4)
            }

            invoiceList.push(element.documentNumber)
            balanceList.push(element.balance)
        });

        templateDataToReturn.totalAmount = amount
        templateDataToReturn.totalBalance = balance
        templateDataToReturn.totalSlab1 = slab1
        templateDataToReturn.totalSlab2 = slab2
        templateDataToReturn.totalSlab3 = slab3
        templateDataToReturn.totalSlab4 = slab4
        templateDataToReturn.invoiceList = invoiceList
        templateDataToReturn.balanceList = balanceList
        return templateDataToReturn
    }
}
