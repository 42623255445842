import React, { Component } from "react";
import "../../index.css";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE, TableColumnPopupType } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import NumberUtility from "../../Utilities/NumberUtility";

export default class AgedReceivableTemplate1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    getDocumentDetailsSection() {
        let tableStyle = this.getTableStyle()

        const documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        const processedDocumentDetails = Utility.chunk(documentDetailList, 2)
        const tableData = processedDocumentDetails.map((rowElement, index) => {
            const headerRow = rowElement.map(colElement => {
                return <td
                    style={{
                        borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                        borderColor: tableStyle.headerBorderColor,
                        borderStyle: 'solid',
                        background: tableStyle.headerBackgroundColor,
                        fontWeight: 'bold'
                    }}>
                    <div className="RowDiv" style={{
                        justifyContent: 'center'
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()} 
                            style={{
                                color: tableStyle.headerTextColor,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            {colElement.label}
                        </div>
                    </div>
                </td>
            });

            const itemRow = rowElement.map(colElement => {
                var value = ''
                if (colElement.key !== undefined) {
                    value = this.state.data[colElement.key]
                }
                else if (colElement.customFieldValue !== undefined) {
                    value = colElement.customFieldValue
                }
                return <td
                    style={{
                        borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                        borderColor: tableStyle.itemBorderColor,
                        borderStyle: 'solid',
                    }}>
                    <div className="RowDiv" style={{
                        justifyContent: 'center'
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()}
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            {value}
                        </div>
                    </div>
                </td>
            });

            return <>
                <tr className="row-cell">
                    {headerRow}
                </tr>
                <tr className="row-cell">
                    {itemRow}
                </tr>
            </>
        });

        return <div className="RowDiv" style={{
            alignSelf: 'end',
            width: '70%'
        }}>
            <table className='parent-width' style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
                {tableData}
            </table>
        </div>
    }

    getContactDetailSection(showTitle) {
        let billToTitle = showTitle ? this.state.data.billToTitle : ''
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    width: Utility.getIsArabicLang() ? '100%' : '70%',
                }}
            >
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    billToTitle,
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
            </div>
        )
    }

    getLastPaymentDetailSection() {
        let lastPaymentAmount = this.state.data.lastPaymentAmount ?? 0
        let paymentString = this.state.data.asOfDate ?? ''
        paymentString = paymentString + ' - ' + Utility.toCurrencyFormat(lastPaymentAmount, NumberUtility.getCurrency(this.state.data))

        return <div className="RowDiv" style={{width: '40%'}}>
            {ComponentManager.getRowValueSection(
                getLocalisedText('last_payment'),
                paymentString,
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )}
        </div>
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}
            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%' }}>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getDocumentType() {
        return <div className="RowDiv"
            style={{
                justifyContent: 'center',
                marginBottom: getVW(20),
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }


    getFormattedSlab(slabPeriod, type) {
        let startCount = 1
        let endCount = slabPeriod

        switch (type) {
            case TableColumnPopupType.slab1:
                startCount = 1
                endCount = slabPeriod
                break;
            case TableColumnPopupType.slab2:
                startCount = slabPeriod + 1
                endCount = slabPeriod * 2
                break;
            case TableColumnPopupType.slab3:
                startCount = slabPeriod * 2 + 1
                endCount = slabPeriod * 3
                break;
            case TableColumnPopupType.slab4:
                startCount = slabPeriod * 3
                break;
            default:
                break;
        }

        if (type === TableColumnPopupType.slab4) {
            return 'More than ' + startCount + ' Days'
        }
        else {
            return `${startCount} to ${endCount} Days`
        }
    }

    getSlabTableSection() {
        let tableStyle = this.getTableStyle()
        let slabList = [
            { key: TableColumnPopupType.amount, value: 'currentAmount' },
            { key: TableColumnPopupType.slab1, value: 'totalSlab1' },
            { key: TableColumnPopupType.slab2, value: 'totalSlab2' },
            { key: TableColumnPopupType.slab3, value: 'totalSlab3' },
            { key: TableColumnPopupType.slab4, value: 'totalSlab4' },
            { key: TableColumnPopupType.balance, value: 'totalBalance' },
        ]

        let agingSlabDuration = this.state.data?.agingSlabDuration ?? 30

        const itemRow = slabList.map(colElement => {
            let slabValue = this.state.data[colElement.value] ?? 0
            let slabLabel = this.getFormattedSlab(agingSlabDuration, colElement.key)

            if(colElement.key === TableColumnPopupType.balance) {
                slabLabel = getLocalisedText('total')
            }
            else if (colElement.key === TableColumnPopupType.amount) {
                slabLabel = getLocalisedText('current_amount')
            } 
            slabLabel = slabLabel + '\n\n'
            return <td
                style={{
                    borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                    borderColor: tableStyle.itemBorderColor,
                    borderStyle: 'solid',
                    padding: getVW(Utility.convertToPrintScale(2.5, this.state.data.printScale)) 
                }}>
                <div className="RowDiv parent-width" style={{
                    justifyContent: 'center'
                }}>
                    <div className={"docFontStyle parent-width" + getFontSizeClass()} 
                        style={{
                            textAlign: 'end',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {slabLabel}
                        {Utility.toCurrencyFormat(slabValue, this.state.data.currency)}
                    </div>
                </div>
            </td>
        });

        return <div className="RowDiv parent-width">
            <table className='parent-width' style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
                <tr className="row-cell">
                    {itemRow}
                </tr>
            </table>
        </div>
    }

    getCompanyAddress() {
        return <div className="ColumnDiv">
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    paddingTop: getVW(Utility.convertToPrintScale(60, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {getLocalisedText('remit_to')}
            </text>
            <div className="ColumnDiv"
                style={{
                    justifyContent: 'flex-start',
                    marginLeft: getVW(20),
                    alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    width: Utility.getIsArabicLang() ? '100%' : undefined,
                }}>
                {this.state.data.showCompanyName &&
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>
                }
                {this.state.data.showFrom &&
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                    </div>
                }
            </div>
        </div>
    }

    getTotalSummaryTable() {
        let tableStyle = this.getTableStyle()
        const summaryData = [
            { label: "total_due", value: Utility.toCurrencyFormat(this.state.data.totalBalance, this.state.data.currency) },
            { label: "amount_enclosed", value: '\n' }
        ]

        const tableData = summaryData.map((element, index) => {
            const headerRow = <td
                style={{
                    padding: getVW(Utility.convertToPrintScale(2.5, this.state.data.printScale)),
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                    borderColor: tableStyle.headerBorderColor,
                    borderStyle: 'solid',
                    background: tableStyle.headerBackgroundColor,
                    fontWeight: 'bold'
                }}>
                <div className="RowDiv" style={{
                    justifyContent: 'center'
                }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: tableStyle.headerTextColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(element.label)}
                    </div>
                </div>
            </td>

            const itemRow = <td
                style={{
                    padding: getVW(Utility.convertToPrintScale(2.5, this.state.data.printScale)),
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                    borderColor: tableStyle.itemBorderColor,
                    borderStyle: 'solid',
                }}>
                <div className="RowDiv" style={{
                    justifyContent: 'end'
                }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{__html: element.value}}>
                    </div>
                </div>
            </td>

            return <>
                <tr className="row-cell">
                    {headerRow}
                </tr>
                <tr className="row-cell">
                    {itemRow}
                </tr>
            </>
        });

        return <div className="RowDiv" style={{width: '90%'}}>
            <table className='parent-width' style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
                {tableData}
            </table>
        </div>
    }


    getInvoiceSummaryTable() {
        let tableStyle = this.getTableStyle()

        const invoiceNumberList = this.state.data.invoiceList ?? []
        let balanceList = this.state.data.balanceList ?? []

        balanceList = balanceList.map(element => {
            return Utility.toCurrencyFormat(element, NumberUtility.getCurrency(this.state.data))
        });

        const invoiceSummaryData = [
            [
                { label: "statement_date", value: this.state.data.asOfDate, textAlign: 'center' },
                { label: "account_no", value: this.state.data.accountNo,  textAlign: 'center' },
            ],
            [
                { label: "invoice_no", value: invoiceNumberList.join('\n'), textAlign: 'right', },
                { label: "balance", value: balanceList.join('\n'), textAlign: 'right', },
                { label: "x", value: '', textAlign: 'center', }
            ]
        ]
        const tableData = invoiceSummaryData.map((rowElement, index) => {
            const headerRow = rowElement.map((colElement, colIndex) => {
                return <td
                    colSpan={this.computeInvoiceSummaryColSpan(rowElement, colIndex)}
                    style={{
                        padding: getVW(Utility.convertToPrintScale(2.5, this.state.data.printScale)),
                        paddingTop: 0,
                        paddingBottom: 0,
                        borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                        borderColor: tableStyle.headerBorderColor,
                        borderStyle: 'solid',
                        background: tableStyle.headerBackgroundColor,
                        fontWeight: 'bold',
                        width: this.computeInvoiceSummaryWidth(rowElement, colIndex),
                    }}>
                    <div className="RowDiv" style={{
                        justifyContent: 'center',
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()}
                            style={{
                                color: tableStyle.headerTextColor,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            {getLocalisedText(colElement.label)}
                        </div>
                    </div>
                </td>
            });

            const itemRow = rowElement.map((colElement, colIndex) => {
                return <td
                    colSpan={this.computeInvoiceSummaryColSpan(rowElement, colIndex)}
                    style={{
                        padding: getVW(Utility.convertToPrintScale(2.5, this.state.data.printScale)),
                        paddingTop: 0,
                        paddingBottom: 0,
                        borderWidth: getVW(Utility.convertToPrintScale(1, this.state.data.printScale)),
                        borderColor: tableStyle.itemBorderColor,
                        borderStyle: 'solid',
                        width: this.computeInvoiceSummaryWidth(rowElement, colIndex),
                    }}>
                    <div className="RowDiv" style={{
                        justifyContent: colElement.textAlign === 'right' ? 'end' : 'center'
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()} style={{
                            textAlign: colElement.textAlign === 'right' ? 'end' : undefined,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                            {colElement.value}
                        </div>
                    </div>
                </td>
            });

            return <>
                <tr className="row-cell">
                    {headerRow}
                </tr>
                <tr className="row-cell">
                    {itemRow}
                </tr>
            </>
        });

        return <div className="RowDiv" style={{
            alignSelf: 'end',
            width: '100%'
        }}>
            <table className='parent-width' style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
                {tableData}
            </table>
        </div>
    }

    computeInvoiceSummaryWidth(rowElement, colIndex) {
        let width = '100%'
        if (rowElement.length === 2) {
            width = colIndex % 2 === 1 ? '60%' : '40%'
        }
        else if (rowElement.length === 3) {
            switch (colIndex) {
                case 0:
                    width = '40%'
                    break;
                case 1:
                    width = '55%'
                    break;
                case 2:
                    width = '5%'
                    break;
                default:
                    break;
            }
        }
        return width
    }

    computeInvoiceSummaryColSpan(rowElement, colIndex) {
        let colSpan = undefined
        if (rowElement.length === 2 && colIndex === 1) {
            colSpan = 2
        }
        return colSpan
    }

    getCustomerCopySection() {
        return <div className="RowDiv parent-width" style={{ alignItems: 'baseline' }}>
            <div className="ColumnDiv parent-width" style={{ width: '40%', alignSelf: 'baseline' }}>
                {this.getContactDetailSection(false)}
                {this.getCompanyAddress()}
            </div>
            <div className="ColumnDiv parent-width" style={{ width: '60%' }}>
                <div className="RowDiv parent-width">
                    <div className="ColumnDiv parent-width" style={{ width: '40%', alignSelf: 'baseline' }}>
                        {this.getTotalSummaryTable()}
                    </div>
                    <div className="ColumnDiv parent-width" style={{ width: '60%', alignSelf: 'baseline' }}>
                        {this.getInvoiceSummaryTable()}
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    <div className="RowDiv" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div className="ColumnDiv" style={{ width: '100%', justifyContent: 'flex-start' }}>
                            {ComponentManager.addVerticalSpace(15)}
                            <div className="RowDiv"
                                style={{
                                    alignItems: 'flex-start'
                                }}>
                                <div>
                                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'static' })}
                                </div>
                                <div className="ColumnDiv"
                                    style={{
                                        justifyContent: 'flex-start',
                                        marginLeft: getVW(15),
                                        alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                                        width: Utility.getIsArabicLang() ? '100%' : undefined,
                                    }}>
                                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                                        style={{
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                                        }}
                                    >
                                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                                    </text>}
                                    {this.state.data.showFrom &&
                                        <div className={"docFontStyle" + getFontSizeClass()}
                                            style={{
                                                marginTop: getVW(6),
                                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                                            }}
                                            dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        {ComponentManager.addHorizontalSpace(getVW(50))}
                        <div className="ColumnDiv" style={{ width: '100%' }}>
                            {this.getDocumentDetailsSection()}
                            {/* {ComponentManager.addVerticalSpace(10)} */}
                            {/* {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(10)}
                            {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                            {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                            {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()} */}
                        </div>
                    </div>
                    {this.getDocumentType()}
                    <div className="RowDiv" style={{ alignItems: 'baseline' }}>
                        {this.getContactDetailSection(true)}
                        {this.getLastPaymentDetailSection()}
                    </div>
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    <div className="ColumnDiv" style={{ width: '100%' }}>
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                        {this.getEditableDocumentTable()}
                        {this.getSlabTableSection()}
                    </div>
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{ bottom: 0, justifyContent: 'center' }}>
                        {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                            ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                this.state.data.termsAndCondition,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                            )}
                        {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                        {(this.state.data.showNotes && this.state.data.notes) &&
                            ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                                this.state.data.notes,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                            )}
                    </div>
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.bottom}
                    />
                    {ComponentManager.addDivider({ backgroundColor: 'black', height: getVW(Utility.convertToPrintScale(2.5, this.state.data.printScale)) })}
                    {Utility.getGeneratedVisibility() && ComponentManager.getGenerateDocumentSection(this.state.data, 1, '0vw', '0vw')}
                    {this.getCustomerCopySection()}
                </div>
                
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if (Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.headerBackgroundColor = 'yellow';
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerBorderColor = "#BBBABA";
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}
            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '60%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    {ComponentManager.getTransactionDocumentTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
