import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class AgedReceivableTableWidth {
    isLineNumberVisible: boolean;
    isDocumentNumberVisible: boolean;
    isDocumentDateVisible: boolean;
    isDocumentDueDateVisible: boolean;
    isDescriptionVisible: boolean;
    isAmountVisible: boolean;
    isBalanceVisible: boolean;
    isSlab1Visible: boolean;
    isSlab2Visible: boolean;
    isSlab3Visible: boolean;
    isSlab4Visible: boolean;

    lineNumber: number
    documentNumber: number
    documentDate: number
    documentDueDate: number
    description: number
    amount: number
    balance: number
    slab1: number
    slab2: number
    slab3: number
    slab4: number

    originalLineNumber: number
    originalDocumentNumber: number
    originalDocumentDate: number
    originalDocumentDueDate: number
    originalDescription: number
    originalAmount: number
    originalBalance: number
    originalSlab1: number
    originalSlab2: number
    originalSlab3: number
    originalSlab4: number

    constructor(data: any) {
        this.isLineNumberVisible = true
        this.isDocumentNumberVisible = true
        this.isDocumentDateVisible = true
        this.isDocumentDueDateVisible = true
        this.isDescriptionVisible = true
        this.isAmountVisible = true
        this.isBalanceVisible = true
        this.isSlab1Visible = true
        this.isSlab2Visible = true
        this.isSlab3Visible = true
        this.isSlab4Visible = true

        this.lineNumber = 5
        this.documentNumber = 10
        this.documentDate = 10
        this.documentDueDate = 10
        this.description = 5
        this.amount = 10
        this.balance = 10
        this.slab1 = 10
        this.slab2 = 10
        this.slab3 = 10
        this.slab4 = 10
        
        this.originalLineNumber = 5
        this.originalDocumentNumber = 10
        this.originalDocumentDate = 10
        this.originalDocumentDueDate = 10
        this.originalDescription = 5
        this.originalAmount = 10
        this.originalBalance = 10
        this.originalSlab1 = 10
        this.originalSlab2 = 10
        this.originalSlab3 = 10
        this.originalSlab4 = 10
    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isDocumentNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.documentNumber)
        this.isDocumentDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.documentDate)
        this.isDocumentDueDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.documentDueDate)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amount)
        this.isBalanceVisible = getIsShowTableColumn(columnData, TableColumnPopupType.balance)
        this.isSlab1Visible = getIsShowTableColumn(columnData, TableColumnPopupType.slab1)
        this.isSlab2Visible = getIsShowTableColumn(columnData, TableColumnPopupType.slab2)
        this.isSlab3Visible = getIsShowTableColumn(columnData, TableColumnPopupType.slab3)
        this.isSlab4Visible = getIsShowTableColumn(columnData, TableColumnPopupType.slab4)
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.lineNumber = this.originalLineNumber
        this.documentNumber = this.originalDocumentNumber
        this.documentDate = this.originalDocumentDate
        this.documentDueDate = this.originalDocumentDueDate
        this.description = this.originalDescription
        this.amount = this.originalAmount
        this.balance = this.originalBalance
        this.slab1 = this.originalSlab1
        this.slab2 = this.originalSlab2
        this.slab3 = this.originalSlab3
        this.slab4 = this.originalSlab4
    }

    private resetElementWidth() {
        this.lineNumber = 0
        this.documentNumber = 0
        this.documentDate = 0
        this.documentDueDate = 0
        this.description = 0
        this.amount = 0
        this.balance = 0
        this.slab1 = 0
        this.slab2 = 0
        this.slab3 = 0
        this.slab4 = 0
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isDocumentNumberVisible) {
            totalWidth += this.documentNumber;
        }
        if (this.isDocumentDateVisible) {
            totalWidth += this.documentDate;
        }
        if (this.isDocumentDueDateVisible) {
            totalWidth += this.documentDueDate;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isAmountVisible) {
            totalWidth += this.amount;
        }
        if (this.isBalanceVisible) {
            totalWidth += this.balance;
        }
        if (this.isSlab1Visible) {
            totalWidth += this.slab1;
        }
        if (this.isSlab2Visible) {
            totalWidth += this.slab2;
        }
        if (this.isSlab3Visible) {
            totalWidth += this.slab3;
        }
        if (this.isSlab4Visible) {
            totalWidth += this.slab4;
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isDocumentNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isDocumentDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isDocumentDueDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isBalanceVisible) {
            totalSelectedElement += 1
        }
        if (this.isSlab1Visible) {
            totalSelectedElement += 1
        }
        if (this.isSlab2Visible) {
            totalSelectedElement += 1
        }
        if (this.isSlab3Visible) {
            totalSelectedElement += 1
        }
        if (this.isSlab4Visible) {
            totalSelectedElement += 1
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isDocumentNumberVisible) {
            this.documentNumber = this.originalDocumentNumber + additionalWidth
        }
        if (this.isDocumentDateVisible) {
            this.documentDate = this.originalDocumentDate + additionalWidth
        }
        if (this.isDocumentDueDateVisible) {
            this.documentDueDate = this.originalDocumentDueDate + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isAmountVisible) {
            this.amount = this.originalAmount + additionalWidth
        }
        if (this.isBalanceVisible) {
            this.balance = this.originalBalance + additionalWidth
        }
        if (this.isSlab1Visible) {
            this.slab1 = this.originalSlab1 + additionalWidth
        }
        if (this.isSlab2Visible) {
            this.slab2 = this.originalSlab2 + additionalWidth
        }
        if (this.isSlab3Visible) {
            this.slab3 = this.originalSlab3 + additionalWidth
        }
        if (this.isSlab4Visible) {
            this.slab4 = this.originalSlab4 + additionalWidth
        }
    }
}