import React, { Component } from 'react'
import "../../DKUILibrary/DKUILibrary.css"
import CustomFieldPopupComponent from '../CustomFieldComponent/CustomFieldPopupComponent';
import CustomFieldTablePopupRow from './CustomFieldTablePopupRow';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    CustomFieldManager,
    TemplateSettingsManager,
    getLocalisedText,
    Utility,
    getIsCheque, 
    getIsPayrollCheck, 
    getIsPayslip, 
    getVW,
    getIsAddressCustomFieldType,
    getIsMakePayment,
    getIsReceivePayment,
    getIsExpenseOrDeposit,
    getIsOrder,
    getIsBill,
    getIsRequestForQuotation,
    Address,
    AddressParser,
    AddressFieldType,
    AddressType,
    OwnerType,
    getIsSalesOrder,
    getIsQuotation,
    getIsInvoice,
    getIsFulfillment,
    getIsPurchaseInwardQuotation,
    getIsGoodsReceipt,
    getIsPurchaseRequisition,
    getIsServiceRequisition,
    COLOR_LIGHTGRAY
} from 'deskera-doc-builder-lib'
import { ChequeDetailType } from '../../deskera-doc-builder-lib/src/Helper/DocumentModuleParser/ChequeParser';
export default class AddressFormatPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            addressFields: this.getList(),
            selectedIndex: undefined,
            addressString: this.getAddressStringByType(this.getList()),
            isApplyAddressFormatToOther: false,
            optionList: this.getDropdownOptions(),
            height: this.getScrollingHeight(this.getList().length),
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 350,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={this.getTitle()}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onCancelClicked}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        height: this.state.height,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}>
                    {this.getPreviewPanel()}
                    {!getIsCheque(this.props.data.type)
                        && !getIsPayrollCheck(this.props.data.type)
                        && !getIsPayslip(this.props.data.type) &&
                        <CustomFieldPopupComponent
                            existingCustomFields={this.state.addressFields}
                            addCustomField={(customField) => this.addCustomField(customField)}
                        />
                    }
                    <div
                        id='address_table_popup'
                        className="ColumnDiv"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: getVW(2),
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {
                            getIsCheque(this.props.data?.type) && this.props.data.templateId === 7 && 
                            this.getContactDetails()
                        }
                        {this.getOptions()}
                        {!getIsCheque(this.props.data.type) && this.renderIsApplyAddressFormatToOther()}
                    </div>
                    </div>
            </div>
        )
    }

    getScrollingHeight(rowSize) {
        var defaultThreshold = 130 //default without total row  866 // for product related
        var innerHeight = window.innerHeight

        var rowHeight = 53
        var totalRowHeight = 0
        if (rowSize > 0) {
            totalRowHeight = rowHeight * rowSize
        }

        defaultThreshold = defaultThreshold + totalRowHeight

        if (defaultThreshold > (innerHeight * 0.8)) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }

    getTitle() {
        if (this.props.addressType !== undefined && this.props.addressType !== null) {
            switch (this.props.addressType) {
                case AddressType.from:
                    return getLocalisedText('company_address')
                case AddressType.tenantAddress:
                    return getLocalisedText('company_address')
                case AddressType.billTo:
                    if(getIsCheque(this.props.data.type)) {
                        return getLocalisedText('contact_address');
                    } else {
                        return getLocalisedText(this.props.data.billToTitle)
                    }                    
                case AddressType.shipTo:
                    return getLocalisedText(this.props.data.shipToTitle)
                case AddressType.shipFrom:
                    return getLocalisedText(this.props.data.shipFromTitle)
                default:
                    return getLocalisedText('address_format')
            }
        }
        return getLocalisedText('address_format')
    }

    getPreviewPanel() {
        return <div
            style={{
                textAlign: 'left',
                padding: 13,
                paddingLeft: 16,
                paddingRight: 16,
                // paddingTop: 5,
                display: 'block',
                width: '-webkit-fill-available'
            }}
            dangerouslySetInnerHTML={{ __html: this.state.addressString}}>
        </div>
    }

    getIsShowContactName() {
        let addressType = this.props.addressType ?? ''
        let documentType = this.props.data.type?.toLowerCase() ?? ''
        let showContactNameOption = false
        
        if(addressType === AddressType.billTo || addressType === AddressType.shipTo) {
            if(documentType !== '') {
                if (getIsQuotation(documentType) ||
                    getIsInvoice(documentType) ||
                    getIsSalesOrder(documentType) ||
                    getIsRequestForQuotation(documentType) ||
                    getIsPurchaseInwardQuotation(documentType) ||
                    getIsFulfillment(documentType) ||
                    getIsPurchaseRequisition(documentType) ||
                    getIsServiceRequisition(documentType) ||
                    getIsOrder(documentType) ||
                    getIsBill(documentType) ||
                    getIsGoodsReceipt(documentType)) {
                    showContactNameOption = true
                }
            }
        }
        return showContactNameOption
    }

    getList() {
        var rowItemList = []
        var addressFieldList = AddressParser.getAddressFormat(this.props.addressType, getIsPayslip(this.props.data.type), this.props.data)
        if(addressFieldList.length > 0) {
            addressFieldList.forEach((element, index) => {
                var newRowItem = {}
                newRowItem.isSelected = element.isSelected
                newRowItem.type = element.type
                if(element.type === AddressFieldType.customField) {
                    newRowItem.code = element.code
                }
                else {
                    newRowItem.code = element.type
                }
                newRowItem.index = element.index
                newRowItem.label = getLocalisedText(element.name)
                // newRowItem.value = element.name
                rowItemList.push(newRowItem)
            });
        }

        if(this.getIsShowContactName()) {
            let name = rowItemList.find(x => x.type === AddressFieldType.name)
            if(name === undefined) {
                rowItemList.push({ type: AddressFieldType.name, isSelected: true, label: getLocalisedText("name"), index: -1 },)
                rowItemList = rowItemList.sort((a, b) => a.index - b.index)
                rowItemList.forEach((element, index) => {
                    element.index = index
                });
            }
        }
        return rowItemList
    }

    getAddressTypeName(addressType) {
        if (addressType !== undefined && addressType !== null) {
            switch (addressType) {
                case AddressType.from:
                    return 'companyAddress'
                case AddressType.billTo:
                    return 'billTo'
                case AddressType.shipTo:
                    return 'shipTo'
                case AddressType.tenantAddress:
                    return 'tenantAddress'
                case AddressType.shipFrom:
                    return 'shipFrom'
                default:
                    return undefined
            }
        }
        return undefined
    }

    renderIsApplyAddressFormatToOther() {
        return <div className='RowDiv'
            style={{
                width: '92%',
                paddingTop: 5,
                paddingBottom: 5,
                textAlign: 'left',
            }}>
            <input
                type="checkbox"
                checked={this.state.isApplyAddressFormatToOther}
                id={'address_format_popup_checkbox'}
                onClick={() => {
                    this.setState({
                        isApplyAddressFormatToOther: !this.state.isApplyAddressFormatToOther
                    })
                }}
            />
            <div
                className='TextField ListPicker'
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                {getLocalisedText('is_apply_address_format_to_other_addresses')}
            </div>
        </div>
    }
    getContactDetails() {
        return(
            <>
            <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                {this.getEditableSection()}
            </div>
            </>
        )
    }
    getEditableSection() {
        return(
            <div className="RowDiv" style={{paddingTop: 5, paddingBottom: 5, paddingLeft: 13, paddingRight:13, boxSizing: 'border-box' }}>
                {this.getCheckBox()}
                <div className="ColumnDiv" style={{width: '100%'}}>
                    {this.renderTextOnySection()}
                </div>
                {this.getFontAddressButton()}
            </div>
        )
    }
    renderTextOnySection() {
        return <>
            {/* {this.state.isTextFieldFocus && this.getEditableTextField()}
            {!this.state.isTextFieldFocus && */}
            <div id={'row_text_id_' } className='TextField ListPicker' style={{
                fontSize: 14,
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
                {/* {this.props.item.label} */}
                Contact name
            </div>
            
        </>
    }
    getCheckBox() {
        return (
            <input type="checkbox" 
                disabled={true}
                checked={true}
                // style={{cursor: "pointer"}}
                // id={'row_checkbox_id_' + this.props.item.index}
                onClick={ () => {
                    // this.props.onSelectClick()
                }}/>
        )
    }
    getDragIndicator() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                    backgroundColor: COLOR_LIGHTGRAY,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 4,
                }}
                id={'row_drag_id_' + this.props.item.index}
            >
                {this.getFontAddressButton()}
            </div>
        )
    }
    getFontAddressButton() {
        const ownerType = "";
        return (
          <div
            style={{
              justifyContent: "flex-end",
              display: 'flex'
            }}
          >
            <div
              className="HeaderButton clicked-effect"
              style={{ marginLeft: 10, userSelect: 'none' }}
              onClick={(event) => this.updateCheckFontStyle("down", ownerType, ChequeDetailType.payTo)}
            >
              A-
            </div>
            <div
              className="HeaderButton clicked-effect"
              style={{ marginLeft: 10, userSelect: 'none' }}
              onClick={(event) => this.updateCheckFontStyle("up", ownerType, ChequeDetailType.payTo)}
            >
              A+
            </div>
          </div>
        );
    }
    updateCheckFontStyle(action, ownerType, field) {
        Utility.updateCheckFontSize(action, ownerType, field);
        // this.props.onDataChanged(this.state.data);
    }
    getOptions() {
        var sortedList = this.state.addressFields.sort((a,b) => a.index - b.index)
        return sortedList.map((item,lineIndex) => {
            return <CustomFieldTablePopupRow
                key={`options_address_${lineIndex}`}
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={(name) => this.onUpdateLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                options={this.getOptionListByItem(item)}
                onUpdateOptions={(value) => this.onUpdateOptions(item, value)}
                isTextEditable={this.getIsContainEditableText(item)}
                isRemovable={this.getIsRemovable(item)}
                onDeleteItem={(item) => this.onDeleteItem(item)}
                isResetTitle={this.getIsContainEditableText(item)}
                onResetTitle={(item) => this.onResetTitle(item)}
                isNonDraggable={this.getIsNonDraggable(item)}
            />
        })
    }

    getIsNonDraggable(item) {
        if(item.type === AddressFieldType.name) {
            return true
        }
        return false
    }

    getIsContainEditableText(item) {
        if (item.type === AddressFieldType.customField){
            if (item.originalLabel === undefined) {
                return true
            }
            return false
        }
        return undefined
    }

    getIsRemovable(item) {
        if (item.type === AddressFieldType.customField) {
            return true
        }
        return false
    }

    getDropdownOptions() {
        var optionList = []
        var sortedList = this.getList().sort((a, b) => a.index - b.index)
        sortedList.forEach(element => {
            var list = this.getDefaultDropdownOptionByItem(element)
            if(list !== undefined) {
                optionList.push({
                    item: element,
                    list: list,
                    itemCode: element.code
                })
            }
        });
        return optionList
    }

    getDefaultDropdownOptionByItem(item) {
        var options = undefined
        switch (item.code) {
            case AddressFieldType.contactNumber:
                options= ['contact_number', 'blank', 'other']
                break;
            case AddressFieldType.contactCode:
                options = ['contact_code', 'blank', 'other']
                break;
            case AddressFieldType.emailAddress:
                options = ['email_address_1', 'blank', 'other']
                break;
            case AddressFieldType.state:
                if (this.props.data && this.props.data.country === 'US') {
                    options = ['state_name', 'label_state_code']
                }
                break;
            default:
                break;
        }

        if (getIsAddressCustomFieldType(this.props.data.type)) {
            const rawAddress = this.props.data[AddressParser.getDataAddressField(this.props.addressType)];
            if (AddressParser.isAddressCustomField(item.code, rawAddress?.customFields)) {
                options = [item.label, 'blank', 'other'];
            }
        }

        return options
    }


    getOptionListByItem(item) {
        var option = undefined
        if(this.state.optionList !== undefined && this.state.optionList !== null) {
            var selectedOption = this.state.optionList.find(x => x.itemCode === item.code)
            if(selectedOption !== undefined) {
                option = selectedOption.list
            }
        }
        return option
    }


    onUpdateOptions(item, newValue) {
        var defaultOptions = this.getOptionListByItem(item)
        if(defaultOptions !== undefined) {
            var newList = this.state.optionList
            newList.forEach(element => {
                if(element.itemCode === item.code) {
                    var newList = []
                    newList.push(newValue)
                    if (this.getDefaultDropdownOptionByItem(item) !== undefined) {
                        this.getDefaultDropdownOptionByItem(item).forEach(element => {
                            newList.push(element)
                        });
                    }
                    element.list = newList
                }
            });
            this.setState({
                optionList: newList
            }, ()=>{
                this.onUpdateLabel(item, newValue)
            })
        }
    }

    onDeleteItem(item) {
        if(item !== undefined && item !== null) {
            var newAddressList = this.state.addressFields.filter(x => x.code !== item.code)
            newAddressList.forEach((element, index) => {
                element.index = index
            });

            this.setState({
                addressFields: newAddressList,
                addressString: this.getAddressStringByType(newAddressList),
                height: this.getScrollingHeight(newAddressList.length),
            })
        }
    }

    onResetTitle(item) {
        var addressFields = this.state.addressFields
        addressFields.forEach(element => {
            if (element.type === AddressFieldType.customField && element.code === item.code) {
                var customField = CustomFieldManager.getCustomFields().find(x => x.code === item.code)
                if (customField !== undefined) {
                    element.label = customField.label
                }
            }
        });

        this.setState({
            addressFields: addressFields,
            addressString: this.getAddressStringByType(addressFields),
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if(startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if(dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if(this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if(startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if(this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var addressFields = this.state.addressFields
        addressFields.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = addressFields.filter(x => x.index === startIndex)
        if(selectedItem.length > 0 ) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            addressFields: [],
            selectedIndex: undefined,
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
            this.setState({
                addressFields: sortedList,
                addressString: this.getAddressStringByType(sortedList),
            })
        })
    }

    onUpdateLabel(item, name) {
        var addressFields = this.state.addressFields

        addressFields.forEach(element => {
            if(element.code === item.code) {
                element.label = name
            }
        });

        this.setState({
            addressFields: addressFields,
            addressString: this.getAddressStringByType(addressFields),
        })
    }

    onSelectClick(item) {
        var addressFields = this.state.addressFields

        addressFields.forEach(element => {
            if (element.code === item.code) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            addressFields: addressFields,
            addressString: this.getAddressStringByType(addressFields),
        })
    }

    onRowChange(rowIndex, action) {
        var newArray = []
        var addressFields = this.state.addressFields
        addressFields.forEach((element, index) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= addressFields.length) {
                newIndex = addressFields.length - 1
            }
        }
        var sortedList = []
        var selectedItem = addressFields[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            addressFields: addressFields,
            addressString: this.getAddressStringByType(addressFields),
        })
    }


    getAddressStringByType(sortedList) {
        var addressString = AddressParser.getFormattedAddress(this.toAddressFieldList(sortedList), this.props.addressType, this.props.data);
        if (getIsPayslip(this.props.data.type)) {
            if (this.props.addressType === AddressType.from) {
                var companyAddress = new Address(undefined, undefined, undefined, undefined, undefined, OwnerType.tenant)
                companyAddress.initPayslipAddress(this.props.data.payslip.tenantInfo.address)
                addressString = AddressParser.getPayslipFormattedAddress(this.toAddressFieldList(sortedList), AddressType.from, companyAddress)
            }
            else if (this.props.addressType === AddressType.billTo) {
                var employeeAddress = new Address(undefined, undefined, undefined, undefined, undefined, OwnerType.contact)
                employeeAddress.initPayslipAddress(this.props.data.payslip.employeeDetails.address)
                addressString = AddressParser.getPayslipFormattedAddress(this.toAddressFieldList(sortedList), AddressType.billTo, employeeAddress)
            }
        }
        return addressString;
    }

    toAddressFieldList(addressFields) {
        var newAddressFieldList = []
        var sortedAddressFields = addressFields.sort((a, b) => a.index - b.index)
        sortedAddressFields.forEach(element => {
            newAddressFieldList.push({
                type: element.type,
                isSelected: element.isSelected,
                index: element.index,
                name: element.label,
                code: element.code
            })
        });
        return newAddressFieldList
    }

    addCustomField(customField) {
        if(customField !== undefined) {
            var newAddressField = this.state.addressFields
            var newRowItem = {}
            newRowItem.isSelected = true
            newRowItem.code = customField.code
            newRowItem.type = AddressFieldType.customField
            newRowItem.index = this.state.addressFields.length
            newRowItem.label = getLocalisedText(customField.label)
            newAddressField.push(newRowItem)
            this.setState({
                addressFields: newAddressField,
                addressString: this.getAddressStringByType(newAddressField),
                height: this.getScrollingHeight(newAddressField.length),
            })
        }
    }

    onSave() {
        var newAddressFormat = TemplateSettingsManager.defaultTemplateSettings.addressFormat
        var data = this.props.data

        if (newAddressFormat === undefined || newAddressFormat === null) {
            newAddressFormat = TemplateSettingsManager.getDefaultAddressFormat()
        }

        var addressType = this.getAddressTypeName(this.props.addressType)
        if (addressType !== undefined) {
            if (this.state.addressFields !== undefined && this.state.addressFields !== null) {
                if(this.state.addressFields.length > 0) {
                    if (this.state.isApplyAddressFormatToOther) {
                        var addressFieldCodeList = this.state.addressFields.map(x => x.code)
                        if (!addressFieldCodeList.includes(AddressFieldType.contactCode) && !addressFieldCodeList.includes(AddressFieldType.contactNumber)) {
                            newAddressFormat['companyAddress'] = this.toAddressFieldList(this.state.addressFields)
                            newAddressFormat['billTo'] = this.toAddressFieldList(this.state.addressFields)
                            newAddressFormat['tenantAddress'] = this.toAddressFieldList(this.state.addressFields)
                            newAddressFormat['shipFrom'] = this.toAddressFieldList(this.state.addressFields)

                            data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), AddressType.from, this.props.data)
                            data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), AddressType.billTo, this.props.data)
                            data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), AddressType.tenantAddress, this.props.data)
                            data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), AddressType.shipFrom, this.props.data)

                            if (!getIsMakePayment(this.props.data.type) && !getIsReceivePayment(this.props.data.type) && !getIsExpenseOrDeposit(this.props.data.type)) {
                                newAddressFormat['shipTo'] = this.toAddressFieldList(this.state.addressFields)
                                data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), AddressType.shipTo, this.props.data)
                            }
                        }
                        else {
                            var addressTypeList = [AddressType.from, AddressType.billTo, AddressType.shipTo, AddressType.tenantAddress, AddressType.shipFrom];
                            if (getIsMakePayment(this.props.data.type)|| getIsReceivePayment(this.props.data.type) || getIsExpenseOrDeposit(this.props.data.type)) {
                                addressTypeList = [AddressType.from, AddressType.billTo, AddressType.tenantAddress, AddressType.shipFrom];
                            }

                            addressTypeList.forEach(element => {
                                if(this.props.addressType === element) {
                                    newAddressFormat[this.getAddressTypeName(element)] = this.toAddressFieldList(this.state.addressFields)
                                    data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), element, this.props.data)
                                }
                                else {
                                    var rawAddress = data[AddressParser.getDataAddressField(element)]
                                    var addressFields = this.state.addressFields
                                    if (rawAddress !== undefined) {
                                        if (rawAddress.ownerType !== undefined && rawAddress.ownerType !== null) {
                                            if (rawAddress.ownerType === OwnerType.tenant) {
                                                if (!getIsAddressCustomFieldType(data.type)) {
                                                    addressFields = addressFields.filter(x => x.code !== AddressFieldType.contactNumber)
                                                }
                                                const name = addressFields.find(x => x.type === AddressFieldType.name)
                                                if (name) {
                                                    addressFields = addressFields.filter(x => x.type !== AddressFieldType.name)
                                                }
                                                addressFields = addressFields.filter(x => x.code !== AddressFieldType.contactCode)
                                            }
                                            addressFields.forEach((element, index) => {
                                                element.index = index
                                            });
                                        }
                                    }
                                    newAddressFormat[this.getAddressTypeName(element)] = this.toAddressFieldList(addressFields)
                                    data = AddressParser.assignAddressObjToString(this.toAddressFieldList(addressFields), element, this.props.data)
                                }
                            });
                        }

                    }
                    else {
                        newAddressFormat[addressType] = this.toAddressFieldList(this.state.addressFields)
                        data = AddressParser.assignAddressObjToString(this.toAddressFieldList(this.state.addressFields), this.props.addressType, this.props.data)
                    }
                }
            }

            if (getIsBill(this.props.data.type) || getIsOrder(this.props.data.type)) {
                AddressParser.assignOriginalAddress(data);
            }
        }

        TemplateSettingsManager.updateAddressFormat(newAddressFormat)
        this.props.onSaveClicked(data)
    }
}
